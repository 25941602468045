import React, { useState, useEffect } from "react";
import useDrivePicker from "react-google-drive-picker";
import Loaders from "../components/loader";
import { useLocation } from "react-router-dom";
import axios from "axios";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

let auth;

const Gdrive = () => {
  let [openPicker, authResponse] = useDrivePicker();
  const [loader, setLoader] = useState("");
  // const [notice, setNotice] = useState(true);

  if (authResponse !== undefined) {
    if (auth === undefined) {
      auth = authResponse.access_token;
    }
  }

  let query = useQuery();

  const createSheet = (attached) => {
    const formData = new FormData();
    if (query.get("subject") === "listening") {
      formData.append(`attachments[0]`, attached);
    } else {
      formData.append("document", attached);
    }
    formData.append("source", "dropbox");
    formData.append("title", query.get("title"));
    // formData.append("min_score", query.get("min_score"));
    formData.append("type", query.get("subject"));
    formData.append("subject", query.get("selectedSubId"));
    if (query.get("worksheetStatus") === "1") {
      formData.append("is_private", 1);
    }
    formData.append("from_grade", query.get("gradeFrom"));
    formData.append("to_grade", query.get("gradeTo"));
    axios
      .post(`${process.env.REACT_APP_API_URL}/test`, formData, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${query.get("token")}`,
        },
      })
      .then(function (res) {
        if (res.data.success) {
          process.env.REACT_APP_IS_SCHOOL
            ? window.open(
                process.env.REACT_APP_TEACHER_PANEL_URL +
                  "/create-sheet/" +
                  res.data.data.id
              )
            : window.open(
                `http://app.punditin.com/create-sheet/${res.data.data.id}`
              );
          window.close();
        }
      });
  };

  const downloadAble = (file) => {
    setLoader(<Loaders />);
    let ext = file.mimeType.replace("application/", "");
    ext = ext.replace("image/", "");

    const formData = new FormData();

    formData.append("access_token", auth);
    formData.append("file_id", file.id);
    formData.append("type", ext);
    axios
      .post(`${process.env.REACT_APP_API_URL}/get-file-url`, formData, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${query.get("token")}`,
        },
      })
      .then(function (res) {
        if (res.data.success) {
          createSheet(res.data.url);
        } else {
          setLoader("");
          alert("File not supported. Please try again.");
        }
      });
  };

  const handleOpenPicker = () => {
    openPicker({
      clientId:
        "298588122172-b5fr0fg6j56u7i9lpu638k4n2ec66ko0.apps.googleusercontent.com",
      developerKey: "AIzaSyBr2VbrL2jZly_Ck15M8C6ve-Il89ggEc4",
      showUploadView: false,
      showUploadFolders: false,
      supportDrives: false,
      viewId: `${query.get("subject") === "listening" ? "" : "PDFS"}`,
      multiselect: false,
      callbackFunction: (data) => {
        if (data.action === "picked") {
          downloadAble(data.docs[0]);
        }
      },
    });
  };

  useEffect(() => {
    setLoader(<Loaders />);
    // setNotice(false);
    setTimeout(() => {
      handleOpenPicker();
      setLoader("");
    }, 500);
  }, []);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      {loader}
      {/* {notice ? (
        <div className="p-5">
          <p>
            <b>Disclosure:</b> This app complies with the Google API Services
            User Data Policy, including the Limited Use requirements. By logging
            in, you agree to the terms outlined in the policy. To learn more,
            please visit the{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://support.google.com/cloud/answer/6076109?hl=en"
            >
              Google API Services User Data Policy.
            </a>
          </p>
          <div
            className="d-flex justify-content-center mt-4"
            style={{
              gap: "10px",
            }}
          >
            <button
              className="btn btn-light"
              type="button"
              onClick={() => window.close()}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              type="button"
              onClick={() => next()}
            >
              Continue
            </button>
          </div>
        </div>
      ) : (
        ""
      )} */}
    </div>
  );
};

export default Gdrive;
