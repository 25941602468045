import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import logo from "../assets/images/Punditlogo.png";
import { Link } from "react-router-dom";
import Layout from "../layouts";
import Breadcrumb from "../components/UI/breadcrumb";
import { Helmet } from "react-helmet";

const LoginForm = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Punditin Login</title>
        {/* <meta name="description" content="Punditin Login" /> */}
      </Helmet>
      <Layout>
        <Breadcrumb title="Join" />
        <div className={`join`} id="form2">
          <div className="join px-3" id="form2">
            <div className="join_studend">
              <Link to={`${process.env.PUBLIC_URL + "/"}`}>
                <img alt="logo" src={logo} className="logoDesign mb-5" />
              </Link>
              <h4 className="text-white">Welcome Back</h4>
              <Link to={`${process.env.PUBLIC_URL + "/student-login"}`}>
                <button
                  type="button"
                  className="btn btn-lg w-100 mt-3"
                  style={{
                    backgroundColor: "rgb(248 129 44)",
                    color: "white",
                  }}
                >
                  I'm a Student
                </button>
              </Link>
              <Link to={`${process.env.PUBLIC_URL + "/parent-login"}`}>
                <button
                  type="button"
                  className="btn btn-lg w-100 mt-3"
                  style={{
                    backgroundColor: "rgb(248 129 44)",
                    color: "white",
                  }}
                >
                  I'm a Parent
                </button>
              </Link>
            </div>
          </div>
        </div>
      </Layout>
    </Fragment>
  );
};

export default LoginForm;
