import PropTypes from "prop-types";
import React, { useState, Fragment, useEffect } from "react";
import Header from "../containers/headers";
import Footer from "../containers/footer";
import LoadingBar from "react-top-loading-bar";
import { animateScroll } from "react-scroll";
import { MdExpandLess } from "react-icons/md";

const LayoutOne = ({ children, footerBg }) => {
    const [progress, setProgress] = useState(0);
    const [scroll, setScroll] = useState(0);
    const [top, setTop] = useState(0);

    useEffect(() => {
        setTop(100);
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        animateScroll.scrollToTop();
    };

    const handleScroll = () => {
        setScroll(window.scrollY);
    };

    return (
        <Fragment>
            <Header />
            <LoadingBar
                color="#f11946"
                progress={progress}
                onLoaderFinished={() => setProgress(100)}
            />
            {children}
            {process.env.REACT_APP_IS_SCHOOL ? (
                <>
                    <div className="copyright-area">
                        <div className="container">
                            <div className="footer-menu text-center">
                                <ul>
                                    <li>
                                        <p
                                            className="copy_right m-0 py-2"
                                            style={{ color: "white" }}
                                        >
                                            © Copyright 2022 Punditin Inc. All
                                            rights reserved.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div
                        className={`site-footer__scroll-top ${
                            scroll > top ? "show" : ""
                        }`}
                        onClick={() => scrollToTop()}
                    >
                        <MdExpandLess className="icon_scroll" />
                    </div>
                </>
            ) : (
                <Footer footerBg={footerBg} />
            )}
        </Fragment>
    );
};

LayoutOne.propTypes = {
    children: PropTypes.any,
    footerBg: PropTypes.string,
};

export default LayoutOne;
