import React, { Fragment, useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import logo from "../assets/images/Punditlogo.png";
import { Link, useHistory } from "react-router-dom";
import Layout from "../layouts";
import Breadcrumb from "../components/UI/breadcrumb";
import axios from "axios";
import ConsentForm from "../components/Join/consent-form";
import { setCurrentUser } from "../helpers/Utils";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ReCAPTCHA from "react-google-recaptcha";

const LoginForStudent = () => {
  let history = useHistory();
  const [loading, setLoading] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isConsentForm, setIsConsentForm] = useState(false);
  const [teacherEmail, setTeacherEmail] = useState("");
  const [captcha, setCaptcha] = useState("");

  const [toggle, setToggle] = useState(
    localStorage.getItem("toggle") === "true"
  );
  const [toggle1, setToggle1] = useState(
    localStorage.getItem("toggle1") === "true"
  );

  const studentLogin = (e) => {
    e.preventDefault();
    if (!captcha) {
      setError("Please verify that you are not a robot.");
      return;
    }
    if (
      localStorage.getItem("toggle") === "true" ||
      localStorage.getItem("toggle1") === "true"
    ) {
      setLoading(true);
      const formData = new FormData();
      formData.append("student_id_or_name", e.target.student_id.value);
      formData.append("password", e.target.password.value);
      axios
        .post(`${process.env.REACT_APP_API_URL}/student/login`, formData)
        .then(function (res) {
          setLoading(false);
          if (res.data.success) {
            setCaptcha("");
            localStorage.setItem("current_userName", res.data.data.name);
            setCurrentUser(res.data.data);
            setSuccess(res.data.message);
            setError("");
            history.push(`/student/${res.data.data.id}`);
          } else {
            setSuccess("");
            setError(res.data.message);
            if (
              res.data?.data?.allow_access === 0 &&
              !process.env.REACT_APP_IS_SCHOOL
            ) {
              setTeacherEmail(res.data.data.teacher_email);
              setIsConsentForm(true);
            }
          }
        });
    } else {
      setError("Please select one of the two options given below.");
    }
  };
  const checkStudent = (check) => {
    if (check === "1") {
      setToggle(!toggle);
      setToggle1(false);
    } else {
      setToggle(false);
      setToggle1(!toggle1);
    }
  };

  useEffect(() => {
    localStorage.setItem("toggle", toggle);
  }, [toggle]);

  useEffect(() => {
    localStorage.setItem("toggle1", toggle1);
  }, [toggle1]);

  return (
    <Fragment>
      <Helmet>
        <title>Punditin Login</title>
        {/* <meta name="description" content="Punditin Login" /> */}
      </Helmet>
      <Layout>
        <Breadcrumb title="Join px-3" />
        <div class={`join`}>
          <div className="join_studend">
            <Link to={`${process.env.PUBLIC_URL + "/"}`}>
              <img alt="logo" src={logo} className="logoDesign mb-5" />
            </Link>
            {error ? (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            ) : (
              ""
            )}
            {success ? (
              <div className="alert alert-success" role="alert">
                {success}
              </div>
            ) : (
              ""
            )}
            <form
              onSubmit={(e) => studentLogin(e)}
              className="justify-content-center"
            >
              <div className="form-group">
                <input
                  type="text"
                  name="student_id"
                  className="form-control p-3 w-100"
                  placeholder="Username or Enter Id"
                />
              </div>
              <div className="password_field">
                <div className="form-group">
                  <input
                    type="password"
                    // type={passwordShown ? "text" : "password"}
                    name="password"
                    className="form-control p-3 w-100"
                    placeholder="Enter Password"
                  />
                  {/* <button onClick={togglePassword}>Show/Hide</button> */}
                </div>
              </div>
              <div className="form-group required text-left">
                {process.env.REACT_APP_IS_SCHOOL ? (
                  <>
                    <div className="d-flex mt-2 text-left justify-content-between">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck2"
                        onChange={() => checkStudent("2")}
                        checked={toggle1 ? "checked" : ""}
                        name="sss"
                        required={
                          process.env.REACT_APP_IS_SCHOOL ? true : false
                        }
                      />
                      <label
                        className="mx-2 text-white"
                        htmlFor="exampleCheck2"
                        style={{
                          width: "calc(100% - 30px)",
                        }}
                      >
                        I agree to the{" "}
                        <Link
                          style={{
                            color: "rgb(248, 129, 44)",
                            textDecoration: "none",
                          }}
                          to={`${process.env.PUBLIC_URL + "/term-condition"}`}
                        >
                          Terms and Conditions
                        </Link>{" "}
                        and{" "}
                        <Link
                          style={{
                            color: "rgb(248, 129, 44)",
                            textDecoration: "none",
                          }}
                          to={`${process.env.PUBLIC_URL + "/privacy-policy"}`}
                        >
                          Privacy Policy
                        </Link>
                      </label>
                    </div>
                  </>
                ) : (
                  <>
                    <label className="text-white mt-3 ">
                      Check one of the below options:
                    </label>
                    <div className="d-flex mt-2 text-left justify-content-between">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck1"
                        onChange={() => checkStudent("1")}
                        checked={toggle ? "checked" : ""}
                        name="sss"
                      />
                      <label
                        className="mx-2 text-white"
                        htmlFor="exampleCheck1"
                        style={{
                          width: "calc(100% - 30px)",
                        }}
                      >
                        I am below 13yrs old and have provided the parental
                        consent form and agree to the{" "}
                        <Link
                          style={{
                            color: "rgb(248, 129, 44)",
                            textDecoration: "none",
                          }}
                          to={`${process.env.PUBLIC_URL + "/term-condition"}`}
                        >
                          Terms and Conditions
                        </Link>{" "}
                        and{" "}
                        <Link
                          style={{
                            color: "rgb(248, 129, 44)",
                            textDecoration: "none",
                          }}
                          to={`${process.env.PUBLIC_URL + "/privacy-policy"}`}
                        >
                          Privacy Policy
                        </Link>
                      </label>
                    </div>
                    <div className="d-flex mt-2 text-left justify-content-between">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck2"
                        onChange={() => checkStudent("2")}
                        checked={toggle1 ? "checked" : ""}
                        name="sss"
                        required={
                          process.env.REACT_APP_IS_SCHOOL ? true : false
                        }
                      />
                      <label
                        className="mx-2 text-white"
                        htmlFor="exampleCheck2"
                        style={{
                          width: "calc(100% - 30px)",
                        }}
                      >
                        I am 13yrs + and agree to the{" "}
                        <Link
                          style={{
                            color: "rgb(248, 129, 44)",
                            textDecoration: "none",
                          }}
                          to={`${process.env.PUBLIC_URL + "/term-condition"}`}
                        >
                          Terms and Conditions
                        </Link>{" "}
                        and{" "}
                        <Link
                          style={{
                            color: "rgb(248, 129, 44)",
                            textDecoration: "none",
                          }}
                          to={`${process.env.PUBLIC_URL + "/privacy-policy"}`}
                        >
                          Privacy Policy
                        </Link>
                      </label>
                    </div>
                  </>
                )}
              </div>
              <div className="form-group mt-3">
                <ReCAPTCHA
                  onChange={(e) => setCaptcha(e)}
                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                />
              </div>
              {loading ? (
                <button
                  className="btn btn-lg w-100 mt-2 disabled"
                  style={{
                    backgroundColor: "rgb(248 129 44)",
                    color: "white",
                  }}
                >
                  Loading
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-lg w-100 mt-2"
                  style={{
                    backgroundColor: "rgb(248 129 44)",
                    color: "white",
                  }}
                >
                  LOGIN
                </button>
              )}
            </form>
          </div>
        </div>
      </Layout>
      {isConsentForm ? (
        <ConsentForm
          setIsConsentForm={setIsConsentForm}
          teacherEmail={teacherEmail}
        />
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default LoginForStudent;
