// FabricCanvas.js
import React, { useEffect, useRef } from "react";
import { fabric } from "fabric";

const FabricCanvas = (props) => {
  const canvasRef = useRef(null);
  useEffect(() => {
    const canvasInstance = new fabric.Canvas(canvasRef.current, {
      backgroundColor: null, // Set to null for transparent background
      height: props.imageHeight,
      width: 800,
    });

    if (props.document?.pen_tool_data) {
      canvasInstance.loadFromJSON(
        props.document?.pen_tool_data,
        canvasInstance.renderAll.bind(canvasInstance),
        function (o, object) {}
      );
    }

    // Disable selection for all objects on the canvas
    canvasInstance.forEachObject((obj) => {
      obj.selectable = false;
    });

    return () => {
      canvasInstance.dispose();
    };
  }, []);

  return (
    <div className="fabric-editor">
      <canvas ref={canvasRef} />
    </div>
  );
};

export default FabricCanvas;
