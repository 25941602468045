import React, { Fragment, useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import logo from "../assets/images/Punditlogo.png";
import { Link } from "react-router-dom";
import Layout from "../layouts";
import Breadcrumb from "../components/UI/breadcrumb";
import axios from "axios";
import { useLocation } from "react-router-dom";
import JoinSection from "../components/Join";
import ConsentForm from "../components/Join/consent-form";
import { useHistory } from "react-router-dom";
import { getCurrentUser } from "../helpers/Utils";
import { Helmet } from "react-helmet";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const JoinLogin = () => {
  let history = useHistory();
  const [loading, setLoading] = useState("");
  const [join, setJoin] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [allData, setAllData] = useState("");
  /* const[code, setCode] = useState("") */
  const [step1, setStep1] = useState("d-none");
  const [step2, setStep2] = useState("d-block");

  const [isConsentForm, setIsConsentForm] = useState(false);
  const [teacherEmail, setTeacherEmail] = useState("");

  let query = useQuery();
  let id = query.get("id");
  let title = query.get("title");
  let deadline = query.get("deadline");

  const JoinApi = (formData) => {
    localStorage.setItem("end_date", "");
    localStorage.setItem("unique_id", "");
    axios
      .post(`${process.env.REACT_APP_API_URL}/student/join-with-code`, formData)
      .then(function (res) {
        if (res.data.success) {
          setJoin(res.data.data.id);
          setAllData(res.data.data);
          setSuccess(res.data.message);
          if (getCurrentUser()) {
            assignSubmit(res.data.data.id);
          } else {
            setLoading(false);
            setError("");
            localStorage.removeItem("current_timer");
          }
        } else {
          setLoading(false);
          setError(res.data.message);
          setTimeout(() => {
            setError("");
          }, 1000);
          setSuccess("");
        }
      });
  };

  const assignSubmit = (id) => {
    const formData = new FormData();
    formData.append("worksheet_id", id);
    formData.append("student_id_or_name", getCurrentUser().student_id);
    formData.append("password", getCurrentUser().password);
    axios
      .post(`${process.env.REACT_APP_API_URL}/student/verify-student`, formData)
      .then(function (res) {
        if (res.data.success) {
          localStorage.removeItem("current_document_id");
          setError("");
          localStorage.setItem("current_userName", res.data.student.name);
          localStorage.setItem("unique_id", res.data.data.id);
          localStorage.setItem("test_time", res.data.data.remaining_time);
          history.push(`/test/${id}`);
        } else {
          setLoading(false);
          setError(res.data.message);
          if (res.data.data && res.data.data.allow_access === 0) {
            setTeacherEmail(res.data.data.teacher_email);
            setIsConsentForm(true);
          }
        }
      });
  };

  const JoinCode = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("code", e.target.code.value);
    JoinApi(formData);
  };

  return (
    <Fragment>
      <Helmet>
        <title>Punditin Login</title>
        {/* <meta name="description" content="Punditin Login" /> */}
      </Helmet>
      <Layout>
        <Breadcrumb title="Join px-3" />
        <div className={`${step2}`}>
          <JoinSection codeId={id} title={title} deadline={deadline} />
        </div>
        {isConsentForm ? (
          <ConsentForm
            setIsConsentForm={setIsConsentForm}
            teacherEmail={teacherEmail}
          />
        ) : (
          ""
        )}
      </Layout>
    </Fragment>
  );
};

export default JoinLogin;
