import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useRouter } from "next/router";
import Dummy from "../../assets/images/default-thumb.png";

const SingleCourse = (props) => {
  const [data, setData] = useState(
    props.myCourses ? props.data.course : props.data
  );
  let history = useRouter();

  return (
    <div className="col-lg-4 col-md-6 mb-3">
      <div className="border lesson-single">
        <Link to={`/course/${data?.id}`}>
          <img
            src={data?.thumbnail ? data?.thumbnail : Dummy.src}
            alt="course"
          />
        </Link>

        <div className="p-2">
          <Link
            to={process.env.NEXT_PUBLIC_IS_SCHOOL ? "/" : `/course/${data?.id}`}
          >
            <div className="d-flex justify-content-between align-items-center">
              {data?.pricing == 0 ? (
                <span className="badge bg-primary">Free</span>
              ) : (
                <span className="badge bg-secondary">${data?.pricing}</span>
              )}
              <div className="duration">Duration: {data?.duration}</div>
            </div>

            <div className="card__title-wrapper mt-3">
              <h5 className="m-0">{data?.course_title}</h5>
              <div className="card__placeholder-container">
                <p className="title ellipsis">{data?.category_name}</p>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SingleCourse;
