/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import Layout from "../layouts";
import Breadcrumb from "../components/UI/breadcrumb";
import axios from "axios";
import { useHistory, Link, useLocation } from "react-router-dom";
import { getCurrentUser, setCurrentUser } from "../helpers/Utils";
import Loaders from "../components/loader";
import io from "socket.io-client";
import { Helmet } from "react-helmet";

const ReamTime = () => {
  const [loader, setLoader] = useState(true);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [quizList, setQuizList] = useState("");
  const [data, setData] = useState("");

  let history = useHistory();

  const checkQuiz = () => {
    localStorage.removeItem("sequence");
    localStorage.removeItem("quizRecordId");
    localStorage.removeItem("totalCount");
    localStorage.removeItem("nextSetId");
    localStorage.removeItem("quizSetId");
    const formData = new FormData();
    formData.append("student_id", getCurrentUser().id);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/student/check-for-quiz`,
        formData,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser()?.token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.success) {
          setQuizList(res.data.data);
        } else {
          setMessage(res.data.message);
        }
        setLoader(false);
      })
      .catch(function (err) {
        setCurrentUser("");
        history.push("/");
      });
  };

  const startTest = () => {
    localStorage.setItem("sequence", "");
    localStorage.setItem("quizRecordId", "");
    localStorage.setItem("quizSetId", "");
    history.push(
      `/real-time-test?quiz_id=${quizList.quiz?.id}&quiz_class_id=${quizList.id}`
    );
  };

  useEffect(() => {
    checkQuiz();
    const socket = io(process.env.REACT_APP_SOCKET_URL);
    socket.on(getCurrentUser().class_id, (type, data) => {
      if (type === "check_for_quiz") {
        setQuizList(data);
        setMessage("");
      }
    });
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>Real Time Test</title>
        {/* <meta name="description" content="Homepage" /> */}
      </Helmet>
      <Layout>
        <Breadcrumb title="Join px-3" />
        <div
          className="container py-4"
          style={{
            minHeight: "calc(100vh - 450px)",
          }}
        >
          {loader ? (
            <Loaders />
          ) : message ? (
            <p className="alert alert-danger my-4 text-center">{message}</p>
          ) : (
            <>
              <h2>{quizList.quiz.name}</h2>
              {loading ? (
                <button className="btn btn-primary disabled" disabled>
                  loading
                </button>
              ) : (
                <button className="btn btn-primary" onClick={() => startTest()}>
                  Start now
                </button>
              )}
            </>
          )}
        </div>
      </Layout>
    </Fragment>
  );
};

export default ReamTime;
