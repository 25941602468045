import React, { useEffect, useRef, useState } from "react";
import { fabric } from "fabric";

const FabricSubmittedStudent = (props) => {
  const canvasRefStudent = useRef(null);
  const [containerClass, setContainerClass] = useState(
    props.type ? "fabric-editor dragdrawer" : "fabric-editor"
  );
  const [canvas, setCanvas] = useState(null);

  useEffect(() => {
    const canvasInstance = new fabric.Canvas(canvasRefStudent.current, {
      backgroundColor: null,
      height: props.imageHeight,
      width: 800,
      preserveObjectStacking: true,
      defaultCursor: "",
    });
    canvasInstance.forEachObject((obj) => {
      obj.selectable = false;
    });
    canvasInstance.selection = false;

    setCanvas(canvasInstance);

    if (props.document.student_pen_tool_data) {
      canvasInstance.loadFromJSON(
        props.document.student_pen_tool_data,
        canvasInstance.renderAll.bind(canvasInstance),
        function (o, object) {}
      );
    }

    // Disable selection for all objects on the canvas
    canvasInstance.forEachObject((obj) => {
      obj.selectable = false;
    });

    return () => {
      canvasInstance.dispose();
    };
  }, []);

  return (
    <div className={containerClass}>
      <canvas ref={canvasRefStudent} />
    </div>
  );
};

export default FabricSubmittedStudent;
