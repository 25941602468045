import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import Layout from "../layouts";
import NotFoundContent from "../containers/not-found";
import Breadcrumb from "../components/UI/breadcrumb";
import { Helmet } from "react-helmet";

const NotFound = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Punditin Not Found</title>
        {/* <meta name="description" content="Not Found page" /> */}
      </Helmet>
      <Layout>
        {/* breadcrumb */}
        <Breadcrumb title="Not Found" />
        <NotFoundContent />
      </Layout>
    </Fragment>
  );
};

export default NotFound;
