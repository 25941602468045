import React, { useState } from "react";
import AudioPlayer from "react-h5-audio-player";
import StudentWorksheetElement from "../student-sheet/element";
import { DndContext } from "@dnd-kit/core";
import Modal from "react-modal";
import axios from "axios";
import TestDocumentFile from "../test-document";

const Index = (props) => {
  const [parent, setParent] = useState(null);
  const [singleDocument, setSingleDocument] = useState(props.data);

  function handleDragEnd({ over }) {
    setParent(over ? over.id : null);
  }
  const [language, setLanguage] = useState(false);
  const handleChangelan = (e) => {
    setLanguage(e);
    const formData = new FormData();
    formData.append("student_rtl_enabled", e ? 1 : 0);
    formData.append("test_record_id", localStorage.getItem("unique_id"));
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/update-test-record-student-side`,
        formData
      )
      .then(function (res) {});
  };

  return (
    <>
      <div
        className={`${
          props.worksheetData.type !== "listening" ? "bg-white" : "mt-3"
        }`}
        id="testbgarea"
      >
        <div className="form-check form-switch d-flex justify-content-start ">
          <label
            style={{
              marginLeft: "4px",
            }}
            className="form-check-label"
            htmlFor="flexSwitchCheckDefault"
          >
            RTL
          </label>
          <input
            className="form-check-input ml-1"
            type="checkbox"
            role="switch"
            id="flexSwitchCheckDefault"
            onChange={(e) => handleChangelan(e.target.checked)}
            style={{
              marginTop: "5px",
              marginLeft: "4px",
            }}
          />
        </div>
        <div className="row">
          {props.worksheetData.attachments.length !== 0 &&
            props.worksheetData.attachments.map((item, i) => (
              <div key={i} className="col-6">
                <label>Audio {i + 1}</label>
                <AudioPlayer
                  src={item.document}
                  showJumpControls="false"
                  showDownloadProgress="false"
                  showFilledProgress="false"
                  hasDefaultKeyBindings="false"
                  autoPlayAfterSrcChange="false"
                />
              </div>
            ))}
        </div>
        {/* Listeinig data element */}
        <div className="mid_bar">
          <div className="middle_image_display">
            <div className="result_view text-left position-relative">
              {singleDocument.is_instruction_document === 1 ? (
                <>
                  <TestDocumentFile
                    imageSrc={singleDocument.document}
                    data={singleDocument}
                  />
                  <DndContext onDragEnd={handleDragEnd}>
                    {singleDocument.elements &&
                      singleDocument.elements.map((item) => (
                        <StudentWorksheetElement
                          mainDocument={singleDocument.document}
                          studentId={localStorage.getItem("unique_id")}
                          data={item}
                          worksheetid={props.worksheetData.id}
                          parent={parent}
                          handleDragEnd={handleDragEnd}
                          language={language}
                        />
                      ))}
                  </DndContext>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="mid_bar">
          <div className="middle_image_display">
            <div className="result_view text-center position-relative">
              {singleDocument.is_instruction_document === 0 ? (
                <>
                  <TestDocumentFile
                    imageSrc={singleDocument.document}
                    data={singleDocument}
                  />
                  <DndContext onDragEnd={handleDragEnd}>
                    {singleDocument.elements &&
                      singleDocument.elements.map((item) => (
                        <>
                          <StudentWorksheetElement
                            mainDocument={singleDocument.document}
                            studentId={localStorage.getItem("unique_id")}
                            data={item}
                            worksheetid={props.worksheetData.id}
                            parent={parent}
                            handleDragEnd={handleDragEnd}
                            language={language}
                          />
                        </>
                      ))}
                  </DndContext>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
