import React from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import TestDocumentFile from "../test-document";

const LeftSheet = (props) => {
  return (
    <>
      <div
        className={`${
          props.worksheetData.type !== "listening" ? "bg-white" : "mt-3"
        }`}
      >
        {props.worksheetData.type === "listening" ? (
          <div className="row">
            {props.worksheetData.attachments.length !== 0 &&
              props.worksheetData.attachments.map((item, i) => (
                <div key={i} className="col-6">
                  <label>Audio {i + 1}</label>
                  <AudioPlayer
                    src={item.document}
                    showJumpControls="false"
                    showDownloadProgress="false"
                    showFilledProgress="false"
                    hasDefaultKeyBindings="false"
                    autoPlayAfterSrcChange="false"
                  />
                </div>
              ))}
          </div>
        ) : (
          ""
        )}
        {props.worksheetData.type === "writing" ? (
          <>
            <div
              className="writing_inst px-3 pt-2"
              dangerouslySetInnerHTML={{
                __html: props.data.instructions,
              }}
            />
            <TestDocumentFile
              isAnswer={props.isAnswer}
              imageSrc={props.data.document}
              data={props.data}
            />
            {props.data.sub_documents &&
              props.data.sub_documents.map((item) => (
                <div className="result_view position-relative">
                  <TestDocumentFile
                    isAnswer={props.isAnswer}
                    imageSrc={item.document}
                    data={item}
                  />
                </div>
              ))}
          </>
        ) : (
          ""
        )}
        {props.worksheetData.type === "listening" ? (
          <div className="mid_bar">
            <div className="middle_image_display">
              {props.worksheetData.documents &&
                props.worksheetData.documents.map((data) => (
                  <>
                    <div className="result_view text-center position-relative">
                      {data.is_instruction_document === 1 ? (
                        <TestDocumentFile
                          imageSrc={data.document}
                          data={data}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    {data.sub_documents &&
                      data.sub_documents.map((subDoc) => (
                        <div className="result_view text-center position-relative">
                          {subDoc.is_instruction_document === 1 ? (
                            <>
                              <TestDocumentFile
                                imageSrc={subDoc.document}
                                data={subDoc}
                              />
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      ))}
                  </>
                ))}
            </div>
          </div>
        ) : (
          ""
        )}
        {props.worksheetData.type === "reading" ? (
          <div className="mid_bar">
            <div className="middle_image_display">
              {props.worksheetData.documents &&
                props.worksheetData.documents.map((data) => (
                  <>
                    {data.is_instruction_document === 1 ? (
                      <div className="result_view text-center position-relative">
                        <TestDocumentFile
                          imageSrc={data.document}
                          data={data}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {data.sub_documents &&
                      data.sub_documents.map((subDoc) => (
                        <div className="result_view text-center position-relative">
                          {subDoc.is_instruction_document === 1 ? (
                            <>
                              <TestDocumentFile
                                imageSrc={subDoc.document}
                                data={subDoc}
                              />
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      ))}
                  </>
                ))}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default LeftSheet;
