/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { getCurrentUser } from "../../helpers/Utils";

const RealTimeQuestion = (props) => {
  const [data, setData] = useState("");
  const [isTrueFalse, setIsTrueFalse] = useState("");
  const [isYesNo, setIsYesNo] = useState("");

  const alpha = Array.from(Array(26)).map((e, i) => i + 65);
  const alphabet = alpha.map((x) => String.fromCharCode(x));
  const formattedContent = props.question.data.replace(/\r\n/g, "<br />");
  return (
    <div
      className={`quiz_question-single ${
        localStorage.getItem("quizIsRtl") == 1 ? "text-end" : ""
      }`}
    >
      {props.question.is_exit_ticket_question ? (
        <h5 className="mb-3">Exit Ticket Question</h5>
      ) : (
        ""
      )}
      <p
        className="mb-4"
        dangerouslySetInnerHTML={{
          __html: formattedContent,
        }}
      />
      {props.question.attachment ? (
        <img
          className="w-100"
          style={{
            maxWidth: "500px",
          }}
          src={props.question.attachment}
          alt="s"
        />
      ) : (
        ""
      )}
      {props.question.question_type === "multiple_choice" ? (
        <div className="row mt-3">
          {props.question.sub_questions?.map((item, counter) => (
            <div className="col-md-6 mb-2">
              <div className="d-flex align-items-start">
                <input
                  type="radio"
                  style={{
                    position: "absolute",
                    opacity: "0",
                    cursor: "pointer",
                    height: "0",
                    width: "0",
                  }}
                  name={`${props.question.id}_name`}
                  id={`${item.id}_name`}
                  onChange={(e) => {
                    if (e.target.checked) {
                      props.setAnswer(item.id);
                    } else {
                      props.setAnswer("");
                    }
                  }}
                  className="cursor-pointer me-2"
                />

                <label
                  className="form-check-label cursor-pointer w-100 p-2"
                  htmlFor={`${item.id}_name`}
                >
                  <div className="sec_box border p-2">
                    <span className="abc">
                      {alphabet.map((xx, ii) => (ii === counter ? xx : ""))}
                    </span>
                    {item.data}
                    {item.attachment ? (
                      <img
                        className="mt-2 mw-100"
                        style={{
                          maxWidth: "500px",
                        }}
                        src={item.attachment}
                        alt="s"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </label>
              </div>
            </div>
          ))}
        </div>
      ) : props.question.question_type === "dropdown" ? (
        <div className="form-group">
          <select
            dir={localStorage.getItem("quizIsRtl") == 1 ? "rtl" : ""}
            className="form-control dropdown"
            onChange={(e) => props.setAnswer(e.target.value)}
          >
            <option value="">Choose Option</option>
            {props.question.sub_questions?.map((item) => (
              <option value={item.id}>{item.data}</option>
            ))}
          </select>
        </div>
      ) : props.question.question_type === "true_false" ? (
        <div className="true_false mt-2">
          <button
            className={`add_answer_button true px-4 ${
              isTrueFalse === "true" ? "selected" : ""
            }`}
            onClick={() => {
              setIsTrueFalse("true");
              props.setAnswer("true");
            }}
          >
            True
          </button>
          <button
            className={`add_answer_button false ms-2 px-4 ${
              isTrueFalse === "false" ? "selected" : ""
            }`}
            onClick={() => {
              setIsTrueFalse("false");
              props.setAnswer("false");
            }}
          >
            False
          </button>
          <button
            className={`add_answer_button not_given ms-2 px-4 ${
              isTrueFalse === "not_given" ? "selected" : ""
            }`}
            onClick={() => {
              setIsTrueFalse("not_given");
              props.setAnswer("not_given");
            }}
          >
            Not Given
          </button>
        </div>
      ) : props.question.question_type === "yes_no" ? (
        <div className="true_false mt-2">
          <button
            className={`add_answer_button true px-4 ${
              isYesNo === "yes" ? "selected" : ""
            }`}
            onClick={() => {
              setIsYesNo("yes");
              props.setAnswer("yes");
            }}
          >
            Yes
          </button>
          <button
            className={`add_answer_button false ms-2 px-4 ${
              isYesNo === "no" ? "selected" : ""
            }`}
            onClick={() => {
              setIsYesNo("no");
              props.setAnswer("no");
            }}
          >
            No
          </button>
          <button
            className={`add_answer_button not_given ms-2 px-4 ${
              isYesNo === "not_given" ? "selected" : ""
            }`}
            onClick={() => {
              setIsYesNo("not_given");
              props.setAnswer("not_given");
            }}
          >
            Not Given
          </button>
        </div>
      ) : props.question.question_type === "exit_ticket" ||
        props.question.question_type === "short_answer" ? (
        <div className="form-group">
          <textarea
            dir={localStorage.getItem("quizIsRtl") == 1 ? "rtl" : ""}
            onChange={(e) => props.setAnswer(e.target.value)}
            className="form-control"
          ></textarea>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default RealTimeQuestion;
