import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useRouter } from "next/router";

import { useHistory } from "react-router-dom";
import { setCurrentUser, getCurrentUser } from "../../helpers/Utils";
import TestDocumentFile from "../test-document";

const StudentWorksheetData = (props) => {
  const [singleData, setSingleData] = useState("");
  const worksheetid = useParams().id;
  let history = useRouter();

  const SingleWorksheetData = () => {
    axios
      .get(
        `${
          process.env.NEXT_PUBLIC_API_URL
        }/get-worksheet-student/${worksheetid}?student_record_id=${localStorage.getItem(
          "unique_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser()?.token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.success) {
          setSingleData(res.data.data);
        }
      })
      .catch(function (err) {
        setCurrentUser("");
        history.push("/");
      });
  };

  useEffect(() => {
    SingleWorksheetData();
  }, []);

  return (
    <div className="container-fluid p-0">
      <div className="mid_bar">
        <div className="middle_image_display">
          {singleData &&
            singleData.documents.map((data, i) => (
              <>
                <div className="result_view text-center position-relative">
                  <TestDocumentFile imageSrc={data.document} data={data} />
                </div>
              </>
            ))}
        </div>
      </div>
    </div>
  );
};

export default StudentWorksheetData;
