/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useEffect } from "react";

import Layout from "../layouts";
import Breadcrumb from "../components/UI/breadcrumb";
import axios from "axios";
import { useRouter } from "next/router";
import { getCurrentUser, setCurrentUser } from "../helpers/Utils";
import Loaders from "../components/loader";
import SingleCourse from "../components/courses/single-course";
import io from "socket.io-client";
import Head from "next/head";

const MyCourses = () => {
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState("");
  const [allData, setAllData] = useState([]);
  const [allCategory, setAllCategory] = useState([]);

  const getSingleLesson = (e) => {
    setLoader(true);
    setAllData([]);
    const currentTimestampInSeconds = Math.floor(Date.now() / 1000);
    let url = `${process.env.REACT_APP_API_URL}/student/studentenroll-list?timeStamp=${currentTimestampInSeconds}`;
    if (e !== undefined) {
      e.preventDefault();
      url = `${process.env.REACT_APP_API_URL}/student/studentenroll-list?timeStamp=${currentTimestampInSeconds}&category_id=${e.target.category.value}&course_title=${e.target.search.value}`;
    }
    axios
      .get(url, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${getCurrentUser()?.token}`,
        },
      })
      .then(function (res) {
        if (res.data.success) {
          setAllData(res.data.data);
        } else {
          setMessage(res.data.message);
        }
        setLoader(false);
      });
  };

  const getCourseCategories = () => {
    const currentTimestampInSeconds = Math.floor(Date.now() / 1000);
    setAllCategory([]);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/course-category-list?timeStamp=${currentTimestampInSeconds}`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.success) {
          setAllCategory(res.data.data);
        }
      });
  };

  useEffect(() => {
    getCourseCategories();
    getSingleLesson();
  }, []);

  return (
    <Fragment>
      <Layout>
        <Head>
          <title>My Courses</title>
        </Head>
        <Breadcrumb title="Courses" />
        <div className="container py-4 courses">
          <div className="row">
            <div className="col-lg-2 col-md-3">
              <h2>My Courses</h2>
            </div>
            <div className="col-lg-10 col-md-9">
              <form className="mb-4" onSubmit={(e) => getSingleLesson(e)}>
                <div class="filter-section d-sm-flex align-items-center justify-content-end gap-3">
                  <h5 className="mb-2 mb-sm-0">Filter</h5>
                  <div class="mb-2 mb-sm-0">
                    <select
                      style={{
                        appearance: "auto",
                      }}
                      name="category"
                      className="form-control"
                    >
                      <option value="">All Category</option>
                      {allCategory?.map((item) => (
                        <option value={item.id}>
                          {item.course_category_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* <div class="mb-2 mb-sm-0">
                    <select
                      style={{
                        appearance: "auto",
                      }}
                      name="type"
                      className="form-control"
                    >
                      <option value="">Type</option>
                      <option value="Paid">Paid</option>
                      <option value="Free">Free</option>
                    </select>
                  </div> */}
                  <div class="mb-2 mb-sm-0">
                    <input
                      className="form-control"
                      name="search"
                      type="text"
                      placeholder="Search by Name"
                    />
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Filter
                  </button>
                </div>
              </form>
            </div>
          </div>

          {loader ? (
            <Loaders />
          ) : message ? (
            <p className="alert alert-danger my-4 text-center">{message}</p>
          ) : (
            <div className="row">
              {allData?.map((item, i) => (
                <SingleCourse data={item} key={i} />
              ))}
            </div>
          )}
        </div>
      </Layout>
      <h2 className="d-none">.</h2>
    </Fragment>
  );
};

export default MyCourses;
