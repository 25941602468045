import React, { useEffect, useState, useRef } from "react";

const TimerMinutesExam = (props) => {
  const [time, setTime] = useState({
    hours: "00",
    minutes: "00",
    seconds: "00",
  });

  const remainingTimeRef = useRef(props.milliseconds); // Ref to store the remaining time

  useEffect(() => {
    // Start the countdown when the component mounts
    let timerId;
    if (!props.isPaused) {
      timerId = startCountdown();
    }

    // Clean up the interval when the component unmounts (page changes)
    return () => {
      clearInterval(timerId);
    };
  }, [props.isPaused]);

  const startCountdown = () => {
    const timerId = setInterval(() => {
      if (!props.isPaused) {
        // Check if the timer is not paused
        remainingTimeRef.current -= 1000;

        if (remainingTimeRef.current < 0) {
          clearInterval(timerId);
          setTime({ hours: "00", minutes: "00", seconds: "00" });
          props.submissionFile();
        } else {
          const totalSeconds = Math.floor(remainingTimeRef.current / 1000);
          const hours = Math.floor(totalSeconds / 3600);
          const minutes = Math.floor((totalSeconds % 3600) / 60);
          const seconds = totalSeconds % 60;

          setTime({
            hours: hours.toString().padStart(2, "0"),
            minutes: minutes.toString().padStart(2, "0"),
            seconds: seconds.toString().padStart(2, "0"),
          });
        }
      }
    }, 1000);

    return timerId; // Return the timerId to be used for cleanup later
  };

  return time.hours + ":" + time.minutes + ":" + time.seconds;
};

export default TimerMinutesExam;
