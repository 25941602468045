/* eslint-disable prefer-rest-params */
import React, { useEffect, useRef, useState } from "react";
import { Chart } from "chart.js";

const Line = ({ data, shadow = false }) => {
  const chartContainer = useRef(null);
  const [, setChartInstance] = useState(null);

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      if (shadow) {
        Chart.controllers.lineWithShadow = Chart.controllers.line;
        Chart.controllers.lineWithShadow = Chart.controllers.line.extend({
          draw(ease) {
            Chart.controllers.line.prototype.draw.call(this, ease);
            const {
              chart: { ctx },
            } = this;
            ctx.save();
            ctx.shadowColor = "rgba(0,0,0,0.15)";
            ctx.shadowBlur = 10;
            ctx.shadowOffsetX = 0;
            ctx.shadowOffsetY = 10;
            ctx.responsive = true;
            ctx.stroke();
            Chart.controllers.line.prototype.draw.apply(this, arguments);
            ctx.restore();
          },
        });
      }
      const context = chartContainer.current.getContext("2d");
      const newChartInstance = new Chart(context, {
        type: shadow ? "lineWithShadow" : "line",
        options: {
          legend: {
            display: false,
          },
          responsive: true,
          maintainAspectRatio: false,
          tooltips: {
            backgroundColor: "#fff",
            titleFontColor: "#333",
            // borderColor: "red",
            borderWidth: 0.5,
            bodyFontColor: "#333",
            bodySpacing: 10,
            xPadding: 15,
            yPadding: 15,
            cornerRadius: 0.15,
          },
          plugins: {
            datalabels: {
              display: false,
            },
          },
          scales: {
            yAxes: [
              {
                gridLines: {
                  display: true,
                  lineWidth: 1,
                  color: "rgba(0,0,0,0.1)",
                  drawBorder: false,
                },
                ticks: {
                  beginAtZero: true,
                  stepSize: 10,
                  min: 0,
                  max: 100,
                  padding: 20,
                },
              },
            ],
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
              },
            ],
          },
        },
        data,
      });
      setChartInstance(newChartInstance);
    }
  }, [chartContainer, data, shadow]);

  return (
    <canvas
      style={{
        minHeight: "285px",
      }}
      ref={chartContainer}
    />
  );
};

export default Line;
