/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef, useState } from "react";
import { fabric } from "fabric";
import axios from "axios";
import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Input,
} from "reactstrap";
import { getCurrentUser } from "../../helpers/Utils";
import molecule1 from "../../assets/images/simple-atom.svg";
import molecule2 from "../../assets/images/diatomic.svg";
import molecule3 from "../../assets/images/diatomic-red.svg";
import molecule4 from "../../assets/images/triatomic.svg";
import molecule5 from "../../assets/images/positive-atom.svg";
import molecule6 from "../../assets/images/negative-atom.svg";
import molecule7 from "../../assets/images/slow-particle.svg";
import molecule8 from "../../assets/images/medium-particle.svg";
import molecule9 from "../../assets/images/fast-particle.svg";
import molecule10 from "../../assets/images/low-density-particles.svg";
import ellipseImg from "../../assets/images/ellipse.svg";
import rectangleImg from "../../assets/images/rectangle.svg";

let selectedMolecule = null;

const FabricCanvasStudent = (props) => {
  const canvasRef = useRef(null);
  const [containerClass, setContainerClass] = useState(
    props.type ? "fabric-editor dragdrawer" : "fabric-editor"
  );
  const [selectedTool, setSelectedTool] = useState(null);
  const [canvas, setCanvas] = useState(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [history, setHistory] = useState([]);
  const [redoStack, setRedoStack] = useState([]);
  const [lineWidth, setLineWidth] = useState(5);
  const [fillColor, setFillColor] = useState("");
  const [strokeColor, setStrokeColor] = useState("#000000");
  const [isCreatingShape, setIsCreatingShape] = useState(false);
  const [currentShape, setCurrentShape] = useState(null);

  useEffect(() => {
    setContainerClass(
      props.type ? "fabric-editor dragdrawer" : "fabric-editor"
    );
  }, [props.type]);

  useEffect(() => {
    const canvasInstance = new fabric.Canvas(canvasRef.current, {
      backgroundColor: null,
      height: props.imageHeight,
      width: 800,
      preserveObjectStacking: true,
      defaultCursor: "",
    });

    setCanvas(canvasInstance);

    if (props.document.student_pen_tool_data) {
      canvasInstance.loadFromJSON(
        props.document.student_pen_tool_data,
        canvasInstance.renderAll.bind(canvasInstance),
        function (o, object) {}
      );
    }

    const saveHistory = debounce(() => {
      const jsonData = canvasInstance.toJSON();
      const formData = new FormData();
      formData.append("test_record_id", localStorage.getItem("unique_id"));
      formData.append("document_id", props.document.id);
      formData.append("data", JSON.stringify(jsonData));
      axios
        .post(
          `${process.env.NEXT_PUBLIC_API_URL}/student/update-pen-tool-data`,
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
              Authorization: `Bearer ${getCurrentUser()?.token}`,
            },
          }
        )
        .then(function (res) {});

      setHistory((prevHistory) => {
        const newHistory = [...prevHistory, jsonData];
        return newHistory.length > 50 ? newHistory.slice(1) : newHistory;
      });
    }, 500);

    canvasInstance.on("object:added", saveHistory);
    canvasInstance.on("object:modified", saveHistory);
    canvasInstance.on("object:removed", saveHistory);

    let isEditing = false;

    const handleEditingEntered = () => {
      isEditing = true;
    };

    const handleEditingExited = () => {
      isEditing = false;
    };

    const handleKeyDown = (event) => {
      const activeObject = canvasInstance.getActiveObject();
      if (activeObject) {
        if (event.keyCode === 46 || event.keyCode === 8) {
          if (!isEditing) {
            var deleteButton = document.querySelector(
              `.deleteElement_${props.document.id}`
            );
            if (deleteButton) {
              deleteButton.click();
            }
          }
        }
      }
    };

    canvasInstance.on("text:editing:entered", handleEditingEntered);
    canvasInstance.on("text:editing:exited", handleEditingExited);

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      canvasInstance.off("text:editing:entered", handleEditingEntered);
      canvasInstance.off("text:editing:exited", handleEditingExited);

      canvasInstance.dispose();
    };
  }, []);

  const debounce = (func, wait) => {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  };

  const handleMouseDown = (o) => {
    if (
      [
        "rectangle",
        "circle",
        "molecule",
        "ellipse",
        "square",
        "line",
        "arrow",
        "doubleArrow",
      ].includes(selectedTool)
    ) {
      if (!canvas) return;
      const nonSelectableTools = [
        "line",
        "arrow",
        "doubleArrow",
        "molecule",
        "ellipse",
        "square",
        "rectangle",
        "circle",
        "text",
        "draw",
      ];
      const isSelectable = !nonSelectableTools.includes(selectedTool);
      canvas.forEachObject((obj) => {
        obj.selectable = isSelectable;
      });

      canvas.selection = isSelectable;
      canvas.renderAll();

      setIsCreatingShape(true);
      const pointer = canvas.getPointer(o.e);
      let shape;
      if (selectedTool === "rectangle") {
        shape = new fabric.Rect({
          left: pointer.x,
          top: pointer.y,
          width: 0,
          height: 0,
          fill: fillColor,
          stroke: strokeColor,
          strokeWidth: lineWidth,
        });
        canvas.add(shape);
        setCurrentShape(shape);
      } else if (selectedTool === "circle") {
        shape = new fabric.Circle({
          left: pointer.x,
          top: pointer.y,
          width: 0,
          height: 0,
          radius: 0,
          fill: fillColor,
          stroke: strokeColor,
          strokeWidth: lineWidth,
        });
        canvas.add(shape);
        setCurrentShape(shape);
      } else if (selectedTool === "ellipse") {
        shape = new fabric.Ellipse({
          left: pointer.x,
          top: pointer.y,
          rx: 0,
          ry: 0,
          fill: fillColor,
          stroke: strokeColor,
          strokeWidth: lineWidth,
        });
        canvas.add(shape);
        setCurrentShape(shape);
      } else if (selectedTool === "square") {
        shape = new fabric.Rect({
          left: pointer.x,
          top: pointer.y,
          width: 0,
          height: 0,
          fill: fillColor,
          stroke: strokeColor,
          strokeWidth: lineWidth,
        });
        canvas.add(shape);
        setCurrentShape(shape);
      } else if (
        selectedTool === "line" ||
        selectedTool === "arrow" ||
        selectedTool === "doubleArrow"
      ) {
        shape = new fabric.Line([pointer.x, pointer.y, pointer.x, pointer.y], {
          stroke: strokeColor,
          strokeWidth: lineWidth,
          originX: "center",
          originY: "center",
        });
        canvas.add(shape);
        setCurrentShape(shape);
      } else if (selectedTool === "molecule") {
        fabric.Image.fromURL(
          selectedMolecule,
          (img) => {
            img.set({
              left: pointer.x,
              top: pointer.y,
            });
            canvas.add(img);
            setCurrentShape(img);
            canvas.renderAll();
          },
          { crossOrigin: "anonymous" }
        );
      }
    }
  };

  const handleMouseMove = (o) => {
    if (!isCreatingShape) return;

    const pointer = canvas.getPointer(o.e);

    if (selectedTool === "rectangle" || selectedTool === "square") {
      const width = Math.abs(pointer.x - currentShape?.left);
      const height =
        selectedTool === "square"
          ? width
          : Math.abs(pointer.y - currentShape?.top);
      currentShape?.set({
        width: width,
        height: height,
      });
    } else if (selectedTool === "circle") {
      const radius = Math.max(
        Math.abs(pointer.x - currentShape?.left),
        Math.abs(pointer.y - currentShape?.top)
      );
      currentShape?.set({ radius });
    } else if (selectedTool === "ellipse") {
      currentShape?.set({
        rx: Math.abs(pointer.x - currentShape?.left),
        ry: Math.abs(pointer.y - currentShape?.top),
      });
    } else if (
      selectedTool === "line" ||
      selectedTool === "arrow" ||
      selectedTool === "doubleArrow"
    ) {
      currentShape?.set({
        x2: pointer.x,
        y2: pointer.y,
      });
    } else if (selectedTool === "molecule") {
      const width = Math.abs(pointer.x - currentShape?.left);
      const height = Math.abs(pointer.y - currentShape?.top);

      currentShape?.scaleToWidth(width);
      currentShape?.scaleToHeight(height);
      currentShape?.setCoords();
    }

    canvas.renderAll();
  };

  const handleMouseUp = () => {
    if (selectedTool === "arrow" || selectedTool === "doubleArrow") {
      const { x1, y1, x2, y2 } = currentShape;
      const minArrowWidth = 30; // Set your minimum width here

      // Calculate the actual distance between the start and end points
      const dx = x2 - x1;
      const dy = y2 - y1;
      const distance = Math.sqrt(dx * dx + dy * dy);

      if (distance < minArrowWidth) {
        // Calculate the new end points to ensure the minimum width
        const angle = Math.atan2(dy, dx);
        const newX2 = x1 + minArrowWidth * Math.cos(angle);
        const newY2 = y1 + minArrowWidth * Math.sin(angle);

        currentShape.set({ x2: newX2, y2: newY2 });
      }

      const adjustedX2 = currentShape.x2;
      const adjustedY2 = currentShape.y2;
      const angle =
        Math.atan2(adjustedY2 - y1, adjustedX2 - x1) * (180 / Math.PI);
      const headLength = 10 + lineWidth + lineWidth;
      const arrow = new fabric.Triangle({
        left: adjustedX2,
        top: adjustedY2,
        originX: "center",
        originY: "center",
        width: headLength,
        height: headLength,
        angle: angle + 90,
        fill: strokeColor,
      });
      const groupObjects = [currentShape, arrow];
      if (selectedTool === "doubleArrow") {
        const arrowStart = new fabric.Triangle({
          left: x1,
          top: y1,
          originX: "center",
          originY: "center",
          width: headLength,
          height: headLength,
          angle: angle - 90,
          fill: strokeColor,
        });
        groupObjects.push(arrowStart);
      }

      const arrowGroup = new fabric.Group(groupObjects);

      canvas.add(arrowGroup);
      canvas.remove(currentShape);
    }

    if (!canvas) return;
    const nonSelectableTools = [
      "line",
      "arrow",
      "doubleArrow",
      "rectangle",
      "ellipse",
      "square",
      "molecule",
      "circle",
      "text",
      "draw",
    ];
    const isSelectable = !nonSelectableTools.includes(selectedTool);

    canvas.forEachObject((obj) => {
      obj.selectable = isSelectable;
    });

    canvas.selection = isSelectable;
    canvas.renderAll();

    setIsCreatingShape(false);
    setCurrentShape(null);
  };

  useEffect(() => {
    if (!canvas) return;
    canvas.on("mouse:down", handleMouseDown);
    canvas.on("mouse:move", handleMouseMove);
    canvas.on("mouse:up", handleMouseUp);

    return () => {
      canvas.off("mouse:down", handleMouseDown);
      canvas.off("mouse:move", handleMouseMove);
      canvas.off("mouse:up", handleMouseUp);
    };
  }, [canvas, selectedTool, isCreatingShape, currentShape]);

  const selectTool = () => {
    selectedMolecule = null;
    const isSelectable = true;
    canvas.forEachObject((obj) => {
      obj.selectable = true;
    });
    const objects = canvas.getObjects();
    if (objects.length > 0) {
      objects.forEach((object) => {
        object.selectable = true;
      });
      const activeGroup = new fabric.ActiveSelection(objects, {
        canvas: canvas,
      });
      canvas.setActiveObject(activeGroup);
      canvas.renderAll();
      canvas.discardActiveObject();
      canvas.renderAll();
    }
    setContainerClass("fabric-editor");
    canvas.selection = isSelectable;
    canvas.renderAll();
    setSelectedTool("select");
    canvas.isDrawingMode = false;
    setIsDrawing(false);
  };

  const addLine = () => {
    canvas.isDrawingMode = false;
    setContainerClass("fabric-editor dragdrawer");
    setIsDrawing(false);
    setSelectedTool("line");
  };

  const addArrow = () => {
    canvas.isDrawingMode = false;
    setIsDrawing(false);
    setSelectedTool("arrow");
    setContainerClass("fabric-editor dragdrawer");
  };

  const addDoubleArrow = () => {
    canvas.isDrawingMode = false;
    setIsDrawing(false);
    setSelectedTool("doubleArrow");
    setContainerClass("fabric-editor dragdrawer");
  };

  const addRectangle = () => {
    canvas.isDrawingMode = false;
    setIsDrawing(false);
    setSelectedTool("rectangle");
    setContainerClass("fabric-editor dragdrawer");
  };

  const addCircle = () => {
    canvas.isDrawingMode = false;
    setIsDrawing(false);
    setSelectedTool("circle");
    setContainerClass("fabric-editor dragdrawer");
  };

  const addEllipse = () => {
    canvas.isDrawingMode = false;
    setIsDrawing(false);
    setSelectedTool("ellipse");
    setContainerClass("fabric-editor dragdrawer");
  };

  const addSquare = () => {
    canvas.isDrawingMode = false;
    setIsDrawing(false);
    setSelectedTool("square");
    setContainerClass("fabric-editor dragdrawer");
  };

  const addMoleculeToCanvas = (molecule) => {
    canvas.isDrawingMode = false;
    setIsDrawing(false);
    setSelectedTool("molecule");
    selectedMolecule = molecule;
    setContainerClass("fabric-editor dragdrawer");
  };

  const addText = () => {
    canvas.isDrawingMode = false;
    setIsDrawing(false);
    const text = new fabric.Textbox("Add your text", {
      left: 100,
      top: 100,
      fill: strokeColor,
      width: 150,
    });
    canvas.add(text);
  };

  const enableDrawing = () => {
    setSelectedTool("draw");
    canvas.isDrawingMode = !canvas.isDrawingMode;
    if (canvas.isDrawingMode) {
      canvas.freeDrawingBrush.width = lineWidth;
      canvas.freeDrawingBrush.color = strokeColor;
    }
    setContainerClass("fabric-editor dragdrawer");
    setIsDrawing(canvas.isDrawingMode);
  };

  const updateLineWidth = (value) => {
    canvas.isDrawingMode = false;
    setIsDrawing(false);
    setLineWidth(value);
    if (canvas.isDrawingMode) {
      canvas.freeDrawingBrush.width = value;
    }
  };

  const undo = () => {
    if (history.length) {
      const newHistory = [...history];
      const lastState = newHistory.pop();
      setRedoStack((prevRedoStack) => [...prevRedoStack, lastState]);
      setHistory(newHistory);
      canvas.loadFromJSON(newHistory[newHistory.length - 1], () => {
        canvas.renderAll();
      });
    }
  };

  const redo = () => {
    if (redoStack.length) {
      const newRedoStack = [...redoStack];
      const nextState = newRedoStack.pop();
      setRedoStack(newRedoStack);
      setHistory((prevHistory) => [...prevHistory, nextState]);
      canvas.loadFromJSON(nextState, () => {
        canvas.renderAll();
      });
    }
  };

  const deleteSelected = () => {
    const activeObjects = canvas.getActiveObjects();
    if (activeObjects.length) {
      activeObjects.forEach((obj) => {
        canvas.remove(obj);
      });
      canvas.discardActiveObject(); // Deselect objects
      canvas.renderAll(); // Ensure canvas updates after deletion
    }
  };

  const updateFillColor = (color) => {
    setSelectedTool("");
    canvas.isDrawingMode = false;
    setIsDrawing(false);
    setFillColor(color);
    if (canvas.getActiveObject()) {
      canvas.getActiveObject().set({ fill: color });
      canvas.renderAll();
    }
  };

  const updateStrokeColor = (color) => {
    setSelectedTool("");
    canvas.isDrawingMode = false;
    setIsDrawing(false);
    setStrokeColor(color);
    if (canvas.getActiveObject()) {
      canvas.getActiveObject().set({ stroke: color });
      canvas.renderAll();
    }
  };

  const duplicateSelected = () => {
    const activeObjects = canvas.getActiveObjects();
    let lastCloned = null;

    activeObjects.forEach((obj) => {
      obj.clone((cloned) => {
        cloned.set({
          left: obj.left + 10,
          top: obj.top + 10,
          evented: true,
        });
        canvas.add(cloned);
        lastCloned = cloned;
      });
    });

    canvas.discardActiveObject();
    if (lastCloned) {
      canvas.setActiveObject(lastCloned);
    }

    canvas.renderAll();
  };

  const sendSelectedToBack = () => {
    const activeObject = canvas.getActiveObject();
    if (activeObject) {
      canvas.sendToBack(activeObject);
    }
  };

  const sendSelectedToFront = () => {
    const activeObject = canvas.getActiveObject();
    if (activeObject) {
      canvas.bringToFront(activeObject);
    }
  };

  return (
    <div className={containerClass}>
      {props.isAnswer ? (
        ""
      ) : (
        <div className="tool-bar border-top border-start border-bottom border-end border-dark">
          <span
            className={`border-bottom cursor-pointer ${
              selectedTool === "select" ? "active" : ""
            }`}
            title="Select tool"
            onClick={selectTool}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              viewBox="0 0 36 36"
            >
              <g fill="none" fill-rule="evenodd">
                <g>
                  <path
                    fill-rule="nonzero"
                    d="M0 0H36V36H0z"
                    transform="translate(-1717.000000, -892.000000) translate(1717.000000, 892.000000)"
                  />
                  <path
                    fill="#2781A0"
                    d="M20.27 20.571l1.962 4.78c.137.332-.019.703-.332.844l-1.728.754c-.322.141-.684-.002-.82-.342l-1.866-4.554-3.047 3.134c-.406.418-1.064.096-1.064-.452V9.643c0-.576.7-.858 1.064-.452l9.999 10.284c.404.394.106 1.096-.439 1.096H20.27z"
                    transform="translate(-1717.000000, -892.000000) translate(1717.000000, 892.000000)"
                  />
                </g>
              </g>
            </svg>
          </span>
          <span
            className={`border-bottom cursor-pointer ${
              isDrawing ? "active" : ""
            } ${""}`}
            onClick={enableDrawing}
            title="Free hand drawing tool"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              viewBox="0 0 36 36"
            >
              <g fill="none" fill-rule="evenodd">
                <g>
                  <g>
                    <path
                      d="M0 0H36V36H0z"
                      transform="translate(-1763.000000, -892.000000) translate(1763.000000, 892.000000)"
                    />
                    <g fill="#2781A0">
                      <path
                        d="M3.22 15.732h1.755v1.348l-2.36.42-1.137-1.156.413-2.394h1.328v1.782zm1.32-3.121c-.202-.204-.202-.531 0-.735l5.635-5.715c.2-.203.522-.203.724 0 .201.204.201.531 0 .735L5.265 12.61c-.201.203-.524.203-.725 0zM9.057 3.621L.79 13.445l-.775 4.51c-.106.61.416 1.135 1.016 1.032l4.446-.79 9.609-9.745c.172-.175.172-.457 0-.63L11.023 3.7c-.175-.174-.453-.174-.626 0zm7.82 1.57l-1.327 1.572c-.172.172-.45.172-.621 0l-4.062-4.12c-.173-.176-.173-.458 0-.63l1.973-1.97c.684-.695 1.797-.695 2.483 0l2.2 2.23c.688.693.688 1.821 0 2.52z"
                        transform="translate(-1763.000000, -892.000000) translate(1763.000000, 892.000000) translate(8.235270, 8.500000)"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </span>
          <UncontrolledDropdown direction="end">
            <DropdownToggle color="empty" className="m-0 p-0 border-0">
              {selectedTool === "line" ? (
                <span
                  className={`border-bottom cursor-pointer ${
                    selectedTool === "line" ? "active" : ""
                  }`}
                  title="Line"
                  onClick={addLine}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                  >
                    <g fill="none" fill-rule="evenodd">
                      <g>
                        <g>
                          <path
                            d="M0 0H36V36H0z"
                            transform="translate(-1809.000000, -892.000000) translate(1809.000000, 892.000000)"
                          />
                          <g fill="#2781A0">
                            <path
                              d="M18 0L19.414 1.414 1.414 19.414 0 18z"
                              transform="translate(-1809.000000, -892.000000) translate(1809.000000, 892.000000) translate(8.292893, 8.250000)"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </span>
              ) : selectedTool === "arrow" ? (
                <span
                  className={`border-bottom cursor-pointer ${
                    selectedTool === "arrow" ? "active" : ""
                  }`}
                  title="Arrow"
                  onClick={addArrow}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                  >
                    <g fill="none" fill-rule="evenodd">
                      <g>
                        <g>
                          <path
                            d="M0 0H36V36H0z"
                            transform="translate(-1855.000000, -892.000000) translate(1855.000000, 892.000000)"
                          />
                          <g fill="#2781A0">
                            <path
                              d="M19.021 0L16.016 8.797 14.07 6.737 1.414 20.137 0 18.64 12.656 5.239 10.713 3.182z"
                              transform="translate(-1855.000000, -892.000000) translate(1855.000000, 892.000000) translate(8.292893, 8.095474)"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </span>
              ) : selectedTool === "doubleArrow" ? (
                <span
                  className={`border-bottom cursor-pointer ${
                    selectedTool === "doubleArrow" ? "active" : ""
                  }`}
                  title="Double arrow"
                  onClick={addDoubleArrow}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                  >
                    <g fill="none" fill-rule="evenodd">
                      <g>
                        <g>
                          <path
                            d="M0 0H36V36H0z"
                            transform="translate(-1901.000000, -892.000000) translate(1901.000000, 892.000000)"
                          />
                          <g fill="#2781A0">
                            <path
                              d="M18.629 0L15.623 8.797 13.677 6.737 6.362 14.482 8.308 16.543 0 19.725 3.005 10.928 4.948 12.985 12.263 5.239 10.32 3.182z"
                              transform="translate(-1901.000000, -892.000000) translate(1901.000000, 892.000000) translate(8.685735, 8.095474)"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </span>
              ) : (
                <span
                  className={`border-bottom cursor-pointer ${
                    selectedTool === "line" ? "active" : ""
                  }`}
                  title="Line"
                  onClick={addLine}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                  >
                    <g fill="none" fill-rule="evenodd">
                      <g>
                        <g>
                          <path
                            d="M0 0H36V36H0z"
                            transform="translate(-1809.000000, -892.000000) translate(1809.000000, 892.000000)"
                          />
                          <g fill="#2781A0">
                            <path
                              d="M18 0L19.414 1.414 1.414 19.414 0 18z"
                              transform="translate(-1809.000000, -892.000000) translate(1809.000000, 892.000000) translate(8.292893, 8.250000)"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </span>
              )}
            </DropdownToggle>
            <DropdownMenu className="p-0 m-0 t-0 rounded-0 arrow_dropdown">
              <DropdownItem
                className="m-0 p-0"
                title="Basic shape tool (click and hold to show available shapes)"
              >
                <span
                  className={`border-bottom cursor-pointer ${
                    selectedTool === "line" ? "active" : ""
                  }`}
                  title="Line"
                  onClick={addLine}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                  >
                    <g fill="none" fill-rule="evenodd">
                      <g>
                        <g>
                          <path
                            d="M0 0H36V36H0z"
                            transform="translate(-1809.000000, -892.000000) translate(1809.000000, 892.000000)"
                          />
                          <g fill="#2781A0">
                            <path
                              d="M18 0L19.414 1.414 1.414 19.414 0 18z"
                              transform="translate(-1809.000000, -892.000000) translate(1809.000000, 892.000000) translate(8.292893, 8.250000)"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </span>
              </DropdownItem>
              <DropdownItem className="m-0 p-0">
                <span
                  className={`border-bottom cursor-pointer ${
                    selectedTool === "arrow" ? "active" : ""
                  }`}
                  title="Arrow"
                  onClick={addArrow}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                  >
                    <g fill="none" fill-rule="evenodd">
                      <g>
                        <g>
                          <path
                            d="M0 0H36V36H0z"
                            transform="translate(-1855.000000, -892.000000) translate(1855.000000, 892.000000)"
                          />
                          <g fill="#2781A0">
                            <path
                              d="M19.021 0L16.016 8.797 14.07 6.737 1.414 20.137 0 18.64 12.656 5.239 10.713 3.182z"
                              transform="translate(-1855.000000, -892.000000) translate(1855.000000, 892.000000) translate(8.292893, 8.095474)"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </span>
              </DropdownItem>
              <DropdownItem className="m-0 p-0">
                <span
                  className={`border-bottom cursor-pointer ${
                    selectedTool === "doubleArrow" ? "active" : ""
                  }`}
                  title="Double Arrow"
                  onClick={addDoubleArrow}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                  >
                    <g fill="none" fill-rule="evenodd">
                      <g>
                        <g>
                          <path
                            d="M0 0H36V36H0z"
                            transform="translate(-1901.000000, -892.000000) translate(1901.000000, 892.000000)"
                          />
                          <g fill="#2781A0">
                            <path
                              d="M18.629 0L15.623 8.797 13.677 6.737 6.362 14.482 8.308 16.543 0 19.725 3.005 10.928 4.948 12.985 12.263 5.239 10.32 3.182z"
                              transform="translate(-1901.000000, -892.000000) translate(1901.000000, 892.000000) translate(8.685735, 8.095474)"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <UncontrolledDropdown direction="end">
            <DropdownToggle color="empty" className="m-0 p-0 border-0">
              {selectedTool === "circle" ? (
                <span
                  className={`border-bottom cursor-pointer ${
                    selectedTool === "circle" ? "active" : ""
                  }`}
                  title="Circle"
                  onClick={addCircle}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                  >
                    <g fill="none" fill-rule="evenodd">
                      <g>
                        <g>
                          <path
                            d="M0 0H36V36H0z"
                            transform="translate(-2047.000000, -893.000000) translate(2047.000000, 893.000000)"
                          />
                          <g>
                            <path
                              fill="#2781A0"
                              d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0zm0 2c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8z"
                              transform="translate(-2047.000000, -893.000000) translate(2047.000000, 893.000000) translate(8.000000, 8.000000)"
                            />
                            <path
                              fill-rule="nonzero"
                              d="M10 2c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8z"
                              transform="translate(-2047.000000, -893.000000) translate(2047.000000, 893.000000) translate(8.000000, 8.000000)"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </span>
              ) : selectedTool === "rectangle" ? (
                <span
                  className={`border-bottom cursor-pointer ${
                    selectedTool === "rectangle" ? "active" : ""
                  }`}
                  title="Rectangle"
                  onClick={addRectangle}
                >
                  <img src={rectangleImg} />
                </span>
              ) : selectedTool === "ellipse" ? (
                <span
                  className={`border-bottom cursor-pointer ${
                    selectedTool === "ellipse" ? "active" : ""
                  }`}
                  title="Ellipse"
                  onClick={addEllipse}
                >
                  <img src={ellipseImg} />
                </span>
              ) : selectedTool === "square" ? (
                <span
                  className={`border-bottom cursor-pointer ${
                    selectedTool === "square" ? "active" : ""
                  }`}
                  title="Square"
                  onClick={addSquare}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                  >
                    <g fill="none" fill-rule="evenodd">
                      <g>
                        <path
                          d="M0 0H36V36H0z"
                          transform="translate(-2093.000000, -893.000000) translate(2093.000000, 893.000000)"
                        />
                        <g fill="#2781A0">
                          <path
                            d="M2 2h17v17H2z"
                            stroke="#2781A0"
                            fill="none"
                            stroke-width="2"
                            transform="translate(-2093.000000, -893.000000) translate(2093.000000, 893.000000) translate(8.500000, 8.500000)"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                </span>
              ) : (
                <span
                  className={`border-bottom cursor-pointer ${
                    selectedTool === "circle" ? "active" : ""
                  }`}
                  title="Circle"
                  onClick={addCircle}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                  >
                    <g fill="none" fill-rule="evenodd">
                      <g>
                        <g>
                          <path
                            d="M0 0H36V36H0z"
                            transform="translate(-2047.000000, -893.000000) translate(2047.000000, 893.000000)"
                          />
                          <g>
                            <path
                              fill="#2781A0"
                              d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0zm0 2c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8z"
                              transform="translate(-2047.000000, -893.000000) translate(2047.000000, 893.000000) translate(8.000000, 8.000000)"
                            />
                            <path
                              fill-rule="nonzero"
                              d="M10 2c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8z"
                              transform="translate(-2047.000000, -893.000000) translate(2047.000000, 893.000000) translate(8.000000, 8.000000)"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </span>
              )}
            </DropdownToggle>
            <DropdownMenu className="p-0 m-0 t-0 rounded-0 arrow_dropdown">
              <DropdownItem className="m-0 p-0">
                <span
                  className={`border-bottom cursor-pointer ${
                    selectedTool === "circle" ? "active" : ""
                  }`}
                  title="Circle"
                  onClick={addCircle}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                  >
                    <g fill="none" fill-rule="evenodd">
                      <g>
                        <g>
                          <path
                            d="M0 0H36V36H0z"
                            transform="translate(-2047.000000, -893.000000) translate(2047.000000, 893.000000)"
                          />
                          <g>
                            <path
                              fill="#2781A0"
                              d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0zm0 2c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8z"
                              transform="translate(-2047.000000, -893.000000) translate(2047.000000, 893.000000) translate(8.000000, 8.000000)"
                            />
                            <path
                              fill-rule="nonzero"
                              d="M10 2c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8z"
                              transform="translate(-2047.000000, -893.000000) translate(2047.000000, 893.000000) translate(8.000000, 8.000000)"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </span>
              </DropdownItem>
              <DropdownItem className="m-0 p-0">
                <span
                  className={`border-bottom cursor-pointer ${
                    selectedTool === "square" ? "active" : ""
                  }`}
                  title="Square"
                  onClick={addSquare}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                  >
                    <g fill="none" fill-rule="evenodd">
                      <g>
                        <path
                          d="M0 0H36V36H0z"
                          transform="translate(-2093.000000, -893.000000) translate(2093.000000, 893.000000)"
                        />
                        <g fill="#2781A0">
                          <path
                            d="M2 2h17v17H2z"
                            stroke="#2781A0"
                            fill="none"
                            stroke-width="2"
                            transform="translate(-2093.000000, -893.000000) translate(2093.000000, 893.000000) translate(8.500000, 8.500000)"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                </span>
              </DropdownItem>

              <DropdownItem className="m-0 p-0">
                <span
                  className={`border-bottom cursor-pointer ${
                    selectedTool === "ellipse" ? "active" : ""
                  }`}
                  title="Ellipse"
                  onClick={addEllipse}
                >
                  <img src={ellipseImg} />
                </span>
              </DropdownItem>
              <DropdownItem className="m-0 p-0">
                <span
                  className={`border-bottom cursor-pointer ${
                    selectedTool === "rectangle" ? "active" : ""
                  }`}
                  title="Rectangle"
                  onClick={addRectangle}
                >
                  <img src={rectangleImg} />
                </span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <span
            className={`border-bottom cursor-pointer ${
              selectedTool === "text" ? "active" : ""
            }`}
            title="Text Tool"
            onClick={addText}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              viewBox="0 0 36 36"
            >
              <g fill="none" fill-rule="evenodd">
                <g>
                  <g>
                    <path
                      d="M0 0H36V36H0z"
                      transform="translate(-1855.000000, -942.000000) translate(1855.000000, 942.000000)"
                    />
                    <g fill="#2781A0">
                      <path
                        d="M11.43 19.195L11.43 17.534 9.281 17.165 9.281 1.964 13.632 1.964 14.027 4.627 15.952 4.627 15.952 0 0 0 0 4.627 1.951 4.627 2.333 1.964 6.684 1.964 6.684 17.165 4.535 17.534 4.535 19.195z"
                        transform="translate(-1855.000000, -942.000000) translate(1855.000000, 942.000000) translate(10.020000, 8.400000)"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </span>
          <UncontrolledDropdown direction="end">
            <DropdownToggle color="empty" className="m-0 p-0 border-0">
              <span
                Title="Stamp tool (click and hold to show available categories)"
                className="border-bottom"
              >
                <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNiIgaGVpZ2h0PSIzNiIgdmlld0JveD0iMCAwIDM2IDM2Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGc+CiAgICAgICAgICAgIDxnPgogICAgICAgICAgICAgICAgPHBhdGggZD0iTTAgMEgzNlYzNkgweiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIwMzkuMDAwMDAwLCAtOTQyLjAwMDAwMCkgdHJhbnNsYXRlKDIwMzkuMDAwMDAwLCA5NDIuMDAwMDAwKSIvPgogICAgICAgICAgICAgICAgPHBhdGggZmlsbD0iIzU0NTQ1NCIgZD0iTTkgMjJ2NmgxOHYtNmMwLTEuMS0xLjAxMi0yLTIuMjUtMmgtMTMuNUMxMC4wMTIgMjAgOSAyMC45IDkgMjJ6bTE2IDJIMTF2LTJoMTR2MnpNMTggOGMtMi43NiAwLTUgMi4yNC01IDVsNSA3IDUtN2MwLTIuNzYtMi4yNC01LTUtNXptMCA5bC0zLTRjMC0xLjY2IDEuMzQtMyAzLTNzMyAxLjM0IDMgM2wtMyA0eiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIwMzkuMDAwMDAwLCAtOTQyLjAwMDAwMCkgdHJhbnNsYXRlKDIwMzkuMDAwMDAwLCA5NDIuMDAwMDAwKSIvPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4K" />
              </span>
            </DropdownToggle>
            <DropdownMenu className="p-0 m-0 t-0 rounded-0 arrow_dropdown">
              <DropdownItem className="m-0 p-0 dropdown-item text-center">
                <span
                  className={`cursor-pointer p-1 ${
                    selectedMolecule === molecule1 ? "active" : ""
                  }`}
                  onClick={() => addMoleculeToCanvas(molecule1)}
                >
                  <img src={molecule1} />
                </span>
              </DropdownItem>
              <DropdownItem className="m-0 p-0 dropdown-item text-center">
                <span
                  className={`cursor-pointer p-1 ${
                    selectedMolecule === molecule2 ? "active" : ""
                  }`}
                  onClick={() => addMoleculeToCanvas(molecule2)}
                >
                  <img src={molecule2} />
                </span>
              </DropdownItem>
              <DropdownItem className="m-0 p-0 dropdown-item text-center">
                <span
                  className={`cursor-pointer p-1 ${
                    selectedMolecule === molecule3 ? "active" : ""
                  }`}
                  onClick={() => addMoleculeToCanvas(molecule3)}
                >
                  <img src={molecule3} />
                </span>
              </DropdownItem>
              <DropdownItem className="m-0 p-0 dropdown-item text-center">
                <span
                  className={`cursor-pointer p-1 ${
                    selectedMolecule === molecule4 ? "active" : ""
                  }`}
                  onClick={() => addMoleculeToCanvas(molecule4)}
                >
                  <img src={molecule4} />
                </span>
              </DropdownItem>
              <DropdownItem className="m-0 p-0 dropdown-item text-center">
                <span
                  className={`cursor-pointer p-1 ${
                    selectedMolecule === molecule5 ? "active" : ""
                  }`}
                  onClick={() => addMoleculeToCanvas(molecule5)}
                >
                  <img src={molecule5} />
                </span>
              </DropdownItem>
              <DropdownItem className="m-0 p-0 dropdown-item text-center">
                <span
                  className={`cursor-pointer p-1 ${
                    selectedMolecule === molecule6 ? "active" : ""
                  }`}
                  onClick={() => addMoleculeToCanvas(molecule6)}
                >
                  <img src={molecule6} />
                </span>
              </DropdownItem>
              <DropdownItem className="m-0 p-0 dropdown-item text-center">
                <span
                  className={`cursor-pointer p-1 ${
                    selectedMolecule === molecule7 ? "active" : ""
                  }`}
                  onClick={() => addMoleculeToCanvas(molecule7)}
                >
                  <img src={molecule7} />
                </span>
              </DropdownItem>
              <DropdownItem className="m-0 p-0 dropdown-item text-center">
                <span
                  className={`cursor-pointer p-1 ${
                    selectedMolecule === molecule8 ? "active" : ""
                  }`}
                  onClick={() => addMoleculeToCanvas(molecule8)}
                >
                  <img src={molecule8} />
                </span>
              </DropdownItem>
              <DropdownItem className="m-0 p-0 dropdown-item text-center">
                <span
                  className={`cursor-pointer p-1 ${
                    selectedMolecule === molecule9 ? "active" : ""
                  }`}
                  onClick={() => addMoleculeToCanvas(molecule9)}
                >
                  <img src={molecule9} />
                </span>
              </DropdownItem>
              <DropdownItem className="m-0 p-0 dropdown-item text-center">
                <span
                  className={`cursor-pointer p-1 ${
                    selectedMolecule === molecule10 ? "active" : ""
                  }`}
                  onClick={() => addMoleculeToCanvas(molecule10)}
                >
                  <img src={molecule10} />
                </span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <span
            className="border-bottom cursor-pointer"
            title="Stroke color (click and hold to show available colors)"
          >
            <label className="m-0" htmlFor="stroke_picker">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                viewBox="0 0 36 36"
              >
                <g fill="none" fillRule="evenodd">
                  <g>
                    <g>
                      <path
                        d="M0 0h36v36H0z"
                        transform="translate(-2131 -942) translate(2131 942)"
                      />
                      <g>
                        <path
                          id="color_stroke_bar"
                          d="M0 18H26V23H0z"
                          transform="translate(-2131 -942) translate(2131 942) translate(5 7)"
                          fill={strokeColor}
                        />
                        <path
                          id="color_stroke_bar"
                          d="M26 18v5H0v-5h26zm-.5.5H.5v4h25v-4z"
                          transform="translate(-2131 -942) translate(2131 942) translate(5 7)"
                          fill="none"
                        />
                        <path
                          fill="#0481A0"
                          d="M7.91 13.248h1.478v1.135l-1.987.353-.958-.972.347-2.016h1.12v1.5zm1.11-2.628c-.17-.17-.17-.446 0-.618l4.745-4.812c.17-.172.44-.172.61 0 .17.171.17.446 0 .618L9.62 10.62c-.169.172-.44.172-.61 0zm4.933-7.502L3.834 11.338l-.652 3.798c-.09.513.35.956.856.869l3.744-.665L17.9 7.118c.145-.148.145-.385 0-.532l-3.42-3.47c-.148-.146-.382-.146-.527 0zm6.585 1.322l-1.42 1.441c-.146.145-.38.145-.524 0l-3.42-3.47c-.146-.147-.146-.385 0-.53l1.42-1.441c.576-.585 1.513-.585 2.091 0l1.853 1.878c.58.584.58 1.533 0 2.121z"
                          transform="translate(-2131 -942) translate(2131 942) translate(5 7)"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </label>

            <input
              id="stroke_picker"
              className="color_picker"
              type="color"
              value={strokeColor}
              onChange={(e) => updateStrokeColor(e.target.value)}
            />
          </span>
          <span
            className="border-bottom cursor-pointer"
            title="Fill color (click and hold to show available colors)"
          >
            <label className="m-0" htmlFor="fill_picker">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                viewBox="0 0 36 36"
              >
                <defs>
                  <filter id="5d93qer36a" colorInterpolationFilters="auto">
                    <feColorMatrix
                      in="SourceGraphic"
                      values="0 0 0 0 0.015686 0 0 0 0 0.505882 0 0 0 0 0.627451 0 0 0 1.000000 0"
                    />
                  </filter>
                </defs>
                <g fill="none" fillRule="evenodd">
                  <g>
                    <g>
                      <path
                        d="M0 0h36v36H0z"
                        transform="translate(-2177 -942) translate(2177 942)"
                      />
                      <g>
                        <path
                          fillRule="nonzero"
                          d="M0 17.999h26v5H0z"
                          transform="translate(-2177 -942) translate(2177 942) translate(5.000 7.001)"
                        />
                        <path
                          id="color_fill_bar"
                          fill={fillColor}
                          d="M0 18h26v5H0z"
                          transform="translate(-2177 -942) translate(2177 942) translate(5.000 7.001)"
                        />
                        <path
                          id="color_fill_bar"
                          fill="none"
                          d="M26 18v5H0v-5h26zm-.5.5H.5v4h25v-4z"
                          transform="translate(-2177 -942) translate(2177 942) translate(5.000 7.001)"
                        />
                        <g
                          filter="url(#5d93qer36a)"
                          transform="translate(-2177 -942) translate(2177 942) translate(5.000 7.001)"
                        >
                          <path
                            fill="#0481A0"
                            d="M7.01 4.07l4.427 4.427H2.583L7.01 4.07zm6.717 4.243L5.67.257c-.34-.342-.893-.342-1.235-.001-.344.34-.344.896-.002 1.238l1.34 1.34L.37 8.971c-.39.39-.39 1.024 0 1.414l6.01 6.01c.196.195.452.293.709.293.255 0 .511-.098.707-.293l6.01-6.01c.39-.39.39-1.024 0-1.414zM17.98 14.23c0 1.035-.839 1.875-1.874 1.875-1.035 0-1.874-.84-1.874-1.875 0-1.521 1.891-3.21 1.891-3.21s1.857 1.666 1.857 3.21"
                            transform="translate(4.000 0.000)"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </label>
            <input
              id="fill_picker"
              className="color_picker"
              type="color"
              value={fillColor}
              onChange={(e) => updateFillColor(e.target.value)}
            />
          </span>
          <UncontrolledDropdown direction="end" className="border-bottom">
            <DropdownToggle color="empty" className="m-0 p-0 border-0">
              <span
                className="cursor-pointer"
                title="Stroke width (click and hold to show available options)"
              >
                <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNiIgaGVpZ2h0PSIzNiIgdmlld0JveD0iMCAwIDM2IDM2Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGc+CiAgICAgICAgICAgIDxnPgogICAgICAgICAgICAgICAgPHBhdGggZD0iTTAgMEgzNlYzNkgweiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE3MTcuMDAwMDAwLCAtMTAzNi4wMDAwMDApIHRyYW5zbGF0ZSgxNzE3LjAwMDAwMCwgMTAzNi4wMDAwMDApIi8+CiAgICAgICAgICAgICAgICA8ZyBmaWxsPSIjMDQ4MUEwIj4KICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMCA0TDI2IDQgMjYgMCAwIDB6TTAgOUwyNiA5IDI2IDcgMCA3ek0wIDEzTDI2IDEzIDI2IDEyIDAgMTJ6IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTcxNy4wMDAwMDAsIC0xMDM2LjAwMDAwMCkgdHJhbnNsYXRlKDE3MTcuMDAwMDAwLCAxMDM2LjAwMDAwMCkgdHJhbnNsYXRlKDUuMDAwMDAwLCA2LjAwMDAwMCkiLz4KICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+Cg==" />
                <i
                  style={{
                    fontStyle: "normal",
                    bottom: 0,
                    position: "absolute",
                    left: 0,
                    width: "100%",
                    lineHeight: "15px",
                  }}
                >
                  {lineWidth}
                </i>
              </span>
            </DropdownToggle>
            <DropdownMenu className="p-3 m-0 t-0 rounded-0">
              <input
                type="range"
                min="1"
                max="20"
                value={lineWidth}
                onChange={(e) => updateLineWidth(Number(e.target.value))}
              />
              <label className="m-0 p-0">Line Width: {lineWidth}</label>
            </DropdownMenu>
          </UncontrolledDropdown>
          <span
            className="border-bottom cursor-pointer"
            title="Clone Tool"
            onClick={duplicateSelected}
          >
            <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNiIgaGVpZ2h0PSIzNiIgdmlld0JveD0iMCAwIDM2IDM2Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGc+CiAgICAgICAgICAgIDxnPgogICAgICAgICAgICAgICAgPHBhdGggZD0iTTAgMEgzNlYzNkgweiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE3MTcuMDAwMDAwLCAtMTA4Mi4wMDAwMDApIHRyYW5zbGF0ZSgxNzE3LjAwMDAwMCwgMTA4Mi4wMDAwMDApIi8+CiAgICAgICAgICAgICAgICA8ZyBmaWxsPSIjMDQ4MUEwIj4KICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMTUgMHY1aC0yLjAwMUwxMyAySDd2NUgydjloM3YySDBWNmw2LTZoOXoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xNzE3LjAwMDAwMCwgLTEwODIuMDAwMDAwKSB0cmFuc2xhdGUoMTcxNy4wMDAwMDAsIDEwODIuMDAwMDAwKSB0cmFuc2xhdGUoNy4wMDAwMDAsIDUuNTAwMDAwKSIvPgogICAgICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0xNiA3SDd2MThoMTVWMTNsLTYtNnpNOSAyM1Y5aDZ2NWg1djlIOXoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xNzE3LjAwMDAwMCwgLTEwODIuMDAwMDAwKSB0cmFuc2xhdGUoMTcxNy4wMDAwMDAsIDEwODIuMDAwMDAwKSB0cmFuc2xhdGUoNy4wMDAwMDAsIDUuNTAwMDAwKSB0cmFuc2xhdGUoMTQuNTAwMDAwLCAxNi4wMDAwMDApIHNjYWxlKC0xLCAxKSB0cmFuc2xhdGUoLTE0LjUwMDAwMCwgLTE2LjAwMDAwMCkiLz4KICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+Cg==" />
          </span>
          <span
            className="border-bottom cursor-pointer"
            onClick={sendSelectedToBack}
            title="Send Selected Object to Back"
          >
            <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNiIgaGVpZ2h0PSIzNiIgdmlld0JveD0iMCAwIDM2IDM2Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGc+CiAgICAgICAgICAgIDxnPgogICAgICAgICAgICAgICAgPHBhdGggZD0iTTAgMEgzNlYzNkgweiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE3NjMuMDAwMDAwLCAtMTA4Mi4wMDAwMDApIHRyYW5zbGF0ZSgxNzYzLjAwMDAwMCwgMTA4Mi4wMDAwMDApIi8+CiAgICAgICAgICAgICAgICA8Zz4KICAgICAgICAgICAgICAgICAgICA8cGF0aCBmaWxsPSIjOTc5Nzk3IiBkPSJNMjQgMTJ2MTJIMTJWMTJoMTJ6bS0yIDJoLTh2OGg4di04eiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE3NjMuMDAwMDAwLCAtMTA4Mi4wMDAwMDApIHRyYW5zbGF0ZSgxNzYzLjAwMDAwMCwgMTA4Mi4wMDAwMDApIHRyYW5zbGF0ZSg2LjAwMDAwMCwgNi4wMDAwMDApIi8+CiAgICAgICAgICAgICAgICAgICAgPHBhdGggZmlsbD0iIzk3OTc5NyIgZD0iTTE4IDZMMTggMTAgMTYgMTAgMTYgOCA4IDggOCAxNiAxMCAxNiAxMCAxOCA2IDE4IDYgNnoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xNzYzLjAwMDAwMCwgLTEwODIuMDAwMDAwKSB0cmFuc2xhdGUoMTc2My4wMDAwMDAsIDEwODIuMDAwMDAwKSB0cmFuc2xhdGUoNi4wMDAwMDAsIDYuMDAwMDAwKSIvPgogICAgICAgICAgICAgICAgICAgIDxwYXRoIGZpbGw9IiNEOEVGRjUiIGZpbGwtcnVsZT0ibm9uemVybyIgZD0iTTEwIDJMMTAgNCA0IDQgNCAxMCAyIDEwIDIgMnoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xNzYzLjAwMDAwMCwgLTEwODIuMDAwMDAwKSB0cmFuc2xhdGUoMTc2My4wMDAwMDAsIDEwODIuMDAwMDAwKSB0cmFuc2xhdGUoNi4wMDAwMDAsIDYuMDAwMDAwKSIvPgogICAgICAgICAgICAgICAgICAgIDxwYXRoIGZpbGw9IiMwNDgxQTAiIGQ9Ik0xMiAwTDEyIDQgMTAgNCAxMCAyIDIgMiAyIDEwIDQgMTAgNCAxMiAwIDEyIDAgMHoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xNzYzLjAwMDAwMCwgLTEwODIuMDAwMDAwKSB0cmFuc2xhdGUoMTc2My4wMDAwMDAsIDEwODIuMDAwMDAwKSB0cmFuc2xhdGUoNi4wMDAwMDAsIDYuMDAwMDAwKSIvPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4K" />
          </span>
          <span
            className="border-bottom cursor-pointer"
            title="Send Selected Object to Front"
            onClick={sendSelectedToFront}
          >
            <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNiIgaGVpZ2h0PSIzNiIgdmlld0JveD0iMCAwIDM2IDM2Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGc+CiAgICAgICAgICAgIDxnPgogICAgICAgICAgICAgICAgPHBhdGggZD0iTTAgMEgzNlYzNkgweiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE4MDkuMDAwMDAwLCAtMTA4Mi4wMDAwMDApIHRyYW5zbGF0ZSgxODA5LjAwMDAwMCwgMTA4Mi4wMDAwMDApIi8+CiAgICAgICAgICAgICAgICA8Zz4KICAgICAgICAgICAgICAgICAgICA8cGF0aCBmaWxsPSIjMDQ4MUEwIiBkPSJNMjQgMTJ2MTJIMTJWMTJoMTJ6bS0yIDJoLTh2OGg4di04eiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE4MDkuMDAwMDAwLCAtMTA4Mi4wMDAwMDApIHRyYW5zbGF0ZSgxODA5LjAwMDAwMCwgMTA4Mi4wMDAwMDApIHRyYW5zbGF0ZSg2LjAwMDAwMCwgNi4wMDAwMDApIi8+CiAgICAgICAgICAgICAgICAgICAgPHBhdGggZmlsbD0iI0Q4RUZGNSIgZmlsbC1ydWxlPSJub256ZXJvIiBkPSJNMjIgMTRMMTQgMTQgMTQgMjIgMjIgMjJ6IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTgwOS4wMDAwMDAsIC0xMDgyLjAwMDAwMCkgdHJhbnNsYXRlKDE4MDkuMDAwMDAwLCAxMDgyLjAwMDAwMCkgdHJhbnNsYXRlKDYuMDAwMDAwLCA2LjAwMDAwMCkiLz4KICAgICAgICAgICAgICAgICAgICA8cGF0aCBmaWxsPSIjOTc5Nzk3IiBkPSJNMTggNkwxOCAxMCAxNiAxMCAxNiA4IDggOCA4IDE2IDEwIDE2IDEwIDE4IDYgMTggNiA2eiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE4MDkuMDAwMDAwLCAtMTA4Mi4wMDAwMDApIHRyYW5zbGF0ZSgxODA5LjAwMDAwMCwgMTA4Mi4wMDAwMDApIHRyYW5zbGF0ZSg2LjAwMDAwMCwgNi4wMDAwMDApIi8+CiAgICAgICAgICAgICAgICAgICAgPHBhdGggZmlsbD0iIzk3OTc5NyIgZD0iTTEyIDBMMTIgNCAxMCA0IDEwIDIgMiAyIDIgMTAgNCAxMCA0IDEyIDAgMTIgMCAweiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE4MDkuMDAwMDAwLCAtMTA4Mi4wMDAwMDApIHRyYW5zbGF0ZSgxODA5LjAwMDAwMCwgMTA4Mi4wMDAwMDApIHRyYW5zbGF0ZSg2LjAwMDAwMCwgNi4wMDAwMDApIi8+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPgo=" />
          </span>
          <span
            className="border-bottom cursor-pointer"
            title="Undo"
            onClick={undo}
          >
            <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNiIgaGVpZ2h0PSIzNiIgdmlld0JveD0iMCAwIDM2IDM2Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGc+CiAgICAgICAgICAgIDxnPgogICAgICAgICAgICAgICAgPHBhdGggZD0iTTAgMEgzNlYzNkgweiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE4NTUuMDAwMDAwLCAtMTA4Mi4wMDAwMDApIHRyYW5zbGF0ZSgxODU1LjAwMDAwMCwgMTA4Mi4wMDAwMDApIi8+CiAgICAgICAgICAgICAgICA8Zz4KICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMCAwTDI0IDAgMjQgMjQgMCAyNHoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xODU1LjAwMDAwMCwgLTEwODIuMDAwMDAwKSB0cmFuc2xhdGUoMTg1NS4wMDAwMDAsIDEwODIuMDAwMDAwKSB0cmFuc2xhdGUoNi4wMDAwMDAsIDYuMDAwMDAwKSIvPgogICAgICAgICAgICAgICAgICAgIDxwYXRoIGZpbGw9IiMwNDgxQTAiIGQ9Ik0xMi41IDhjLTIuNjUgMC01LjA1Ljk5LTYuOSAyLjZMMiA3djloOWwtMy42Mi0zLjYyYzEuMzktMS4xNiAzLjE2LTEuODggNS4xMi0xLjg4IDMuNTQgMCA2LjU1IDIuMzEgNy42IDUuNWwyLjM3LS43OEMyMS4wOCAxMS4wMyAxNy4xNSA4IDEyLjUgOHoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xODU1LjAwMDAwMCwgLTEwODIuMDAwMDAwKSB0cmFuc2xhdGUoMTg1NS4wMDAwMDAsIDEwODIuMDAwMDAwKSB0cmFuc2xhdGUoNi4wMDAwMDAsIDYuMDAwMDAwKSIvPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4K" />
          </span>
          <span
            className="border-bottom cursor-pointer"
            title="Redo"
            onClick={redo}
          >
            <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNiIgaGVpZ2h0PSIzNiIgdmlld0JveD0iMCAwIDM2IDM2Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGc+CiAgICAgICAgICAgIDxnPgogICAgICAgICAgICAgICAgPHBhdGggZD0iTTAgMEgzNlYzNkgweiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE5MDEuMDAwMDAwLCAtMTA4Mi4wMDAwMDApIHRyYW5zbGF0ZSgxOTAxLjAwMDAwMCwgMTA4Mi4wMDAwMDApIi8+CiAgICAgICAgICAgICAgICA8Zz4KICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMCAwTDI0IDAgMjQgMjQgMCAyNHoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xOTAxLjAwMDAwMCwgLTEwODIuMDAwMDAwKSB0cmFuc2xhdGUoMTkwMS4wMDAwMDAsIDEwODIuMDAwMDAwKSB0cmFuc2xhdGUoNi4wMDAwMDAsIDYuMDAwMDAwKSIvPgogICAgICAgICAgICAgICAgICAgIDxwYXRoIGZpbGw9IiMwNDgxQTAiIGQ9Ik0xOC40IDEwLjZDMTYuNTUgOC45OSAxNC4xNSA4IDExLjUgOGMtNC42NSAwLTguNTggMy4wMy05Ljk2IDcuMjJMMy45IDE2YzEuMDUtMy4xOSA0LjA1LTUuNSA3LjYtNS41IDEuOTUgMCAzLjczLjcyIDUuMTIgMS44OEwxMyAxNmg5VjdsLTMuNiAzLjZ6IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTkwMS4wMDAwMDAsIC0xMDgyLjAwMDAwMCkgdHJhbnNsYXRlKDE5MDEuMDAwMDAwLCAxMDgyLjAwMDAwMCkgdHJhbnNsYXRlKDYuMDAwMDAwLCA2LjAwMDAwMCkiLz4KICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+Cg==" />
          </span>
          <span
            className={`border-bottom cursor-pointer deleteElement_${props.document.id}`}
            title="Delete Selected Object"
            onClick={deleteSelected}
          >
            <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNiIgaGVpZ2h0PSIzNiIgdmlld0JveD0iMCAwIDM2IDM2Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGc+CiAgICAgICAgICAgIDxnPgogICAgICAgICAgICAgICAgPHBhdGggZD0iTTAgMEgzNlYzNkgweiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE5NDYuMDAwMDAwLCAtMTA4Mi4wMDAwMDApIHRyYW5zbGF0ZSgxOTQ2LjAwMDAwMCwgMTA4Mi4wMDAwMDApIi8+CiAgICAgICAgICAgICAgICA8ZyBmaWxsPSIjMDQ4MUEwIj4KICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMTMuMTY3IDE4LjVoLTEwVjZoMTBtMi0yaC0xNHYxNC4xNjdjMCAxLjI4MyAxLjA1IDIuMzMzIDIuMzMzIDIuMzMzaDkuMzMzYzEuMjg0IDAgMi4zMzQtMS4wNSAyLjMzNC0yLjMzM1Y0ek0xMS4wODMgMEw1LjI1IDAgNC4wODMuOTk3IDAgLjk5NyAwIDMgMTYuMzMzIDMgMTYuMzMzLjk5NyAxMi4yNS45OTd6IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTk0Ni4wMDAwMDAsIC0xMDgyLjAwMDAwMCkgdHJhbnNsYXRlKDE5NDYuMDAwMDAwLCAxMDgyLjAwMDAwMCkgdHJhbnNsYXRlKDkuODMzMzMzLCA3Ljc1MDAwMCkiLz4KICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+Cg==" />
          </span>
        </div>
      )}
      <canvas ref={canvasRef} />
    </div>
  );
};

export default FabricCanvasStudent;
