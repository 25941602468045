import React, { Fragment, useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import logo from "../assets/images/Punditlogo.png";
import { Link } from "react-router-dom";
import Layout from "../layouts";
import Breadcrumb from "../components/UI/breadcrumb";
import axios from "axios";
import { useLocation } from "react-router-dom";
import JoinSection from "../components/Join";
import ConsentForm from "../components/Join/consent-form";
import { useHistory } from "react-router-dom";
import { getCurrentUser } from "../helpers/Utils";
import { Helmet } from "react-helmet";
import bowser from "bowser";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

let joinCode;

const Join = () => {
  let history = useHistory();
  const [loading, setLoading] = useState("");
  const [join, setJoin] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [allData, setAllData] = useState("");
  /* const[code, setCode] = useState("") */
  const [step1, setStep1] = useState(false);
  const [step2, setStep2] = useState("d-none");

  const [isConsentForm, setIsConsentForm] = useState(false);
  const [teacherEmail, setTeacherEmail] = useState("");

  let query = useQuery();
  let query_url = query.get("code");

  const JoinApi = (formData) => {
    localStorage.setItem("end_date", "");
    localStorage.setItem("unique_id", "");
    axios
      .post(`${process.env.REACT_APP_API_URL}/student/join-with-code`, formData)
      .then(function (res) {
        if (res.data.success) {
          setJoin(res.data.data.id);
          setAllData(res.data.data);
          setSuccess(res.data.message);
          if (getCurrentUser()) {
            assignSubmit(res.data.data.id);
          } else {
            setLoading(false);
            setError("");
            history.push(
              `/join-login?id=${res.data.data.id}&title=${res.data.data.title}&deadline=${res.data.data.deadline_date_time}`
            );
            localStorage.removeItem("current_timer");
          }
        } else {
          setLoading(false);
          setError(res.data.message);
          setTimeout(() => {
            setError("");
          }, 2000);
          setSuccess("");
        }
      });
  };

  useEffect(() => {
    if (query_url) {
      var formData = new FormData();
      formData.append("code", query_url);
      JoinApi(formData);
    }
  }, []);

  const JoinCode = (e) => {
    e.preventDefault();

    const braveBrowser = window.navigator;
    const browser = bowser.parse(window.navigator.userAgent);

    if ("brave" in braveBrowser || "_duckduckgoloader_" in braveBrowser) {
      alert(
        "Please ensure you are using either Safari or Chrome browser to proceed with the exam."
      );
    } else if (
      "version" in browser.browser &&
      (browser.browser.name === "Safari" || browser.browser.name === "Chrome")
    ) {
      setLoading(true);
      const formData = new FormData();
      formData.append("code", e.target.code.value);
      JoinApi(formData);
    } else {
      alert(
        "Please ensure you are using either Safari or Chrome browser to proceed with the exam."
      );
    }
  };

  const assignSubmit = (id) => {
    const formData = new FormData();
    formData.append("worksheet_id", id);
    formData.append("student_id_or_name", getCurrentUser().student_id);
    formData.append("password", getCurrentUser().password);
    axios
      .post(`${process.env.REACT_APP_API_URL}/student/verify-student`, formData)
      .then(function (res) {
        if (res.data.success) {
          localStorage.removeItem("current_document_id");
          setError("");
          localStorage.setItem("current_userName", res.data.student.name);
          localStorage.setItem("unique_id", res.data.data.id);
          localStorage.setItem("test_time", res.data.data.remaining_time);
          history.push(`/test/${id}`);
        } else {
          setLoading(false);
          setError(res.data.message);
          if (res.data.data && res.data.data.allow_access === 0) {
            setTeacherEmail(res.data.data.teacher_email);
            setIsConsentForm(true);
          }
        }
      });
  };

  return (
    <Fragment>
      <Helmet>
        <title>Punditin Join via CODE</title>
        {/* <meta name="description" content="Punditin Join via CODE" /> */}
      </Helmet>
      <Layout>
        <Breadcrumb title="Join px-3" />
        <div className={`join ${step1}`}>
          <div className="join_studend">
            <Link to={`${process.env.PUBLIC_URL + "/"}`}>
              <img alt="logo" src={logo} className="logoDesign mb-5" />
            </Link>
            {error ? (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            ) : (
              ""
            )}
            {success ? (
              <div className="alert alert-success" role="alert">
                {success}
              </div>
            ) : (
              ""
            )}
            <form
              onSubmit={(e) => JoinCode(e)}
              className="justify-content-center"
            >
              <div className="form-group">
                <input
                  type="text"
                  name="code"
                  className="form-control p-3 w-100"
                  placeholder="Enter Code"
                />
              </div>
              {loading ? (
                <button
                  className="btn btn-lg w-100 mt-2 disabled"
                  style={{
                    backgroundColor: "rgb(248 129 44)",
                    color: "white",
                  }}
                >
                  Loading
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-lg w-100 mt-2"
                  onClick={(e) => JoinApi(e)}
                  style={{
                    backgroundColor: "rgb(248 129 44)",
                    color: "white",
                  }}
                >
                  JOIN CODE
                </button>
              )}
              <p className="policy_text">
                By entering this code you confirm that you and/or the teacher
                who provided you with this code have the ability to consent to
                the{" "}
                <Link
                  style={{
                    color: "rgb(248, 129, 44)",
                    textDecoration: "none",
                  }}
                  to={`${process.env.PUBLIC_URL + "/term-condition"}`}
                >
                  Terms and Conditions
                </Link>{" "}
                and{" "}
                <Link
                  style={{
                    color: "rgb(248, 129, 44)",
                    textDecoration: "none",
                  }}
                  to={`${process.env.PUBLIC_URL + "/privacy-policy"}`}
                >
                  Privacy Policy
                </Link>
                and give that consent by using this site.
              </p>
            </form>
          </div>
        </div>
        {isConsentForm ? (
          <ConsentForm
            setIsConsentForm={setIsConsentForm}
            teacherEmail={teacherEmail}
          />
        ) : (
          ""
        )}
      </Layout>
    </Fragment>
  );
};

export default Join;
