import React from "react";
import { FaPhone, FaRegEnvelope } from "react-icons/fa";

const MobileWidgets = ({ styles }) => {
    return (
        <div className={styles.offcanvasWidgetArea}>
            <div className={styles.offCanvasContactWidget}>
                <div className={styles.headerContactInfo}>
                    <ul className={styles.headerContactInfoList}>
                        <li>
                            <FaPhone />{" "}
                            <a
                                href="tel:+971504661345"
                                style={{ color: "#4d6cb4" }}
                            >
                                +971504661345
                            </a>
                        </li>
                        <li>
                            <FaRegEnvelope />{" "}
                            <a
                                href="mailto:admin@punditin.com"
                                style={{ color: "#4d6cb4" }}
                            >
                                admin@punditin.com
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default MobileWidgets;
