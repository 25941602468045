/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, Fragment, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Breadcrumb from "../components/UI/breadcrumb";
import Layout from "../layouts";
import { useParams } from "react-router-dom";
import axios from "axios";
import Loaders from "../components/loader";
import { getCurrentUser, setCurrentUser } from "../helpers/Utils";
import { Helmet } from "react-helmet";
import { Card, CardBody, Row } from "reactstrap";
import "react-tabs/style/react-tabs.css";
import Dummy from "../assets/images/default-thumb.png";

import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { MdOutlineVideocamOff } from "react-icons/md";

const AdminViewSingleCourse = () => {
  const { id } = useParams();
  const [allData, setAllData] = useState([]);
  const [activeLink, setActiveLink] = useState("");
  const [activeLesson, setActiveLesson] = useState("");
  const [activeLessonResource, setActiveLessonResource] = useState("");
  const [isEnroll, setIssetEnroll] = useState(1);
  const [enrollLoading, setEnrollLoading] = useState(false);
  const [loader, setLoader] = useState(true);
  const [message, setMessage] = useState("");
  const [projectTab, setProjectTab] = useState(0);

  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    if (isEnroll) {
      setActiveTab(tabNumber);
    }
  };

  const getSingleLesson = () => {
    const currentTimestampInSeconds = Math.floor(Date.now() / 1000);
    let url = `${process.env.REACT_APP_API_URL}/public-student-course-view/${id}?timeStamp=${currentTimestampInSeconds}`;
    if (getCurrentUser()) {
      url = `${process.env.REACT_APP_API_URL}/student/course-view/${id}?timeStamp=${currentTimestampInSeconds}`;
    }
    axios
      .get(url, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${getCurrentUser()?.token}`,
        },
      })
      .then(function (res) {
        if (res.data.success) {
          // setIssetEnroll(res.data.data.is_enrolled);
          // if (res.data.data.is_enrolled) {
          //   setProjectTab(1);
          // }
          setAllData(res.data.data);
          // if (res.data.data?.lessons[0]?.lesson_link) {
          //   const lessonLink = res.data.data?.lessons[0]?.lesson_link;
          //   if (isYouTubeLink(lessonLink)) {
          //     const videoID = getYouTubeID(lessonLink);
          //     if (videoID) {
          //       setActiveLink(`https://www.youtube.com/embed/${videoID}`);
          //     } else {
          //       setActiveLink(res.data.data?.lessons[0]?.lesson_link);
          //     }
          //   } else {
          //     setActiveLink(res.data.data?.lessons[0]?.lesson_link);
          //   }
          // }
          // setActiveLesson(res.data.data?.lessons[0]);
          // setActiveLessonResource(res.data.data?.lessons[0]?.resources[0]);
        }
        setLoader(false);
      });
  };

  const isYouTubeLink = (link) => {
    return (
      link?.includes("youtube.com/watch") ||
      link?.includes("youtube.com/embed") ||
      link?.includes("youtu.be")
    );
  };

  const markCompleted = (id) => {
    const formData = new FormData();
    formData.append("student_id", getCurrentUser().id);
    formData.append("lesson_id", id);
    formData.append("course_id", allData.id);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/student/update-lesson-status`,
        formData,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser()?.token}`,
          },
        }
      )
      .then(function (res) {
        setAllData(res.data.data);
      });
  };

  const playNextVideo = (link) => {
    const lessonLink = link;
    if (isYouTubeLink(lessonLink)) {
      const videoID = getYouTubeID(lessonLink);
      if (videoID) {
        setActiveLink(`https://www.youtube.com/embed/${videoID}`);
      } else {
        setActiveLink(link);
      }
    } else {
      setActiveLink(link);
    }
  };

  const getYouTubeID = (youLink) => {
    var link = youLink;
    var regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
    var match = link.match(regExp);
    if (match && match[2].length == 11) {
      var ID = "";
      var url = youLink
        .replace(/(>|<)/gi, "")
        .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
      if (url[2] !== undefined) {
        ID = url[2].split(/[^0-9a-z_\-]/i);
        ID = ID[0];
        return ID;
      }
    }
    return null;
  };

  const enrollNow = () => {
    setEnrollLoading(true);
    const formData = new FormData();
    formData.append("course_id", allData.id);
    // formData.append("student_id", getCurrentUser().id);
    axios
      .post(`${process.env.REACT_APP_API_URL}/student/order-add`, formData, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${getCurrentUser()?.token}`,
        },
      })
      .then(function (res) {
        if (res.data.link) {
          window.open(res.data.link, "_self");
        } else if (res.data.status) {
          setIssetEnroll(1);
          setEnrollLoading(true);
          setMessage(res.data.message);
          setTimeout(() => {
            setMessage("");
          }, 3000);
        } else {
          setMessage(res.data.message);
          setTimeout(() => {
            setMessage("");
          }, 3000);
          setEnrollLoading(true);
        }
      });
  };

  useEffect(() => {
    getSingleLesson();
  }, []);

  return (
    <Fragment>
      <Layout>
        <Breadcrumb title="Pricing my-5" />
        {loader ? (
          <Loaders />
        ) : (
          <>
            <Helmet>
              <title>{allData?.course_title}</title>
            </Helmet>
            <div className="container">
              <h2 className="mt-4 mb-0">{allData?.course_title}</h2>
              <div className="video-player">
                <div className="row">
                  <div
                    className={`col-lg-7 ${
                      activeLesson
                        ? ""
                        : "d-flex align-items-center justify-content-center"
                    }`}
                  >
                    <div
                      className={`video-player-video ${
                        activeLesson
                          ? activeLink
                            ? "bg-light"
                            : "pt-0"
                          : "pt-0"
                      }`}
                    >
                      {activeLesson ? (
                        activeLink ? (
                          <iframe
                            src={activeLink}
                            title={activeLesson?.lesson_name}
                            frameBorder="0"
                            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                            style={{
                              pointerEvents: `${isEnroll ? "all" : "none"}`,
                            }}
                          ></iframe>
                        ) : (
                          <img
                            className="mw-100 course_thumbb"
                            src={
                              allData.thumbnail ? allData.thumbnail : Dummy.src
                            }
                            style={{
                              objectPosition: "center",
                              margin: "0 auto",
                              display: "block",
                            }}
                          />
                        )
                      ) : (
                        <img
                          className="mw-100 course_thumbb"
                          src={
                            allData.thumbnail ? allData.thumbnail : Dummy.src
                          }
                          style={{
                            objectPosition: "center",
                            margin: "0 auto",
                            display: "block",
                          }}
                        />
                      )}
                    </div>
                    {activeLesson ? (
                      <>
                        <h5 className="mt-3">{activeLesson?.lesson_name}</h5>
                        {activeLesson?.lesson_text_to_speech_audio_enabled ? (
                          <>
                            <audio
                              controls
                              controlsList="nodownload noplaybackrate"
                              src={
                                activeLesson?.lesson_text_to_speech_audio_file
                              }
                              className="w-100 d-block mt-3"
                            />
                            <span className="d-block mb-3">
                              Created with AI-powered Text-to-Speech technology.
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                        <p>
                          {activeLesson?.lesson_description &&
                            activeLesson?.lesson_description}
                        </p>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-lg-5">
                    <div className="summary">
                      <h4>
                        <strong>{allData?.lessons?.length} Lessons</strong> (
                        {allData?.duration})
                      </h4>
                    </div>
                    <div className="playlist">
                      <div>
                        <ul>
                          {allData?.lessons?.map((item, i) => (
                            <li
                              className={
                                item.lesson_name === activeLesson?.lesson_name
                                  ? "selected"
                                  : ""
                              }
                              key={i}
                            >
                              <div
                                className="playlist_data"
                                onClick={() => {
                                  // setProjectTab(1);
                                  // if (!item.is_completed && isEnroll) {
                                  //   markCompleted(item.id);
                                  // }
                                  playNextVideo(item.lesson_link);
                                  setActiveLesson("");
                                  setTimeout(() => {
                                    setActiveLesson(item);
                                  }, 100);
                                }}
                              >
                                <div className="d-flex gap-1">
                                  <div className="session-item-rank">
                                    <h3 data-rank={i + 1}>{i + 1}. </h3>
                                  </div>
                                  <div className="session-item-title">
                                    <h3> {item.lesson_name}</h3>
                                  </div>
                                </div>
                                <div className="duration d-flex align-items-center gap-2">
                                  <h3>{item.lesson_duration}</h3>
                                  {isEnroll ? (
                                    item.is_completed ? (
                                      <div className="marked-com">
                                        <IoCheckmarkCircleOutline />
                                      </div>
                                    ) : item.lesson_name ===
                                      activeLesson?.lesson_name ? (
                                      <div className="marked-com">
                                        <IoCheckmarkCircleOutline />
                                      </div>
                                    ) : (
                                      ""
                                    )
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              {item.lesson_name ===
                              activeLesson?.lesson_name ? (
                                <div
                                  className={`resource_list ${
                                    item?.resources?.length ? "p-2" : ""
                                  }`}
                                >
                                  {item.resources?.map((data, index) => (
                                    <div
                                      className={`single ${
                                        data.id === activeLessonResource?.id
                                          ? "selected"
                                          : !isEnroll
                                          ? "disabled"
                                          : ""
                                      }`}
                                      onClick={() => {
                                        if (isEnroll) {
                                          setProjectTab(1);
                                          setActiveLessonResource("");
                                          setTimeout(() => {
                                            setActiveLessonResource(data);
                                          }, 100);
                                        }
                                      }}
                                      key={index}
                                    >
                                      {data.resource_title}
                                    </div>
                                  ))}
                                </div>
                              ) : !isEnroll ? (
                                ""
                              ) : (
                                ""
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div>
                      {!isEnroll ? (
                        getCurrentUser() ? (
                          enrollLoading ? (
                            <button
                              className="btn btn-success btn-lg w-100 mt-2 disabled"
                              disabled
                              type="button"
                            >
                              Loading
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-success btn-lg w-100 mt-2"
                              onClick={() => enrollNow()}
                            >
                              Enroll Now{" "}
                              {allData.pricing !== 0
                                ? allData.pricing !== undefined ||
                                  allData.pricing !== null
                                  ? `$${allData.pricing}`
                                  : ""
                                : ""}
                            </button>
                          )
                        ) : (
                          <Link
                            to="/login"
                            className="btn btn-success btn-lg w-100 mt-2 text-light"
                          >
                            Login to Enroll Now{" "}
                            {allData.pricing !== 0
                              ? allData.pricing !== undefined ||
                                allData.pricing !== null
                                ? `$${allData.pricing}`
                                : ""
                              : ""}
                          </Link>
                        )
                      ) : (
                        ""
                      )}
                      {message ? (
                        <p className="mt-3 alert alert-primary">{message}</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="lesson-tabs">
                  <div className="react-tabs">
                    <ul className="react-tabs__tab-list">
                      <li
                        onClick={() => handleTabClick(1)}
                        className={`react-tabs__tab  ${
                          activeTab === 1 ? "react-tabs__tab--selected" : ""
                        }`}
                      >
                        About
                      </li>
                      <li
                        onClick={() => handleTabClick(2)}
                        className={`react-tabs__tab ${
                          activeTab === 2 ? "react-tabs__tab--selected" : ""
                        }`}
                      >
                        Projects & Resources
                      </li>
                      <li
                        onClick={() => handleTabClick(3)}
                        className={`react-tabs__tab ${
                          activeTab === 3 ? "react-tabs__tab--selected" : ""
                        }`}
                      >
                        Quiz
                      </li>
                    </ul>
                  </div>
                  <div>
                    {activeTab === 1 && (
                      <div>
                        {allData?.text_to_speech_audio_enabled ? (
                          <>
                            <audio
                              controls
                              controlsList="nodownload noplaybackrate"
                              src={allData?.text_to_speech_audio_file}
                              className="w-100 d-block mt-3"
                            />
                            <span className="d-block mb-3">
                              Created with AI-powered Text-to-Speech technology.
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: allData?.description,
                          }}
                        />
                      </div>
                    )}
                    {activeTab === 2 && (
                      <div>
                        {activeLessonResource ? (
                          <div className="resources">
                            <div className="d-flex justify-content-between align-items-center mb-4">
                              <h4 className="m-0">
                                {activeLessonResource?.resource_title}
                              </h4>
                              {allData.is_content_downloadable &&
                              isEnroll &&
                              activeLessonResource?.file ? (
                                <a
                                  href={activeLessonResource?.file}
                                  target="_blank"
                                  download
                                  className="btn btn-primary text-white"
                                >
                                  Download
                                </a>
                              ) : (
                                ""
                              )}
                            </div>
                            {activeLessonResource?.resource_text_to_speech_audio_file ? (
                              <>
                                <audio
                                  controls
                                  controlsList="nodownload noplaybackrate"
                                  src={
                                    activeLessonResource?.resource_text_to_speech_audio_file
                                  }
                                  className="w-100 d-block mt-3"
                                />
                                <span className="d-block mb-3">
                                  Created with AI-powered Text-to-Speech
                                  technology.
                                </span>
                              </>
                            ) : (
                              ""
                            )}
                            <div
                              className="mb-3"
                              dangerouslySetInnerHTML={{
                                __html:
                                  activeLessonResource?.resource_description,
                              }}
                            />
                            {activeLessonResource?.file ? (
                              activeLessonResource?.file
                                ?.toLowerCase()
                                .endsWith(".png") ||
                              activeLessonResource?.file
                                ?.toLowerCase()
                                .endsWith(".jpg") ||
                              activeLessonResource?.file
                                ?.toLowerCase()
                                .endsWith(".jpeg") ? (
                                <img
                                  src={activeLessonResource?.file}
                                  alt={activeLessonResource?.resource_title}
                                  width="100%"
                                  height="auto"
                                />
                              ) : activeLessonResource?.file
                                  ?.toLowerCase()
                                  .endsWith(".pdf") ? (
                                <iframe
                                  src={`${activeLessonResource?.file}#toolbar=0`}
                                  toolbar="0"
                                  width="100%"
                                  height="700"
                                  title={activeLessonResource?.resource_title}
                                ></iframe>
                              ) : (
                                <iframe
                                  src={`https://docs.google.com/gview?url=${activeLessonResource?.file}&embedded=true`}
                                  toolbar="0"
                                  width="100%"
                                  sandbox="allow-scripts allow-same-origin"
                                  height="700"
                                  title={activeLessonResource?.resource_title}
                                ></iframe>
                              )
                            ) : (
                              <span className="alert alert-danger d-table w-auto">
                                There is no file added.
                              </span>
                            )}
                          </div>
                        ) : (
                          <span className="alert alert-info d-table w-auto">
                            Please select resources in any lesson to view it's
                            content.
                          </span>
                        )}
                      </div>
                    )}
                    {activeTab === 3 && (
                      <div>
                        {activeLesson ? (
                          activeLesson.worksheets.length ? (
                            activeLesson.worksheets.map((item) => (
                              <Card
                                className="col-md-4 m-2 fw-bold fs-5"
                                style={{
                                  boxShadow:
                                    "rgba(180, 184, 184, 0.5) 0px 2px 4px 0px",
                                  borderRadios: "8px",
                                }}
                              >
                                <CardBody className="p-3 font-weight-light">
                                  <h4>{item.title}</h4>
                                  <small className="d-block font-weight-light">
                                    Please enter this code{" "}
                                    <span style={{ color: "#4e6cb4" }}>
                                      {item.code}
                                    </span>{" "}
                                    to join the Quiz.
                                  </small>
                                  <Link
                                    className="btn btn-primary text-white mt-3"
                                    href={`/join`}
                                  >
                                    Join Now
                                  </Link>
                                </CardBody>
                              </Card>
                            ))
                          ) : (
                            <span className="alert alert-danger d-table w-auto">
                              There is no quiz.
                            </span>
                          )
                        ) : (
                          <span className="alert alert-info d-table w-auto">
                            Please select any lesson to view it's quiz.
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Layout>
    </Fragment>
  );
};

export default AdminViewSingleCourse;
