/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import Equationeditor from "../../Math/Equationeditor";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import Xarrow, { Xwrapper } from "react-xarrows";

const CUSTOM_OPERATORS = [
  ["\\pm", "\\pm"],
  ["\\sqrt{x}", "\\sqrt"],
  ["\\sqrt[3]{x}", "\\sqrt[3]{}"],
  ["\\sqrt[n]{x}", "\\nthroot"],
  ["\\frac{x}{y}", "\\frac"],
  ["\\sum^{s}_{x}{d}", "\\sum"],
  ["\\prod^{s}_{x}{d}", "\\prod"],
  ["\\coprod^{s}_{x}{d}", "\\coprod"],
  ["\\int^{s}_{x}{d}", "\\int"],
  ["\\binom{n}{k}", "\\binom"],
];

let CheckboxArr;
let ColumnArr;

const StudentSubmittedElement = (props) => {
  const [multiOpt, setMultiOpt] = useState("");
  const [changeInput, setChangeInput] = useState([1, 2, 3]);
  const [submittedAnswer, setSubmittedAnswer] = useState([]);
  const [columnInput, setColumnInput] = useState([1]);

  const [operators] = useState(CUSTOM_OPERATORS);
  const options = { operators };

  setTimeout(() => {
    if (props.data.element_type === "checkbox") {
      var myElements = document.getElementsByTagName("input");
      for (var i = 0; i < myElements.length; i++) {
        if (myElements[i].type === "checkbox") {
          var counts = i + 1;
          myElements[i].setAttribute("value", i + 1);
          myElements[i].setAttribute("id", "checkbox-" + counts + "");
        }
      }
      if (
        props.data.default_answer !== null &&
        props.data.default_answer !== undefined
      ) {
        props.data.default_answer.map((item) => {
          let defCheckedInput = document.getElementById(
            "checkbox-" + item + ""
          );
          if (defCheckedInput !== null) {
            defCheckedInput.setAttribute("checked", true);
          }
        });
      }
    } else if (props.data.element_type === "radio") {
      var myElements = document.getElementsByClassName("radio_count");
      if (
        props.data.default_answer !== null &&
        props.data.default_answer !== undefined
      ) {
        for (var j = 0; j < myElements.length; j++) {
          var count = j + 1;
          if (count === props.data.default_answer) {
            myElements[j].setAttribute("checked", true);
          }
        }
      }
    } else if (props.data.element_type === "drag_drop") {
      let elementToMove = document.getElementById(props.data.id);
      let destinationElement = document.getElementById(
        props.data.default_answer
      );
      destinationElement.appendChild(elementToMove);
    }
  }, 500);

  useEffect(() => {
    if (props.data.element_type === "checkbox") {
      if (props.data.default_answer) {
        setSubmittedAnswer(props.data?.default_answer);
      }

      if (props.data.settings !== null && props.data.settings !== undefined) {
        // rowValue
        CheckboxArr = [];
        setMultiOpt(props.data.settings.rowValue);
        for (var i = 1; i <= props.data.settings.rowValue; i++) {
          CheckboxArr.push(i);
        }
        setChangeInput(CheckboxArr);
        // colValue
        ColumnArr = [];
        for (var j = 1; j <= props.data.settings.colValue; j++) {
          ColumnArr.push(j);
        }
        setColumnInput(ColumnArr);
      }
    } else if (props.data.element_type === "radio") {
      if (props.data.settings !== null && props.data.settings !== undefined) {
        // rowValue
        CheckboxArr = [];
        setMultiOpt(props.data.settings.rowValue);
        for (var k = 1; k <= props.data.settings.rowValue; k++) {
          CheckboxArr.push(k);
        }
        setChangeInput(CheckboxArr);
        // colValue
        ColumnArr = [];
        for (var l = 1; l <= props.data.settings.colValue; l++) {
          ColumnArr.push(l);
        }
        setColumnInput(ColumnArr);
      }
    }
  }, []);

  return (
    <>
      {props.data.element_type === "checkbox" ? (
        <div
          key={props.data.id}
          className="work_elements checkbox"
          style={{
            width: props.data.element_size && props.data.element_size.x,
            height: props.data.element_size && props.data.element_size.y,
            transform: `translate(${
              props.data.questions && props.data.questions.x + 7
            }px, ${props.data?.questions && props.data.questions?.y}px)`,
            minWidth: "24px",
          }}
        >
          <div
            className="work_elements checkbox"
            style={{
              width: "100%",
              height: props.data.element_size && props.data.element_size.y,
              gridTemplateColumns: `repeat(${
                props.data?.settings && props.data?.settings?.colValue
              }, auto)`,
              gridTemplateRows: `repeat(${props.data.settings && 4}, auto )`,
            }}
          >
            {columnInput.map((e, i) => (
              <>
                {changeInput.map((e, index) => (
                  <label>
                    {submittedAnswer.includes(`${i + 1}${index + 1}`) ? (
                      <input
                        id={`${i + 1}${index + 1}`}
                        disabled
                        className="d-none"
                        type="checkbox"
                        value={`${i + 1}${index + 1}`}
                        name={`${props.data.element_id}-checkbox`}
                        defaultChecked="checked"
                      />
                    ) : (
                      <input
                        id={`${i + 1}${index + 1}`}
                        disabled
                        className="d-none"
                        type="checkbox"
                        value={`${i + 1}${index + 1}`}
                        name={`${props.data.element_id}-checkbox`}
                      />
                    )}
                    <span
                      htmlFor={`${i + 1}${index + 1}`}
                      className="checkmark"
                    ></span>
                  </label>
                ))}
              </>
            ))}
          </div>
        </div>
      ) : (
        ""
      )}
      {/* Radio elements */}
      {props.data.element_type === "radio" ? (
        <div
          className="work_elements radio"
          style={{
            width: props.data.element_size && props.data.element_size.x,
            height: props.data.element_size && props.data.element_size.y,
            transform: `translate(${
              props.data.questions && props.data.questions.x + 7
            }px, ${props.data.questions && props.data.questions.y}px)`,
            minWidth: "24px",
          }}
          key={props.data.id}
        >
          <div
            className="work_elements radio"
            style={{
              width: "100%",
              height: props.data.element_size && props.data.element_size.y,
              gridTemplateColumns: `repeat(${
                props.data.settings && props.data.settings.colValue
              }, auto)`,
              gridTemplateRows: `repeat(${
                props.data.settings && props.data.settings.rowValue
              }, auto )`,
            }}
          >
            {columnInput.map((e, i) => (
              <>
                {changeInput.map((e, index) => (
                  <label>
                    <input
                      id={`${i + 1}${index + 1}`}
                      className="d-none"
                      disabled
                      type="radio"
                      value={`${i + 1}${index + 1}`}
                      name={`${props.data.element_id}-radio`}
                      defaultChecked={
                        props.data.default_answer === `${i + 1}${index + 1}`
                          ? "checked"
                          : ""
                      }
                    />

                    <span
                      htmlFor={`${i + 1}${index + 1}`}
                      className="checkmark"
                    ></span>
                  </label>
                ))}
              </>
            ))}
          </div>
        </div>
      ) : (
        ""
      )}
      {props.data.element_type === "audio_recording" ? (
        <div
          key={props.data.id}
          className="work_elements audio_recording"
          style={{
            minHeight: "70px",
            minWidth: "225px",
            width: props.data.element_size && props.data.element_size.x,
            height: props.data.element_size && props.data.element_size.y,
            transform: `translate(${
              props.data.questions && props.data.questions.x
            }px, ${props.data.questions && props.data.questions.y}px)`,
          }}
        >
          {props.data.attachment ? (
            <audio
              src={props.data.attachment}
              controls="controls"
              controlsList="nodownload noplaybackrate"
              // onEnded={() => setPlay(false)}
              className="w-100"
            />
          ) : (
            "Audio not available"
          )}
        </div>
      ) : (
        ""
      )}
      {props.data.element_type === "text" ? (
        <div
          key={props.data.id}
          className="work_elements"
          style={{
            width: props.data.element_size && props.data.element_size.x,
            height: props.data.element_size && props.data.element_size.y,
            transform: `translate(${
              props.data.questions && props.data.questions.x + 7
            }px, ${props.data.questions && props.data.questions.y}px)`,
            display: "inline-block",
          }}
        >
          <textarea
            className="form-control"
            disabled
            value={props?.data?.default_answer}
            style={{
              width: "100%",
              height: "100%",
              padding: "5px",
              resize: "none",
              minHeight: "unset",
              fontWeight: "bold",
            }}
            dir={props.worksheetid.student_rtl_enabled ? "rtl" : "ltr"}
            name="text"
          />
        </div>
      ) : (
        ""
      )}
      {props.data.element_type === "dropdown" ? (
        <div
          key={props.data.id}
          className="work_elements dropdown"
          style={{
            width: props.data.element_size && props.data.element_size.x,
            height: props.data.element_size && props.data.element_size.y,
            transform: `translate(${
              props.data.questions && props.data.questions.x + 7
            }px, ${props.data.questions && props.data.questions.y}px)`,
            display: "inline-block",
          }}
        >
          <select
            name="dropdown"
            disabled
            dir={props.worksheetid.rtl_enabled ? "rtl" : ""}
            value={props?.data?.default_answer}
            className="form-control"
            style={{
              height: "100%",
              width: "100%",
              fontWeight: "bold",
            }}
          >
            <option value="" disabled selected hidden>
              Select
            </option>
            {props?.data?.element_data?.map((val) => (
              <option value={val.name}>{val.name}</option>
            ))}
          </select>
        </div>
      ) : (
        ""
      )}
      {props.data.element_type === "youtube_video" ? (
        <div
          key={props.data.id}
          className="work_elements youtube_video"
          style={{
            width: props.data.element_size && props.data.element_size.x,
            height: props.data.element_size && props.data.element_size.y,
            transform: `translate(${
              props.data.questions && props.data.questions.x + 7
            }px, ${props.data.questions && props.data.questions.y}px)`,
            display: "inline-block",
            minHeight: "100px",
            minWidth: "200px",
          }}
        >
          <iframe
            style={{
              padding: "0",
              resize: "none",
              minHeight: "unset",
            }}
            width="100%"
            height="100%"
            title="he"
            src={`https://www.youtube.com/embed/${props.data.element_data}`}
          ></iframe>
        </div>
      ) : (
        ""
      )}
      {props.data.element_type === "hotspot" ? (
        <div
          key={props.data.id}
          className="work_elements hotspot"
          style={{
            width: props.data.element_size?.x,
            height: props.data.element_size?.y,
            transform: `translate(${
              props.data.questions && props.data.questions.x + 7
            }px, ${props.data.questions && props.data.questions.y}px)`,
            display: "inline-block",
          }}
        >
          <div
            disabled
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "5px",
              border: "solid 1px #3e884f1a",
              backgroundColor:
                props.data.default_answer == 1 ? "#3e884f33" : "#c43d4b33",
            }}
          ></div>
        </div>
      ) : (
        ""
      )}
      {props.data.element_type === "drag_drop" ? (
        <>
          <div
            key={props.data.id}
            className="work_elements drag_drop"
            style={{
              width: props.data.element_size?.x,
              height: props.data.element_size?.y,
              minWidth: "35px",
              minHeight: "35px",
              left: `${props.data.questions?.x + 7}px`,
              top: `${props.data.questions?.y}px`,
              backgroundColor: "#ffffff99",
              display: "inline-block",
              border: "solid 1px #ddd",
            }}
          >
            <div
              style={{
                width: props.data.element_size?.x,
                height: props.data.element_size?.y,
                minWidth: "35px",
                minHeight: "35px",
              }}
            >
              <div
                className="position-relative overflow-hidden"
                id={props.data.id}
                style={{
                  width: props.data.element_size?.x,
                  height: props.data.element_size?.y,
                  minWidth: "35px",
                  minHeight: "35px",
                }}
              >
                <img
                  src={
                    props.data?.attachment
                      ? props.data.attachment
                      : props.mainDocument
                  }
                  className="main__d"
                  alt="s"
                  style={
                    props.data.attachment && props.data.attachment !== ""
                      ? {
                          pointerEvents: "none",
                          objectFit: "cover",
                          width: "100%",
                          height: "100%",
                        }
                      : {
                          transform: `translate(-${
                            props.data.questions && props.data.questions.x + 7
                          }px, -${
                            props.data.questions && props.data.questions.y
                          }px)`,
                          width: "800px",
                          position: "absolute",
                          left: "0",
                          top: "0",
                          pointerEvents: "none",
                          maxWidth: "unset",
                          maxHeight: "unset",
                        }
                  }
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {props.data.element_type === "dropzone" ? (
        <div
          key={props.data.id}
          className="work_elements"
          style={{
            width: props.data.element_size?.x,
            height: props.data.element_size?.y,
            left: `${props.data.questions?.x + 7}px`,
            top: `${props.data.questions?.y}px`,
            background: "#ffffff87",
            display: "inline-block",
            border: "solid 1px #000",
            minWidth: "35px",
            minHeight: "35px",
          }}
        >
          <div
            className="overflow-hidden"
            style={{
              minWidth: "35px",
              minHeight: "35px",
              width: props.data.element_size && props.data.element_size.x,
              height: props.data.element_size && props.data.element_size.y,
            }}
            id={props.data.id}
          ></div>
        </div>
      ) : (
        ""
      )}
      {props.data.element_type === "textarea" ? (
        <div
          key={props.data.id}
          className="work_elements textarea"
          style={{
            width: props.data.element_size && props.data.element_size.x,
            height: props.data.element_size && props.data.element_size.y,
            transform: `translate(${
              props.data.questions && props.data.questions.x + 7
            }px, ${props.data.questions && props.data.questions.y}px)`,
            display: "inline-block",
          }}
        >
          <div
            className={props.worksheetid.rtl_enabled ? "text-right p-2" : "p-2"}
            dangerouslySetInnerHTML={{ __html: props.data?.element_data }}
          />
        </div>
      ) : (
        ""
      )}
      {props.data.element_type === "image" ? (
        <div
          key={props.data.id}
          className="work_elements"
          style={{
            width: props.data.element_size && props.data.element_size.x,
            height: props.data.element_size && props.data.element_size.y,
            transform: `translate(${
              props.data.questions && props.data.questions.x + 7
            }px, ${props.data.questions && props.data.questions.y}px)`,
            display: "inline-block",
          }}
        >
          <img
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            src={props.data.attachment}
            alt=""
            srcset=""
          />
        </div>
      ) : (
        ""
      )}
      {props.data.element_type === "open_ended" ? (
        <div
          key={props.data.id}
          className="work_elements"
          style={{
            width: props.data.element_size && props.data.element_size.x,
            height: props.data.element_size && props.data.element_size.y,
            transform: `translate(${
              props.data.questions && props.data.questions.x + 7
            }px, ${props.data.questions && props.data.questions.y}px)`,
            display: "inline-block",
          }}
        >
          <textarea
            className="form-control"
            dir={props.worksheetid.student_rtl_enabled ? "rtl" : "ltr"}
            disabled
            style={{
              width: "100%",
              height: "100%",
              padding: "5px",
              resize: "none",
              minHeight: "unset",
              fontWeight: "bold",
              border: "0.5px solid black",
            }}
            name="openended"
          />
        </div>
      ) : (
        ""
      )}
      {props.data.element_type === "math_equation" ? (
        <div
          key={props.data.id}
          className="work_elements"
          style={{
            width: props.data.element_size && props.data.element_size.x,
            height: props.data.element_size && props.data.element_size.y,
            transform: `translate(${
              props.data.questions && props.data.questions.x + 7
            }px, ${props.data.questions && props.data.questions.y}px)`,
            display: "inline-block",
          }}
        >
          <Equationeditor
            options={options}
            key={JSON.stringify(options)}
            element_data={props?.data?.element_data}
            document_id={props?.data?.document_id}
            element_id={props.data?.element_id}
            worksheet_id={props.data?.worksheet_id}
          />
        </div>
      ) : (
        ""
      )}
      {props.data.element_type === "math_response" ? (
        <div
          key={props.data.id}
          className="work_elements math_response"
          id={`math_response-${props.data.id}`}
          style={{
            width: props.data.element_size && props.data.element_size.x,
            height: props.data.element_size && props.data.element_size.y,
            transform: `translate(${
              props.data.questions && props.data.questions.x + 7
            }px, ${props.data.questions && props.data.questions.y}px)`,
            display: "inline-block",
          }}
        >
          <Equationeditor
            options={options}
            key={JSON.stringify(options)}
            element_data={props?.data?.element_data}
            document_id={props?.data?.document_id}
            element_id={props.data?.element_id}
            worksheet_id={props.data?.worksheet_id}
            student_submitted={props.data?.submitted_answer}
          />
        </div>
      ) : (
        ""
      )}
      <Xwrapper>
        {props.data.element_type === "matching" ? (
          <div
            key={props.data.id}
            className="work_elements"
            style={{
              width: props.data.element_size?.x,
              height: props.data.element_size?.y,
              left: `${props.data.questions?.x + 7}px`,
              top: `${props.data.questions?.y}px`,
              background: "#ffffff87",
              display: "inline-block",
              border: "solid 1px #000",
            }}
          >
            <div
              id={`${props.data.unique_id}_2`}
              style={{
                width: "100%",
                height: "100%",
                border: "1px solid balck",
              }}
            >
              {props.data.attachment ? (
                <img
                  className="w-100 h-100"
                  style={{
                    objectFit: "cover",
                  }}
                  src={props.data.attachment}
                  alt="s"
                />
              ) : (
                ""
              )}
            </div>
            {props.data.child_id !== null ? (
              <Xarrow
                start={`${props.data.unique_id}_1`}
                end={`${props.data.unique_id}_2`}
                curveness={false}
                showHead={false}
                startAnchor={"middle"}
                endAnchor={"middle"}
                lineColor="#999898"
                strokeWidth="3"
              />
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        {props.data.element_type === "matching_pair" ? (
          <div
            key={props.data.id}
            className="work_elements"
            style={{
              width: props.data.element_size?.x,
              height: props.data.element_size?.y,
              left: `${props.data.questions?.x + 7}px`,
              top: `${props.data.questions?.y}px`,
              background: "#ffffff87",
              display: "inline-block",
              border: "solid 1px #000",
            }}
          >
            <div
              id={`${props.data.unique_id}_1`}
              style={{
                width: "100%",
                height: "100%",
                border: "1px solid balck",
              }}
            >
              {props.data.attachment ? (
                <img
                  className="w-100 h-100"
                  style={{
                    objectFit: "cover",
                  }}
                  src={props.data.attachment}
                  alt="s"
                />
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </Xwrapper>
    </>
  );
};

export default StudentSubmittedElement;
