import React from 'react'
import Loading from "../../assets/images/Loading-icon.gif";

const Loaders = () => {
    return (
        <div className="load text-center py-1">
            <img alt="" src={Loading} />
        </div>
    );
};

export default Loaders;
