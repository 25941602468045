/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import axios from "axios";
import { useHistory, useParams, Link, useLocation } from "react-router-dom";
import { getCurrentUser, setCurrentUser } from "../helpers/Utils";
import Loaders from "../components/loader";
import { MdClose, MdCheck } from "react-icons/md";
import SplitPane from "react-split-pane";
import Logo from "../assets/images/Punditlogo.png";
import RealTimeQuestion from "../components/real-time/single-question";
import Modal from "react-modal";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import io from "socket.io-client";
import TestCounter from "../components/ielts/timer";
import { Helmet } from "react-helmet";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    padding: "0",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "95%",
    overflow: "auto",
    maxWidth: "500px",
    maxHeight: "95%",
  },
};

let sequenceNumber = 1;

const RealTimeTest = () => {
  let history = useHistory();
  let params = new URLSearchParams(window.location.search);
  const [quizId] = useState(params.get("quiz_id"));
  const [quizClassId] = useState(params.get("quiz_class_id"));

  const [finalData, setFinalData] = useState("");
  const [quizRecordId, setQuizRecordId] = useState("");
  const [settings, setSettings] = useState("");
  const [loader, setLoader] = useState(true);
  const [quizTimer, setQuizTimer] = useState("");
  const [data, setData] = useState("");
  const [answer, setAnswer] = useState("");
  const [isResponseModal, setIsResponseModal] = useState(false);
  const [isResponseLoader, setIsResponseLoader] = useState(false);
  const [isResponseData, setIsResponseData] = useState("");
  const [finelScreen, setFinalScreen] = useState(false);
  const [counter, setCounter] = useState("1");
  const [totalCounter, setTotalCounter] = useState("");
  const [isRtl, setIsRtl] = useState(0);

  const joinQuiz = () => {
    sequenceNumber = 1;
    setQuizTimer("");
    localStorage.setItem("sequence", "1");
    localStorage.removeItem("quizRecordId");
    localStorage.removeItem("totalCount");
    localStorage.removeItem("nextSetId");
    localStorage.removeItem("quizSetId");
    localStorage.removeItem("quizIsRtl");
    localStorage.removeItem("end_date");
    const formData = new FormData();
    formData.append("student_id", getCurrentUser().id);
    formData.append("quiz_id", quizId);
    formData.append("quiz_class_id", quizClassId);
    axios
      .post(`${process.env.REACT_APP_API_URL}/student/join-quiz`, formData, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${getCurrentUser()?.token}`,
        },
      })
      .then(function (res) {
        if (res.data.success) {
          if (res.data.quiz_question.time) {
            setQuizTimer(60000 * res.data.quiz_question.time);
          }
          setQuizRecordId(res.data.data.id);
          localStorage.setItem("quizRecordId", res.data.data.id);
          setData(res.data.quiz_question);
          localStorage.setItem(
            "totalCount",
            res.data.quiz_question.total_questions_count
          );
          localStorage.setItem(
            "nextSetId",
            res.data.quiz_question.next_quiz_set_id
          );
          localStorage.setItem("quizSetId", res.data.quiz_question.quiz_set_id);
          localStorage.setItem("quizIsRtl", res.data.data.quiz.rtl_enabled);

          setSettings(res.data.settings);
          setCounter("1");
          setTotalCounter(res.data.quiz_question.total_questions_count);
        } else {
          setQuizTimer("");
          setFinalScreen(true);
        }
        setLoader(false);
      })
      .catch(function (err) {
        setCurrentUser("");
        history.push("/");
      });
  };

  const getFinalData = () => {
    sequenceNumber = 1;
    if (settings.show_final_score) {
      setQuizTimer("");
      setLoader(true);
      setFinalData("");
      const formData = new FormData();
      formData.append("quiz_record_id", localStorage.getItem("quizRecordId"));
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/student/submit-quiz`,
          formData,
          {
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${getCurrentUser()?.token}`,
            },
          }
        )
        .then(function (res) {
          if (res.data.success) {
            setFinalData(res.data.data);
          }
          setFinalScreen(true);
          setLoader(false);
        })
        .catch(function (err) {
          setCurrentUser("");
          history.push("/");
        });
    } else {
      setFinalScreen(true);
      setQuizTimer("");
      const formData = new FormData();
      formData.append("quiz_record_id", localStorage.getItem("quizRecordId"));
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/student/submit-quiz`,
          formData,
          {
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${getCurrentUser()?.token}`,
            },
          }
        )
        .then(function (res) {})
        .catch(function (err) {
          setCurrentUser("");
          history.push("/");
        });
    }
  };

  const nextSet = () => {
    if (
      localStorage.getItem("nextSetId") !== "null" &&
      localStorage.getItem("nextSetId") !== null &&
      localStorage.getItem("nextSetId") !== ""
    ) {
      sequenceNumber = 1;
      localStorage.setItem("sequence", sequenceNumber);
      getQuestion("1");
    } else {
      getFinalData();
      setQuizTimer("");
      setIsResponseModal(false);
      localStorage.removeItem("sequence", "");
      localStorage.removeItem("quizRecordId", "");
      localStorage.removeItem("totalCount", "");
      localStorage.removeItem("nextSetId", "");
      localStorage.removeItem("quizSetId", "");
    }
  };

  const checkGetQuestion = () => {
    let cCount = localStorage.getItem("sequence");
    let TCount = localStorage.getItem("totalCount");
    if (parseInt(cCount) > parseInt(TCount)) {
      if (
        localStorage.getItem("nextSetId") !== "null" &&
        localStorage.getItem("nextSetId") !== null &&
        localStorage.getItem("nextSetId") !== ""
      ) {
        sequenceNumber = 1;
        localStorage.setItem("sequence", sequenceNumber);
        getQuestion("1");
      } else {
        getFinalData();
        setQuizTimer("");
        setIsResponseModal(false);
        localStorage.removeItem("sequence", "");
        localStorage.removeItem("quizRecordId", "");
        localStorage.removeItem("totalCount", "");
        localStorage.removeItem("nextSetId", "");
        localStorage.removeItem("quizSetId", "");
      }
    } else {
      getQuestion();
    }
  };

  // const startNextSet = () => {
  //     if (localStorage.getItem("nextSetId")) {
  //         getQuestion("1");
  //     } else {
  //         getFinalData();
  //     }
  // };

  const getQuestion = (e) => {
    setIsResponseModal(false);
    setLoader(true);
    setData("");
    const formData = new FormData();
    formData.append("quiz_id", quizId);
    if (e) {
      setQuizTimer("");
      formData.append("quiz_set_id", localStorage.getItem("nextSetId"));
      formData.append("sequence", 1);
    } else {
      formData.append("quiz_set_id", localStorage.getItem("quizSetId"));
      formData.append("sequence", localStorage.getItem("sequence"));
    }
    formData.append("quiz_record_id", localStorage.getItem("quizRecordId"));
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/student/get-next-quiz-question`,
        formData,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser()?.token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.success) {
          if (res.data.data.quiz_set_id != localStorage.getItem("quizSetId")) {
            setQuizTimer("");
            localStorage.removeItem("end_date");
            if (res.data.data.time) {
              setQuizTimer(60000 * res.data.data.time);
            }
          }
          setCounter(res.data.data.question_counter);
          setData(res.data.data);
          if (res.data.data.next_quiz_set_id) {
            localStorage.setItem("nextSetId", res.data.data.next_quiz_set_id);
          } else {
            localStorage.setItem("nextSetId", "");
          }
          localStorage.setItem("quizSetId", res.data.data.quiz_set_id);
          setTotalCounter(res.data.data.total_questions_count);
          localStorage.setItem(
            "totalCount",
            res.data.data.total_questions_count
          );
          setLoader(false);
        } else {
          setQuizTimer("");
          getFinalData();
          localStorage.removeItem("sequence");
          localStorage.removeItem("quizRecordId");
          localStorage.removeItem("totalCount");
          localStorage.removeItem("nextSetId");
          localStorage.removeItem("quizSetId");
          setLoader(false);
        }
      })
      .catch(function (err) {
        setCurrentUser("");
        history.push("/");
      });
  };

  const submitAnswer = (e) => {
    sequenceNumber++;
    localStorage.setItem("sequence", sequenceNumber);

    setLoader(true);
    const formData = new FormData();
    formData.append("quiz_id", quizId);
    formData.append("quiz_record_id", localStorage.getItem("quizRecordId"));
    formData.append("quiz_question_id", data.id);
    formData.append("answer", answer);
    formData.append("quiz_class_id", quizClassId);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/student/submit-quiz-answer`,
        formData,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser()?.token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.question.is_exit_ticket) {
          checkGetQuestion();
        } else {
          if (settings?.show_answers) {
            setIsResponseData(res.data);
            setIsResponseModal(true);
          } else {
            checkGetQuestion();
          }
          setAnswer("");
          // setLoader(false);
        }
      })
      .catch(function (err) {
        setCurrentUser("");
        history.push("/");
      });
  };

  useEffect(() => {
    if (localStorage.getItem("sequence")) {
      sequenceNumber = localStorage.getItem("sequence");
      if (
        localStorage.getItem("sequence") > localStorage.getItem("totalCount")
      ) {
        if (localStorage.getItem("nextSetId") == null) {
          setIsResponseModal(false);
          getFinalData();
        } else {
          sequenceNumber = 1;
          localStorage.setItem("sequence", sequenceNumber);
          getQuestion("1");
        }
      } else {
        getQuestion();
      }
    } else {
      localStorage.removeItem("sequence");
      localStorage.removeItem("quizRecordId");
      localStorage.removeItem("totalCount");
      localStorage.removeItem("nextSetId");
      localStorage.removeItem("quizSetId");
      joinQuiz();
    }

    const socket = io(process.env.REACT_APP_SOCKET_URL);
    socket.on(getCurrentUser().id, (type, data) => {
      if (type === "remove_student") {
        if (data) {
          history.push("/real-time");
        }
      }
    });
  }, []);

  const updateQuizRtl = (e) => {
    setIsRtl(e ? 1 : 0);
    const formData = new FormData();
    formData.append("student_id", getCurrentUser().id);
    formData.append("quiz_id", quizId);
    formData.append("quiz_class_id", quizClassId);
    formData.append("student_rtl_enabled", e ? 1 : 0);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/student/update-quiz-record`,
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {});
  };

  return (
    <Fragment>
      <Helmet>
        <title>Real Time Test</title>
        {/* <meta name="description" content="Homepage" /> */}
      </Helmet>
      <div className="realtime">
        <div className="worksheet_topheader">
          <div className="logo_head p-0">
            <img src={Logo} alt="" width="125px" />
          </div>
        </div>
        <SplitPane>
          <div>
            {quizTimer ? (
              <div
                className="realtime_timer text-center py-2"
                style={{
                  background: "#4c69af",
                  borderTop: "solid 1px #a6bdf5b5",
                }}
              >
                <TestCounter data={quizTimer} submissionFile={nextSet} />
              </div>
            ) : (
              ""
            )}
            <div className="container my-4">
              {finelScreen ? (
                finalData ? (
                  <div
                    className="pt-5 text-center"
                    style={{
                      maxWidth: "500px",
                      margin: "0 auto",
                    }}
                  >
                    <h3 className="mb-4">Finished!</h3>
                    <h6 className="text-end">
                      Total Points: {finalData?.total_points}
                    </h6>
                    <table className="table table-bordered">
                      <tbody>
                        <tr>
                          <th>Score</th>
                          <td>{finalData?.score}</td>
                        </tr>
                        <tr>
                          <th>Short Answer Questions</th>
                          <td>Pending</td>
                        </tr>
                        <tr>
                          <th>Percent</th>
                          <td>{finalData?.percentage}</td>
                        </tr>
                      </tbody>
                    </table>
                    <Link
                      to="/real-time"
                      className="btn button_submission px-5 text-white"
                    >
                      Back to Home
                    </Link>
                  </div>
                ) : (
                  <div className="text-center pt-5 mt-5">
                    <h4>You‘ve completed the current activity.</h4>
                    <Link
                      to="/real-time"
                      className="btn button_submission w-25 text-white"
                    >
                      Back to Home
                    </Link>
                  </div>
                )
              ) : (
                <>
                  {loader ? (
                    <Loaders />
                  ) : (
                    <>
                      <div className="quiz_question">
                        <div className="d-flex gap-5 align-items-center">
                          <h2
                            className="m-0"
                            style={{
                              color: "#4d6cb4",
                            }}
                          >
                            {counter}/{totalCounter}
                          </h2>
                          <div className="d-flex gap-1 align-items-center">
                            <label className="m-0">RTL</label>
                            <Switch
                              defaultChecked={isRtl ? true : false}
                              onChange={(e) => updateQuizRtl(e)}
                            />
                          </div>
                        </div>
                        <RealTimeQuestion
                          question={data}
                          recordId={quizRecordId}
                          isRtl={isRtl}
                          quizId={quizId}
                          quizClassId={quizClassId}
                          setAnswer={setAnswer}
                        />
                      </div>
                      <div className="mt-4">
                        {finelScreen ? (
                          ""
                        ) : answer ? (
                          <button
                            className="btn btn-primary"
                            onClick={() => submitAnswer()}
                          >
                            Submit
                          </button>
                        ) : (
                          <button className="btn btn-primary disabled" disabled>
                            Submit
                          </button>
                        )}
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </SplitPane>
        <div className="worksheet_footer">
          <div className="row align-items-center w-100">
            <div className="col"></div>
            <div className="col timer text-center"></div>
            <div className="col text-end submit_test"></div>
          </div>
        </div>
      </div>
      {isResponseModal ? (
        <Modal
          isOpen="true"
          contentLabel="Example Modal"
          ariaHideApp={false}
          style={customStyles}
        >
          {isResponseLoader ? (
            <Loaders />
          ) : (
            <div className="modal-body">
              {isResponseData.question?.question_type === "short_answer" ? (
                ""
              ) : isResponseData.is_correct ? (
                <h5>
                  <i
                    className="text-success"
                    style={{
                      fontSize: "33px",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    <MdCheck />
                  </i>
                  Correct
                </h5>
              ) : (
                <h5>
                  <i
                    className="text-danger"
                    style={{
                      fontSize: "33px",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    <MdClose />
                  </i>
                  Incorrect
                </h5>
              )}
              <div className="">
                <p className="mb-0">
                  <b>Question:</b>
                </p>
                <p>{isResponseData.question?.data}</p>
                {isResponseData.question?.question_type === "short_answer" ? (
                  <h5>Marks will be added by the teacher later on</h5>
                ) : (
                  <>
                    <p className="mt-3 mb-0">
                      <b>Correct Answer:</b>
                    </p>
                    {isResponseData.question?.question_type === "true_false" ||
                    isResponseData.question?.question_type === "yes_no" ? (
                      <p>
                        {isResponseData.question.correct_answers === "yes"
                          ? "Yes"
                          : isResponseData.question.correct_answers === "no"
                          ? "No"
                          : isResponseData.question.correct_answers ===
                            "not_given"
                          ? "Not Given"
                          : isResponseData.question.correct_answers === "true"
                          ? "True"
                          : isResponseData.question.correct_answers === "false"
                          ? "False"
                          : ""}
                      </p>
                    ) : (
                      <ul className="ms-3">
                        {isResponseData.question?.sub_questions?.map((item) =>
                          item.correct_answers ? (
                            <li className="w-100">{item.data}</li>
                          ) : (
                            ""
                          )
                        )}
                      </ul>
                    )}
                    {isResponseData.question?.explanation.data ||
                    isResponseData.question?.explanation.attachment ? (
                      <div className="mt-3">
                        <p className="mb-0">
                          <b>Explanation:</b>
                        </p>
                        {isResponseData.question?.explanation.data ? (
                          <p>{isResponseData.question.explanation.data}</p>
                        ) : (
                          ""
                        )}
                        {isResponseData.question?.explanation.attachment ? (
                          <img
                            src={isResponseData.question.explanation.attachment}
                            alt="s"
                            className="mw-100 w-auto"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div>
              <button
                onClick={() => checkGetQuestion()}
                className="btn btn-primary mt-3"
              >
                OK
              </button>
            </div>
          )}
        </Modal>
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default RealTimeTest;
