import React from "react";
import { Link, useHistory } from "react-router-dom";
import { getCurrentUser, setCurrentUser } from "../../../helpers/Utils";

const MobileNavMenu = ({ styles }) => {
  let history = useHistory();

  const studentLogOut = () => {
    setCurrentUser(null);
    history.push("/");
  };
  return (
    <nav className={styles.offcanvasNavigation} id="offcanvas-navigation">
      <ul className="list-inline">
        <li>
          <Link to={process.env.PUBLIC_URL + "/join"}>Enter Code</Link>
        </li>
        {getCurrentUser() ? (
          <>
            <li>
              <Link
                to={process.env.PUBLIC_URL + `/student/${getCurrentUser().id}`}
              >
                Gradebook
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + `/real-time`}>Real-Time</Link>
            </li>
            <li>
              <Link to="" onClick={() => studentLogOut()}>
                Logout
              </Link>
            </li>
          </>
        ) : (
          <li>
            <Link to={process.env.PUBLIC_URL + "/login"}>Login</Link>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default MobileNavMenu;
