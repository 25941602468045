import React, { useState } from "react";
import Modal from "react-modal";
import ConsentFormFile from "../../assets/files/punditin-consent-form.pdf";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    padding: "0",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "545px",
    width: "100%",
  },
};

const ConsentForm = (props) => {
  const [loader, setLoader] = useState("");

  return (
    <Modal
      isOpen="true"
      contentLabel="Example Modal"
      ariaHideApp={false}
      style={customStyles}
    >
      {loader !== "" ? (
        "Loading"
      ) : (
        <>
          <div className="modal-header">
            <h2 className="text-capitalize m-0">Consent Update</h2>
            <button
              onClick={() => props.setIsConsentForm(false)}
              className="close"
            >
              <span
                style={{
                  fontSize: "28px",
                }}
                aria-hidden="true"
              >
                ×
              </span>
            </button>
          </div>
          <div className="modal-body">
            <p>
              Please download the Parental consent form and email it to both:
            </p>
            <h6>
              <a href={`mailto:${props.teacherEmail}`}>{props.teacherEmail}</a>,{" "}
              <a href="mailto:parentalconsent@punditin.com">
                parentalconsent@punditin.com
              </a>
            </h6>
            <div className="text-center">
              <a
                className="btn btn-primary border-0"
                href={ConsentFormFile}
                download
                style={{
                  backgroundColor: "rgb(248, 129, 44)",
                  color: "white",
                }}
              >
                Download!
              </a>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};

export default ConsentForm;
