import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/Punditlogo.png";

const Logo = () => {
    return (
        <div className="logo">
            <Link to={process.env.PUBLIC_URL + "/"}>
                <img
                    style={{
                        maxWidth: "235px",
                    }}
                    src={logo}
                    alt="Logo"
                />
            </Link>
        </div>
    );
};

export default Logo;
