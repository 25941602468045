import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import ScrollToTop from "./helpers/ScrollToTop";
import Home from "./pages/Home";
import About from "./pages/About";
import Blog from "./pages/Blog";
import BlogPost from "./pages/BlogPost";
import Contact from "./pages/Contact";
import SchoolForm from "./pages/SchoolFormDistrict";
import NotFound from "./pages/NotFound";
import Join from "./pages/Join";
import JoinLogin from "./pages/Join-login";
import Pricing from "./pages/Pricing";
import Privacy from "./pages/Privacy";
import TermCondition from "./pages/termCondition";
import CookiePolicy from "./pages/CookiePolicy";
import CancellationRefundPolicy from "./pages/CancellationRefundPolicy";
import SignUp from "./pages/Signup";
import RegisterForm from "./pages/Register";
import LoginForm from "./pages/Login";
import Feature from "./pages/Feature";
import SignIn from "./pages/Signin";
import FreeSignUp from "./pages/FreeSignup";
import StudentWorksheet from "./pages/Student-worksheet";
import ResultShow from "./pages/result";
import ForgetPassword from "./pages/forget-password";
import SplitScreenTest from "./pages/split-screen-test";
import FaqPage from "./pages/Faq";
import LoginForStudent from "./pages/StudentLogin";
import LoginForParent from "./pages/ParentLogin";
import StudentData from "./pages/StudentData";
import StudentTestData from "./pages/TestData";
import UnderMaintenancePage from "./pages/UnderMaintenance";
import Loaders from "./components/loader";
import axios from "axios";
import Gdrive from "./pages/Gdrive";
import RealTime from "./pages/RealTime";
import RealTimeTest from "./pages/RealTimeTest";
import TeacherLoginForm from "./pages/TeacherLogin";
import Courses from "./pages/courses";
import MyCourses from "./pages/my-courses";
import SingleCourse from "./pages/single-course";
import AdminViewSingleCourse from "./pages/admin-view-course";
import { getCurrentUser } from "./helpers/Utils";
import { useHistory } from "react-router-dom";

function App() {
  const [loading, setLoading] = useState("");
  let history = useHistory();

  const maintenanceMode = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/maintenance-mode`)
      .then(function (res) {
        if (
          res.data.success &&
          window.location.pathname !== "/under-maintenance"
        ) {
          window.location.href = "/under-maintenance";
        } else if (
          res.data.success === false &&
          window.location.pathname === "/under-maintenance"
        ) {
          window.location.href = "/";
        }
        setLoading("");
      });
  };

  // Disable Right Click
  //   window.addEventListener(
  //     "contextmenu",
  //     function (e) {
  //       e.preventDefault();
  //     },
  //     false
  //   );

  // Disable F12 & PrintScr Key
  document.onkeydown = function (e) {
    if (e.keyCode === 123 || e.keyCode === 44) {
      return false;
    }
  };

  useEffect(() => {
    maintenanceMode();
  }, []);

  if (loading === "") {
    return (
      <Router>
        <ScrollToTop>
          <Switch>
            <Route exact path={"/"} component={Join} />
            <Route exact path={"/login"} component={LoginForm} />
            <Route exact path={"/join"} component={Join} />
            <Route exact path={"/join-login"} component={JoinLogin} />
            <Route path={"/gdrive"} component={Gdrive} />
            <Route path={"/test/:id"} component={SplitScreenTest} />
            <Route path={"/result/:id"} component={ResultShow} />
            <Route path={"/student/:id"} component={StudentData} />
            <Route path={"/real-time-test"} component={RealTimeTest} />
            <Route path={"/student-login"} component={LoginForStudent} />
            <Route path={"/submitted-test/:id"} component={StudentTestData} />
            <Route path={"/real-time"} component={RealTime} />
            <Route exact path={"/parent-login"} component={LoginForParent} />
            <Route exact path={"/teacher-login"} component={TeacherLoginForm} />
            <Route exact path={"/courses"} component={Courses} />
            <Route exact path={"/my-courses"} component={MyCourses} />
            <Route exact path={"/course/:id"} component={SingleCourse} />
            <Route
              exact
              path={"/admin-view-course/:id"}
              component={AdminViewSingleCourse}
            />
            <Route path={"/not-found"} component={NotFound} />
            <Route exact component={NotFound} />
            <Route
              exact
              path={"/under-maintenance"}
              component={UnderMaintenancePage}
            />
          </Switch>
        </ScrollToTop>
      </Router>
    );
  } else {
    return (
      <div className="">
        <Loaders />
      </div>
    );
  }
}

export default App;
