import React, { useState, Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
} from "reactstrap";

import { Link, useHistory } from "react-router-dom";
import Breadcrumb from "../components/UI/breadcrumb";
import Layout from "../layouts";
import { useParams } from "react-router-dom";
import axios from "axios";
import Loaders from "../components/loader";
import { getCurrentUser, setCurrentUser } from "../helpers/Utils";
import { Helmet } from "react-helmet";
import Modal from "react-modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import StudentProgress from "../components/gradebook/student-progress";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    padding: "0",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "545px",
    width: "100%",
  },
};
const StudentData = () => {
  const { id } = useParams();
  const [gradebook, setGradebook] = useState("");
  const [loader, setLoader] = useState(<Loaders />);
  const [attendance, setAttendance] = useState([]);
  const [feedbackModal, setFeedbackModal] = useState(false);
  const [selectedGradebook, setSelectedGradebook] = useState("");
  const [attendanceloader, setAttendanceloaderLoader] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [teachersLoader, setTeachersLoader] = useState(true);
  const [selectedTeacher, setSelectedTeacher] = useState("");
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  let history = useHistory();

  const resultData = (tId) => {
    setLoader(<Loaders />);
    let url = `${
      process.env.REACT_APP_API_URL
    }/student/get-my-gradebook/${id}?timeStamp=${Date.now()}`;
    if (tId) {
      url = `${
        process.env.REACT_APP_API_URL
      }/student/get-my-gradebook/${tId}?subject=&timeStamp=${Date.now()}`;
    }
    axios
      .get(url, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${getCurrentUser()?.token}`,
        },
      })
      .then(function (res) {
        if (res.data.success) {
          setGradebook(res.data.data);
        }
        setLoader("");
      })
      .catch(function (err) {
        setCurrentUser("");
        history.push("/");
      });
  };

  const submitFeedback = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("type", "test_record");
    formData.append("feedback", e.target.feedback.value);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/student/set-feedback/${selectedGradebook.id}`,
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${getCurrentUser()?.token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.success) {
          toast.success(res.data.message, {
            position: "top-right",
            autoClose: true,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          resultData();
          setFeedbackModal(false);
        } else {
          toast.error(res.data.message, {
            position: "top-right",
            autoClose: true,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  const formatDate = (date) => {
    let d = new Date(date);
    let month = (d.getMonth() + 1).toString();
    let day = d.getDate().toString();
    let year = d.getFullYear();
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    return [year, month, day].join("-");
  };

  const getAttendance = () => {
    setAttendanceloaderLoader(true);
    setAttendance([]);
    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/student/my-attendance?from_date=${formatDate(
          selectionRange[0].startDate
        )}&to_date=${formatDate(selectionRange[0].endDate)}`,
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${getCurrentUser()?.token}`,
          },
        }
      )
      .then(function (res) {
        setAttendanceloaderLoader(false);
        if (res.data.success) {
          setAttendance(res.data.data);
        }
      });
  };

  const getAllTeachers = async () => {
    try {
      setTeachersLoader(true);
      const teachersResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/student/parent-students`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser()?.token}`,
          },
        }
      );
      if (teachersResponse.data.success) {
        setTeachers(teachersResponse.data.data);
      }
      const subjectsResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/get-subjects`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser()?.token}`,
          },
        }
      );
      if (subjectsResponse.data.success) {
        setSubjects(subjectsResponse.data.data);
      }
    } catch (error) {
      console.error("Error fetching data", error);
    } finally {
      setTeachersLoader(false);
    }
  };

  useEffect(() => {
    resultData();
    getAttendance();
    if (getCurrentUser()?.is_parent_login) {
      getAllTeachers();
    }
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>{gradebook && gradebook.student.name}</title>
      </Helmet>
      <Layout>
        <Breadcrumb title="Pricing my-5" />
        <div
          className="mygradebook pt--60 pb--60"
          style={{
            minHeight: "450px",
          }}
        >
          <div className="container border-bottom pb-3 mb-3">
            <div className="row">
              <div className="col-sm-6">
                <h2 className="orange_heading m-0 p-0">
                  {gradebook && gradebook.student.name}
                </h2>
              </div>
              <div className="col-sm-6 d-flex justify-content-end align-items-center m-0">
                {getCurrentUser()?.is_parent_login ? (
                  teachersLoader ? (
                    <Spinner />
                  ) : (
                    <select className="form-control w-auto">
                      <option value="">Teacher (Student)</option>
                      {teachers.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.teacher_name} ({item.name})
                        </option>
                      ))}
                    </select>
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="container">
            <StudentProgress />
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h4 className="m-0">Attendance</h4>
              <div>
                <UncontrolledDropdown className="dropdown-menu-right mr-2">
                  <DropdownToggle className="btn btn-outline-primary btn-sm bg-transparent text-dark">
                    Choose date
                  </DropdownToggle>
                  <DropdownMenu className="navar_dropDown">
                    <DateRangePicker
                      ranges={selectionRange}
                      onChange={(e) => setSelectionRange([e.selection])}
                      editableDateInputs={true}
                      moveRangeOnFirstSelection={false}
                    />
                    <DropdownItem
                      className="btn btn-sm dropdown-item text-center m-auto d-block text-light rounded"
                      style={{
                        width: "100px",
                        backgroundColor: "#4D6CB4",
                        color: "white",
                      }}
                      onClick={() => getAttendance()}
                    >
                      Submit
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
            <div className="table-responsive">
              {attendanceloader ? (
                <Loaders />
              ) : (
                <table className="table table-bordered border border-dark">
                  <thead>
                    <tr>
                      <th
                        style={{
                          whiteSpace: "nowrap",
                          paddingRight: "15px",
                        }}
                      >
                        Present
                      </th>
                      <th
                        style={{
                          whiteSpace: "nowrap",
                          paddingRight: "15px",
                        }}
                      >
                        Absent
                      </th>
                      <th
                        style={{
                          whiteSpace: "nowrap",
                          paddingRight: "15px",
                        }}
                      >
                        Late
                      </th>
                      <th
                        style={{
                          whiteSpace: "nowrap",
                          paddingRight: "15px",
                        }}
                      >
                        Excused
                      </th>
                      <th
                        style={{
                          whiteSpace: "nowrap",
                          paddingRight: "15px",
                        }}
                      >
                        Not Excused
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-capitalize">{attendance?.present}</td>
                      <td className="text-capitalize">{attendance?.absent}</td>
                      <td className="text-capitalize">{attendance?.late}</td>
                      <td className="text-capitalize">{attendance?.excused}</td>
                      <td className="text-capitalize">
                        {attendance?.not_excused}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
            {loader ? (
              loader
            ) : (
              <>
                <h4>Assigned Tests</h4>
                {gradebook.assigned_worksheets === null ? (
                  <span className="text-danger mb-5 d-block">
                    There are no pending assignments.
                  </span>
                ) : (
                  ""
                )}
                {gradebook.assigned_worksheets &&
                gradebook.assigned_worksheets.length > 0 ? (
                  <div className="table-responsive">
                    <table className="table table-bordered border border-dark">
                      <thead>
                        <tr>
                          <th
                            style={{
                              whiteSpace: "nowrap",
                              paddingRight: "15px",
                            }}
                          >
                            Worksheet name
                          </th>
                          <th
                            style={{
                              whiteSpace: "nowrap",
                              paddingRight: "15px",
                            }}
                          >
                            Assigned Date
                          </th>
                          <th
                            style={{
                              whiteSpace: "nowrap",
                              paddingRight: "15px",
                            }}
                          >
                            End date
                          </th>
                          <th
                            style={{
                              whiteSpace: "nowrap",
                              paddingRight: "15px",
                            }}
                          >
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {gradebook.assigned_worksheets &&
                          gradebook.assigned_worksheets.map((item, i) => (
                            <tr key={i}>
                              <td className="text-capitalize">{item.title}</td>
                              <td>{item.formatted_assignment_start_date}</td>
                              <td>{item.deadline_date_time}</td>
                              <td>
                                {item.is_overdue === 1 ? (
                                  <p className="text-danger m-0">Expired</p>
                                ) : (
                                  <p className="m-0">Incomplete</p>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <span className="text-danger">No Data Found</span>
                )}
                <h4 className="mt-5">
                  Gradebook{" "}
                  {getCurrentUser()?.is_parent_login &&
                  getCurrentUser()?.is_parent_login ? (
                    ""
                  ) : (
                    <small
                      style={{
                        fontWeight: "400",
                        fontSize: "15px",
                      }}
                    >
                      (Click on the worksheet name to view the submitted &
                      correct answers)
                    </small>
                  )}
                </h4>
                {gradebook.data && gradebook.data.length > 0 ? (
                  <div className="table-responsive">
                    <table className="table table-bordered border border-dark">
                      <thead>
                        <tr>
                          <th
                            style={{
                              whiteSpace: "nowrap",
                              paddingRight: "15px",
                            }}
                          >
                            Worksheet name
                          </th>
                          <th
                            style={{
                              whiteSpace: "nowrap",
                              paddingRight: "15px",
                            }}
                          >
                            Marks
                          </th>
                          <th
                            style={{
                              whiteSpace: "nowrap",
                              paddingRight: "15px",
                            }}
                          >
                            Percentage
                          </th>
                          <th
                            style={{
                              whiteSpace: "nowrap",
                              paddingRight: "15px",
                            }}
                          >
                            Type
                          </th>
                          <th
                            style={{
                              whiteSpace: "nowrap",
                              paddingRight: "15px",
                            }}
                          >
                            Status
                          </th>
                          <th
                            style={{
                              whiteSpace: "nowrap",
                              paddingRight: "15px",
                            }}
                          >
                            Feedback from Teacher
                          </th>
                          <th
                            style={{
                              whiteSpace: "nowrap",
                              paddingRight: "15px",
                            }}
                          >
                            Your feedback
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {gradebook.data &&
                          gradebook.data?.map((item, i) => (
                            <tr key={i}>
                              <td className="text-capitalize">
                                {getCurrentUser()?.is_parent_login ? (
                                  item.worksheet?.title
                                ) : (item.is_ongoing == 0) &
                                  (item.status == null) ? (
                                  <Link
                                    className="text-dark"
                                    to={`/submitted-test/${item.worksheet_id}?record_id=${item.id}`}
                                  >
                                    <u>{item.worksheet?.title}</u>
                                  </Link>
                                ) : (
                                  item.worksheet?.title
                                )}
                              </td>
                              <td>
                                {item.worksheet.show_scores ? (
                                  item.is_ongoing === 1 ? (
                                    "Ongoing"
                                  ) : item.score !== null ? (
                                    <div>
                                      {item.score} / {item.total_score}
                                    </div>
                                  ) : (
                                    "Pending"
                                  )
                                ) : (
                                  ""
                                )}
                              </td>
                              <td>
                                {item.worksheet.show_scores
                                  ? item.formatted_score
                                  : ""}
                              </td>
                              <td className="text-capitalize">{item?.type}</td>
                              <td className="text-capitalize">
                                {item.is_ongoing === 1
                                  ? "Ongoing"
                                  : item.status == null
                                  ? "Completed"
                                  : item.status}
                              </td>
                              <td
                                className="text-capitalize"
                                style={{
                                  maxWidth: "200px",
                                }}
                              >
                                {item.remarks ? item.remarks : "--"}
                              </td>
                              <td>
                                <p className="d-block">{item.feedback}</p>
                                <button
                                  onClick={() => {
                                    setSelectedGradebook(item);
                                    setFeedbackModal(true);
                                  }}
                                  className="btn btn-success btn-sm d-block"
                                >
                                  {item.feedback ? "Edit" : "Add"}
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <span className="text-danger">No Data Found</span>
                )}

                <h4 className="mt-5">Real-Time Quiz Gradebook</h4>
                {gradebook.quizzes && gradebook.quizzes.length > 0 ? (
                  <div className="table-responsive">
                    <table className="table table-bordered border border-dark">
                      <thead>
                        <tr>
                          <th
                            style={{
                              whiteSpace: "nowrap",
                              paddingRight: "15px",
                            }}
                          >
                            Quiz Name
                          </th>
                          <th
                            style={{
                              whiteSpace: "nowrap",
                              paddingRight: "15px",
                            }}
                          >
                            Score
                          </th>
                          <th
                            style={{
                              whiteSpace: "nowrap",
                              paddingRight: "15px",
                            }}
                          >
                            Completed
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {gradebook.quizzes?.map((item) => (
                          <tr>
                            <td>{item.quiz.name}</td>
                            <td>
                              {item.show_scores ? (
                                <>
                                  {item.score}/{item.total_points}{" "}
                                </>
                              ) : (
                                ""
                              )}
                            </td>
                            <td>{item.formatted_submitted_date}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <span className="text-danger">No Data Found</span>
                )}
                <h4 className="mt-5">
                  Exams{" "}
                  {getCurrentUser()?.is_parent_login &&
                  getCurrentUser()?.is_parent_login ? (
                    ""
                  ) : (
                    <small
                      style={{
                        fontWeight: "400",
                        fontSize: "15px",
                      }}
                    >
                      (Click on the exam name to view the submitted & correct
                      answers)
                    </small>
                  )}
                </h4>
                {gradebook.exams && gradebook.exams.length > 0 ? (
                  <div className="table-responsive">
                    <table className="table table-bordered border border-dark">
                      <thead>
                        <tr>
                          <th
                            style={{
                              whiteSpace: "nowrap",
                              paddingRight: "15px",
                            }}
                          >
                            Exam name
                          </th>
                          <th
                            style={{
                              whiteSpace: "nowrap",
                              paddingRight: "15px",
                            }}
                          >
                            Marks
                          </th>
                          <th
                            style={{
                              whiteSpace: "nowrap",
                              paddingRight: "15px",
                            }}
                          >
                            Percentage
                          </th>
                          <th
                            style={{
                              whiteSpace: "nowrap",
                              paddingRight: "15px",
                            }}
                          >
                            Type
                          </th>
                          <th
                            style={{
                              whiteSpace: "nowrap",
                              paddingRight: "15px",
                            }}
                          >
                            Status
                          </th>
                          <th
                            style={{
                              whiteSpace: "nowrap",
                              paddingRight: "15px",
                            }}
                          >
                            Feedback
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {gradebook.exams &&
                          gradebook.exams?.map((item, i) => (
                            <tr key={i}>
                              <td className="text-capitalize">
                                {getCurrentUser()?.is_parent_login ? (
                                  item.worksheet?.title
                                ) : (item.is_ongoing == 0) &
                                  (item.status == null) ? (
                                  <Link
                                    className="text-dark"
                                    to={`/submitted-test/${item.worksheet_id}?record_id=${item.id}`}
                                  >
                                    <u>{item.worksheet?.title}</u>
                                  </Link>
                                ) : (
                                  item.worksheet?.title
                                )}
                              </td>
                              <td>
                                {item.worksheet.show_scores ? (
                                  item.is_ongoing === 1 ? (
                                    "Ongoing"
                                  ) : item.score !== null ? (
                                    <div>
                                      {item.score} / {item.total_score}
                                    </div>
                                  ) : (
                                    "Pending"
                                  )
                                ) : (
                                  ""
                                )}
                              </td>
                              <td>
                                {item.worksheet.show_scores
                                  ? item.formatted_score
                                  : ""}
                              </td>
                              <td className="text-capitalize">{item?.type}</td>
                              <td className="text-capitalize">
                                {item.is_ongoing === 1
                                  ? "Ongoing"
                                  : item.status == null
                                  ? "Completed"
                                  : item.status}
                              </td>
                              <td
                                className="text-capitalize"
                                style={{
                                  maxWidth: "200px",
                                }}
                              >
                                {item.remarks ? item.remarks : "--"}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <span className="text-danger">No Data Found</span>
                )}
                <h4 className="mt-5">Course Quiz Gradebook</h4>
                {gradebook.course_worksheets &&
                gradebook.course_worksheets.length > 0 ? (
                  <div className="table-responsive">
                    <table className="table table-bordered border border-dark">
                      <thead>
                        <tr>
                          <th
                            style={{
                              whiteSpace: "nowrap",
                              paddingRight: "15px",
                            }}
                          >
                            Worksheet name
                          </th>
                          <th
                            style={{
                              whiteSpace: "nowrap",
                              paddingRight: "15px",
                            }}
                          >
                            Marks
                          </th>
                          <th
                            style={{
                              whiteSpace: "nowrap",
                              paddingRight: "15px",
                            }}
                          >
                            Percentage
                          </th>
                          <th
                            style={{
                              whiteSpace: "nowrap",
                              paddingRight: "15px",
                            }}
                          >
                            Type
                          </th>
                          <th
                            style={{
                              whiteSpace: "nowrap",
                              paddingRight: "15px",
                            }}
                          >
                            Status
                          </th>
                          <th
                            style={{
                              whiteSpace: "nowrap",
                              paddingRight: "15px",
                            }}
                          >
                            Feedback from teacher
                          </th>
                          <th
                            style={{
                              whiteSpace: "nowrap",
                              paddingRight: "15px",
                            }}
                          >
                            Your feedback
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {gradebook.course_worksheets &&
                          gradebook.course_worksheets?.map((item, i) => (
                            <tr key={i}>
                              <td className="text-capitalize">
                                {getCurrentUser()?.is_parent_login ||
                                item.is_ongoing == 1 ? (
                                  item.worksheet?.title
                                ) : (
                                  <Link
                                    className="text-dark"
                                    href={`/submitted-test/${item.worksheet_id}?record_id=${item.id}`}
                                  >
                                    <u>{item.worksheet?.title}</u>
                                  </Link>
                                )}
                              </td>
                              <td>
                                {item.worksheet.show_scores ? (
                                  item.is_ongoing === 1 ? (
                                    "Ongoing"
                                  ) : item.score !== null ? (
                                    <div>
                                      {item.score} / {item.total_score}
                                    </div>
                                  ) : (
                                    "Pending"
                                  )
                                ) : (
                                  ""
                                )}
                              </td>
                              <td>
                                {item.worksheet.show_scores
                                  ? item.formatted_score
                                  : ""}
                              </td>
                              <td className="text-capitalize">{item?.type}</td>
                              <td className="text-capitalize">
                                {item.is_ongoing === 1
                                  ? "Ongoing"
                                  : item.status == null
                                  ? "Completed"
                                  : item.status}
                              </td>
                              <td
                                className="text-capitalize"
                                style={{
                                  maxWidth: "200px",
                                }}
                              >
                                {item.remarks ? item.remarks : "--"}
                              </td>
                              <td>
                                <p className="d-block">{item.feedback}</p>
                                <button
                                  onClick={() => {
                                    setSelectedGradebook(item);
                                    setFeedbackModal(true);
                                  }}
                                  className="btn btn-success btn-sm d-block"
                                >
                                  {item.feedback ? "Edit" : "Add"}
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <span className="text-danger">No Data Found</span>
                )}
              </>
            )}
          </div>
          <Modal
            isOpen={feedbackModal}
            contentLabel="Example Modal"
            ariaHideApp={false}
            style={customStyles}
          >
            <form onSubmit={(e) => submitFeedback(e)}>
              <div className="modal-header border-bottom p-3">
                <h2 className="text-capitalize m-0">
                  {selectedGradebook.worksheet?.title}
                </h2>
                <button
                  onClick={() => setFeedbackModal(false)}
                  className="close"
                >
                  <span
                    style={{
                      fontSize: "28px",
                    }}
                    aria-hidden="true"
                  >
                    ×
                  </span>
                </button>
              </div>
              <div className="modal-body p-3">
                <div className="form-group">
                  <label className="mb-2">Feedback</label>
                  <textarea
                    name="feedback"
                    className="form-control"
                    style={{
                      minHeight: "200px",
                      fontSize: "15px",
                    }}
                    dir={selectedGradebook.student_rtl_enabled ? "rtl" : ""}
                    defaultValue={selectedGradebook.feedback}
                  ></textarea>
                </div>
              </div>
              <div className="modal-footer border-top p-3">
                <button className="btn btn-primary">Submit</button>
              </div>
            </form>
          </Modal>
        </div>
      </Layout>
    </Fragment>
  );
};

export default StudentData;
