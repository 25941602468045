import React, { useState, useRef, useEffect } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  Spinner,
  DropdownMenu,
} from "reactstrap";
import axios from "axios";
import Line from "./Line";
import { getCurrentUser, setCurrentUser } from "../../helpers/Utils";

import { DateRange } from "react-date-range";
import { format, subMonths } from "date-fns";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

const StudentProgress = () => {
  const [loader, setLoader] = useState(true);
  const [chartData, setChartData] = useState([]);
  const [state, setState] = useState([
    {
      startDate: subMonths(new Date(), 1),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDates, setSelectedDates] = useState(
    `${format(subMonths(new Date(), 1), "MM/dd/yyyy")} - ${format(
      new Date(),
      "MM/dd/yyyy"
    )}`
  );
  const datePickerRef = useRef(null);

  const handleDateChange = (item) => {
    setState([item.selection]);
  };

  const handleInputClick = () => {
    setShowCalendar(!showCalendar);
  };

  const handleClickOutside = (event) => {
    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target)
    ) {
      setShowCalendar(false);
    }
  };

  const handleClear = () => {
    setState([{ startDate: null, endDate: null, key: "selection" }]);
    setSelectedDates("");
    setShowCalendar(false);
  };
  const handleSubmit = () => {
    const formattedStartDate = state[0].startDate
      ? format(state[0].startDate, "yyyy-MM-dd")
      : "";
    const formattedEndDate = state[0].endDate
      ? format(state[0].endDate, "yyyy-MM-dd")
      : "";
    setSelectedDates(`${formattedStartDate} - ${formattedEndDate}`);
    setShowCalendar(false);
    setLoader(true);
    setChartData([]);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/student/progress-report-st/${
          getCurrentUser().id
        }?start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.success) {
          setChartData(res.data.data);
        }
        setLoader(false);
      });
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    handleSubmit();
  }, []);

  return (
    <Card className="my-4 p-4 border">
      <CardBody>
        <div className="d-flex mb-3 justify-content-between">
          <h4 className="m-0">Student Progress</h4>
          <div className="d-flex gap-5">
            <div className="">
              <div ref={datePickerRef} className="date-picker">
                <input
                  className="form-control"
                  type="text"
                  value={selectedDates}
                  onClick={handleInputClick}
                  readOnly
                  placeholder="Select date range"
                  style={{
                    minWidth: "220px",
                  }}
                />
                {showCalendar && (
                  <div className="calendarElement">
                    <DateRange
                      editableDateInputs={true}
                      onChange={handleDateChange}
                      moveRangeOnFirstSelection={false}
                      ranges={state}
                    />
                    <div className="calendar-buttons d-flex align-items-center justify-content-end pr-2 py-2 gap-2 border-top bg-white">
                      <button
                        className="btn btn-primary"
                        onClick={() => handleSubmit("")}
                      >
                        Submit
                      </button>
                      <button
                        className="btn btn-outline-primary"
                        onClick={handleClear}
                      >
                        Clear
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="dashboard-line-chart">
          {loader ? (
            <div className="text-center pt-5">
              <Spinner />
            </div>
          ) : (
            <Line data={chartData} />
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default StudentProgress;
