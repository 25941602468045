import React, { useState, useEffect } from "react";
import logo from "../../assets/images/Punditlogo.png";
import ConsentForm from "./consent-form";
import { Link } from "react-router-dom";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { setCurrentUser, getCurrentUser } from "../../helpers/Utils";
import ReCAPTCHA from "react-google-recaptcha";

const JoinSection = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isConsentForm, setIsConsentForm] = useState(false);
  const [teacherEmail, setTeacherEmail] = useState("");
  const [captcha, setCaptcha] = useState("");

  const [toggle, setToggle] = useState(
    localStorage.getItem("toggle") === "true"
  );
  const [toggle1, setToggle1] = useState(
    localStorage.getItem("toggle1") === "true"
  );

  let history = useHistory();

  const checkStudent = (check) => {
    if (check === "1") {
      setToggle(!toggle);
      setToggle1(false);
    } else {
      setToggle(false);
      setToggle1(!toggle1);
    }
  };

  const assignSubmit = (e) => {
    e.preventDefault();
    if (
      localStorage.getItem("toggle") === "true" ||
      localStorage.getItem("toggle1") === "true"
    ) {
      setLoading(true);
      const formData = new FormData();
      formData.append("worksheet_id", props.codeId);
      formData.append("student_id_or_name", e.target.student_id.value);
      formData.append("password", e.target.password.value);
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/student/verify-student`,
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
              Authorization: `Bearer ${getCurrentUser()?.token}`,
            },
          }
        )
        .then(function (res) {
          setLoading(false);
          if (res.data.success) {
            localStorage.removeItem("current_document_id");
            setError("");
            localStorage.setItem("current_userName", res.data.student.name);
            localStorage.setItem("unique_id", res.data.data.id);
            localStorage.setItem("test_time", res.data.data.remaining_time);
            setCurrentUser(res.data.student);
            history.push(`/test/${props.codeId}`);
          } else {
            setError(res.data.message);
            if (res.data.data && res.data.data.allow_access === 0) {
              setTeacherEmail(res.data.data.teacher_email);
              setIsConsentForm(true);
            }
          }
        })
        .catch(function (err) {
          setCurrentUser("");
          history.push("/");
        });
    } else {
      setError("Please select one of the two options given below.");
    }
  };

  useEffect(() => {
    localStorage.setItem("toggle", toggle);
  }, [toggle]);

  useEffect(() => {
    localStorage.setItem("toggle1", toggle1);
  }, [toggle1]);

  return (
    <>
      <div className="join">
        <div className="join_studend">
          {error ? (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          ) : (
            ""
          )}
          <Link to={`${process.env.PUBLIC_URL + "/"}`}>
            <img alt="logo" src={logo} className="logoDesign mb-5" />
          </Link>
          <h3 className="join-title">Assignment: {props.title}</h3>
          <p style={{ color: "#b9b9b9" }}>
            {props.deadline_date_time
              ? `Deadline: ${props.deadline_date_time}`
              : ""}
          </p>
          <form
            className="justify-content-center"
            onSubmit={(e) => assignSubmit(e)}
          >
            <div className="form-group">
              <input
                type="text"
                name="student_id"
                className="form-control p-3 w-100"
                placeholder="Username or Enter Id"
              />
            </div>
            <div className="form-group mt-2">
              <input
                type="password"
                name="password"
                className="form-control p-3 w-100"
                placeholder="Enter Password"
              />
            </div>
            <div className="form-group required text-left">
              {process.env.REACT_APP_IS_SCHOOL ? (
                <>
                  <div className="d-flex mt-2 text-left justify-content-between">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck2"
                      onChange={() => checkStudent("2")}
                      checked={toggle1 ? "checked" : ""}
                      name="sss"
                      required={process.env.REACT_APP_IS_SCHOOL ? true : false}
                    />
                    <label
                      className="mx-2 text-white"
                      htmlFor="exampleCheck2"
                      style={{
                        width: "calc(100% - 30px)",
                      }}
                    >
                      I agree to the{" "}
                      <Link
                        style={{
                          color: "rgb(248, 129, 44)",
                          textDecoration: "none",
                        }}
                        to={`${process.env.PUBLIC_URL + "/term-condition"}`}
                      >
                        Terms and Conditions
                      </Link>{" "}
                      and{" "}
                      <Link
                        style={{
                          color: "rgb(248, 129, 44)",
                          textDecoration: "none",
                        }}
                        to={`${process.env.PUBLIC_URL + "/privacy-policy"}`}
                      >
                        Privacy Policy
                      </Link>
                    </label>
                  </div>
                </>
              ) : (
                <>
                  <label className="text-white mt-3 ">
                    Check one of the below options:
                  </label>
                  <div className="d-flex mt-2 text-left justify-content-between">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck1"
                      onChange={() => checkStudent("1")}
                      checked={toggle ? "checked" : ""}
                      name="sss"
                    />
                    <label
                      className="mx-2 text-white"
                      htmlFor="exampleCheck1"
                      style={{
                        width: "calc(100% - 30px)",
                      }}
                    >
                      I am below 13yrs old and have provided the parental
                      consent form and agree to the{" "}
                      <Link
                        style={{
                          color: "rgb(248, 129, 44)",
                          textDecoration: "none",
                        }}
                        to={`${process.env.PUBLIC_URL + "/term-condition"}`}
                      >
                        Terms and Conditions
                      </Link>{" "}
                      and{" "}
                      <Link
                        style={{
                          color: "rgb(248, 129, 44)",
                          textDecoration: "none",
                        }}
                        to={`${process.env.PUBLIC_URL + "/privacy-policy"}`}
                      >
                        Privacy Policy
                      </Link>
                    </label>
                  </div>
                  <div className="d-flex mt-2 text-left justify-content-between">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck2"
                      onChange={() => checkStudent("2")}
                      checked={toggle1 ? "checked" : ""}
                      name="sss"
                      required={process.env.REACT_APP_IS_SCHOOL ? true : false}
                    />
                    <label
                      className="mx-2 text-white"
                      htmlFor="exampleCheck2"
                      style={{
                        width: "calc(100% - 30px)",
                      }}
                    >
                      I am 13yrs + and agree to the{" "}
                      <Link
                        style={{
                          color: "rgb(248, 129, 44)",
                          textDecoration: "none",
                        }}
                        to={`${process.env.PUBLIC_URL + "/term-condition"}`}
                      >
                        Terms and Conditions
                      </Link>{" "}
                      and{" "}
                      <Link
                        style={{
                          color: "rgb(248, 129, 44)",
                          textDecoration: "none",
                        }}
                        to={`${process.env.PUBLIC_URL + "/privacy-policy"}`}
                      >
                        Privacy Policy
                      </Link>
                    </label>
                  </div>
                </>
              )}
            </div>
            <div className="form-group mt-3">
              <ReCAPTCHA
                onChange={(e) => setCaptcha(e)}
                size="invisible"
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
              />
            </div>
            {loading ? (
              <button
                className="btn btn-lg w-100 mt-2 disabled"
                style={{
                  backgroundColor: "rgb(248 129 44)",
                  color: "white",
                }}
              >
                Loading
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-lg w-100 mt-2"
                style={{
                  backgroundColor: "rgb(248 129 44)",
                  color: "white",
                }}
              >
                JOIN
              </button>
            )}
          </form>
        </div>
      </div>
      {isConsentForm ? (
        <ConsentForm
          setIsConsentForm={setIsConsentForm}
          teacherEmail={teacherEmail}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default JoinSection;
