import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import SignInForm from "../containers/SignIn";
import Layout from "../layouts";
import Breadcrumb from "../components/UI/breadcrumb";
import { Helmet } from "react-helmet";

const TeacherLoginForm = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Punditin Login</title>
      </Helmet>
      <Layout>
        <Breadcrumb title="Punditin Login" />
        <div>
          <SignInForm />
        </div>
      </Layout>
    </Fragment>
  );
};

export default TeacherLoginForm;
