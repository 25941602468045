import React, { useState, Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";
import logo from "../assets/images/Punditlogo.png";
import { Link } from "react-router-dom";
import SignUpForm from "../containers/Signup";
import Layout from "../layouts";
import Breadcrumb from "../components/UI/breadcrumb";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Loaders from "../components/loader";
import { Spinner } from "reactstrap";
import { Helmet } from "react-helmet";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const RegisterForm = () => {
  let query = useQuery();
  const [referralId] = useState(query.get("referee_id"));

  if (referralId !== null) {
    localStorage.setItem("isReferralId", referralId);
  }

  const [loader, setLoader] = useState(true);
  const [isSignUp, setIsSignUp] = useState("");
  const [isSignUpMessage, setIsSignUpMessage] = useState("");

  const [step1, setStep1] = useState(referralId !== null ? "d-none" : "");
  const [step2, setStep2] = useState(referralId !== null ? "" : "d-none");

  const activeSignUp = () => {
    setStep2("");
    setStep1("d-none");
  };

  const checkForSignUp = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/check-for-signup-from`)
      .then((res) => {
        setIsSignUp(res.data.disable_signup);
        setIsSignUpMessage(res.data.message);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    checkForSignUp();
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>Punditin Register</title>
        {/* <meta name="description" content="Punditin Register" /> */}
      </Helmet>
      <Layout>
        <Breadcrumb title="Join px-3" />
        <div className={`join ${step1} px-3`} id="form2">
          <div className="join_studend">
            <Link to={`${process.env.PUBLIC_URL + "/"}`}>
              <img alt="logo" src={logo} className="logoDesign mb-5" />
            </Link>
            {loader ? (
              <div className="py-4">
                <Spinner
                  size="sm"
                  color="light"
                  style={{
                    height: "3rem",
                    width: "3rem",
                  }}
                />
              </div>
            ) : isSignUp === "1" ? (
              <h4 className="text-white">
                {isSignUpMessage && isSignUpMessage}
              </h4>
            ) : (
              <>
                <h4 className="text-white">
                  JOIN NOW <span style={{ color: "#bbb7b7" }}>FOR FREE</span>
                </h4>
                <span className="text-white">
                  Already have an account?{" "}
                  <Link
                    to={`${process.env.PUBLIC_URL + "/login"}`}
                    style={{ color: "#f8812c" }}
                  >
                    <span className="pl-1">Sign In</span>
                  </Link>
                </span>
                <div className="form-group mt-3">
                  <button
                    onClick={() => activeSignUp()}
                    type="button"
                    className="btn btn-lg w-100"
                    style={{
                      backgroundColor: "rgb(248 129 44)",
                      color: "white",
                    }}
                  >
                    I'm a teacher
                  </button>
                </div>
                <Link to={`${process.env.PUBLIC_URL + "/join"}`}>
                  <button
                    type="submit"
                    className="btn btn-lg w-100 mt-3"
                    style={{
                      backgroundColor: "rgb(248 129 44)",
                      color: "white",
                    }}
                  >
                    I'm a Student
                  </button>
                </Link>
              </>
            )}
          </div>
        </div>
        <div className={`${step2}`}>
          <SignUpForm />
        </div>
      </Layout>
    </Fragment>
  );
};

export default RegisterForm;
