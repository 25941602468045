import React, { useState, useEffect } from "react";
import Countdown from "react-countdown";
import Timer from "../../assets/images/time.png";

const TestCounter = (props) => {
  const [data, setData] = useState({ date: Date.now(), delay: props.data });
  const wantedDelay = props.data;

  const Completionist = () => <span>Time is over</span>;

  const renderer = ({ minutes, seconds, completed, hours, days }) => {
    if (completed) {
      return <Completionist />;
    } else {
      return (
        <span>
          {minutes}:{seconds}
        </span>
      );
    }
  };
  const getLocalStorageValue = (s) => localStorage.getItem(s);

  useEffect(() => {
    const savedDate = getLocalStorageValue("end_date");
    if (savedDate && !isNaN(savedDate)) {
      const currentTime = Date.now();
      const delta = parseInt(savedDate, 10) - currentTime;

      //Do you reach the end?
      if (delta > wantedDelay) {
        //Yes we clear uour saved end date
        if (localStorage.getItem("end_date").length > 0)
          localStorage.removeItem("end_date");
      } else {
        //No update the end date with the current date
        setData({ date: currentTime, delay: delta });
      }
    }
  }, []);

  return (
    <>
      <h4 className="m-0 p-0 text-white">
        <img width="22" className="mx-2" src={Timer} alt="" />
        <Countdown
          date={data.date + data.delay}
          renderer={renderer}
          onStart={(delta) => {
            if (localStorage.getItem("end_date") == null) {
              localStorage.setItem(
                "end_date",
                JSON.stringify(data.date + data.delay)
              );
            }
          }}
          onComplete={() => {
            if (localStorage.getItem("end_date") != null) {
              localStorage.removeItem("end_date");
            }
            props.submissionFile();
          }}
        />
      </h4>
    </>
  );
};

export default TestCounter;
