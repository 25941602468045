import React, { useEffect, useState } from "react";
import Logo from "../../components/header/Logo";
import HeaderBtn from "../../components/header/HeaderBtn";
import MobileMenu from "../../components/header/MobileMenu";
import styles from "./Header.module.scss";
import { Link, useHistory } from "react-router-dom";
import { getCurrentUser, setCurrentUser } from "../../helpers/Utils";
import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import { IoIosMenu } from "react-icons/io";
import axios from "axios";

const Header = () => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);
  const [soon, setSoon] = useState(false);
  let history = useHistory();

  const studentLogOut = () => {
    localStorage.clear();
    setCurrentUser(null);
    history.push("/");
  };

  const triggerMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      "#offcanvas-mobile-menu"
    );
    offcanvasMobileMenu.classList.add(styles.active);
  };

  const courseSoon = () => {
    let currentTimestampInSeconds = Math.floor(Date.now() / 1000);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/course-module-enabled?timeStamp=${currentTimestampInSeconds}`
      )
      .then(function (res) {
        console.log(res);
        if (res.data.success) {
          setSoon(true);
        } else {
          setSoon(false);
        }
      });
  };

  useEffect(() => {
    courseSoon();
    const header = document.querySelector("header");
    setHeaderTop(header.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.innerWidth > 767) {
      setScroll(window.scrollY);
    }
  };

  return (
    <>
      <MobileMenu styles={styles} />
      <header
        className={`${styles.headerArea} ${
          scroll > headerTop ? styles.stick : ""
        }`}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-6 col-lg-3">
              <Logo />
            </div>
            <div className="col-6 col-lg-9">
              {process.env.REACT_APP_IS_SCHOOL ? (
                <div className="header__btn Btn_header">
                  <div className="desktop-button-wrapper d-none d-lg-block">
                    {!getCurrentUser()?.is_parent_login ? (
                      <Link
                        className="text-white"
                        to={process.env.PUBLIC_URL + "/join"}
                      >
                        Enter Code
                      </Link>
                    ) : (
                      ""
                    )}
                    {getCurrentUser() ? (
                      <UncontrolledDropdown className="d-inline-block">
                        <DropdownToggle className="bg-transparent p-0 border-0 text-white shadow-none">
                          {getCurrentUser().name}
                        </DropdownToggle>
                        <DropdownMenu className="navar_dropDown p-0">
                          <DropdownItem className="p-0">
                            <Link
                              className="p-3 d-block text-dark"
                              to={
                                process.env.PUBLIC_URL +
                                `/student/${getCurrentUser().id}`
                              }
                            >
                              Gradebook
                            </Link>
                          </DropdownItem>
                          <hr
                            className="m-0"
                            style={{
                              backgroundColor: "lightslategray",
                            }}
                          />
                          {soon ? (
                            <>
                              <DropdownItem className="p-0">
                                <Link
                                  className="p-3 d-block text-dark"
                                  to={process.env.PUBLIC_URL + `/my-courses`}
                                >
                                  My Courses
                                </Link>
                              </DropdownItem>
                              <hr
                                className="m-0"
                                style={{
                                  backgroundColor: "lightslategray",
                                }}
                              />
                            </>
                          ) : (
                            ""
                          )}
                          {!getCurrentUser().is_parent_login ? (
                            <>
                              <DropdownItem className="p-0">
                                <Link
                                  className="p-3 d-block text-dark"
                                  to={process.env.PUBLIC_URL + `/real-time`}
                                >
                                  Real-Time
                                </Link>
                              </DropdownItem>
                              <hr
                                className="m-0"
                                style={{
                                  backgroundColor: "lightslategray",
                                }}
                              />{" "}
                            </>
                          ) : (
                            ""
                          )}
                          <DropdownItem
                            className="p-3 d-block text-dark"
                            onClick={() => studentLogOut()}
                          >
                            Logout
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    ) : (
                      <Link to={process.env.PUBLIC_URL + "/login"}>
                        <span className=" ml-3 respon_head_small">Login</span>
                      </Link>
                    )}
                  </div>
                  <div
                    className={`${styles.mobileButtonWrapper} d-block d-lg-none`}
                  >
                    <button
                      className="mobile-aside-button"
                      onClick={() => triggerMobileMenu()}
                    >
                      <IoIosMenu />
                    </button>
                  </div>
                </div>
              ) : (
                <HeaderBtn styles={styles} />
              )}
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
