/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from "react";
import FabricCanvas from "../fabric/FabricEditor";
import FabricCanvasStudent from "../fabric/FabricEditorStudent";
import FabricSubmittedStudent from "../fabric/FabricEditorSubmitted";

export default function TestDocumentFile(props) {
  const [isStudentTools, setIsStudentTools] = useState(false);
  const [imageHeight, setImageHeight] = useState(0);
  const handleImageLoad = (event) => {
    const img = event.target;
    const height = img.clientHeight;
    setImageHeight(height);
  };
  useEffect(() => {
    props.data?.elements?.map((item) => {
      if (item.element_type == "free_draw" || item.element_type == "marking") {
        setIsStudentTools(true);
      }
    });
  }, []);
  return (
    <>
      {imageHeight ? (
        <FabricCanvas imageHeight={imageHeight} document={props.data} />
      ) : (
        ""
      )}
      {isStudentTools ? (
        !props.isAnswer ? (
          <div
            style={{
              zIndex: 2,
            }}
            className="position-relative"
          >
            {imageHeight ? (
              <FabricCanvasStudent
                imageHeight={imageHeight}
                document={props.data}
                isAnswer={props.isAnswer}
              />
            ) : (
              ""
            )}
          </div>
        ) : props.isAnswer === "my_answer" ? (
          <div
            style={{
              zIndex: 2,
            }}
            className="position-relative"
          >
            {imageHeight ? (
              <FabricSubmittedStudent
                imageHeight={imageHeight}
                document={props.data}
                isAnswer={props.isAnswer}
              />
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )
      ) : (
        ""
      )}

      <img
        onLoad={handleImageLoad}
        className="main_document border-bottom"
        src={props.imageSrc}
        alt="Punditin"
        style={{
          minWidth: "800px",
          width: "800px",
        }}
      />
    </>
  );
}
