import React, { useState, Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import Breadcrumb from "../components/UI/breadcrumb";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import Loaders from "../components/loader";
import { getCurrentUser, setCurrentUser } from "../helpers/Utils";
import { Helmet } from "react-helmet";

const ResultShow = () => {
  const { id } = useParams();
  const [allData, setAllData] = useState("");
  const [loader, setLoader] = useState(<Loaders />);
  let history = useHistory();

  const resultData = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/student/get-test-record/${id}?student_record_id=${id}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser()?.token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.success) {
          setAllData(res.data.data);
        }
        setTimeout(() => {
          setLoader("");
        }, 3000);
      })
      .catch(function (err) {
        setCurrentUser("");
        history.push("/");
      });
  };

  console.log("lol", allData);

  useEffect(() => {
    resultData();
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>Result</title>
      </Helmet>
      <Breadcrumb title="Result px-3" />
      {loader === "" ? (
        <div className="result-show text-center">
          <p>{allData.worksheet && allData.worksheet.title}</p>

          <h2>
            {process.env.REACT_APP_IS_SCHOOL
              ? "Your exam was turned in!"
              : "Your activity was turned in!"}
          </h2>
          {allData.worksheet.is_exam ? (
            <h5>Your score will be shared with you soon.</h5>
          ) : (
            <>
              {allData?.worksheet.type === "writing" ? (
                <h5>Your score will be shared with you soon.</h5>
              ) : (
                ""
              )}
              {allData?.worksheet.type === "speaking" ? (
                <h5>Your score will be shared with you soon.</h5>
              ) : (
                ""
              )}
              {allData?.worksheet.show_scores ? (
                <>
                  {allData?.worksheet.type === "listening" ? (
                    <h5>
                      Your score: {allData?.score}/{allData?.total_score}
                    </h5>
                  ) : (
                    ""
                  )}
                  {allData?.worksheet?.type === null ? (
                    <h5>
                      Your score: {allData?.score}/{allData?.total_score}
                    </h5>
                  ) : (
                    ""
                  )}
                  {allData?.worksheet.type === "reading" ? (
                    <h5>
                      Your score: {allData?.score}/{allData?.total_score}
                    </h5>
                  ) : (
                    ""
                  )}
                  {allData?.manual_grade === "0" ? (
                    ""
                  ) : (
                    <h6>
                      Some questions are pending to be marked by the teacher.
                    </h6>
                  )}
                </>
              ) : (
                ""
              )}
            </>
          )}
          <Link
            to={process.env.REACT_APP_IS_SCHOOL ? "/" : "/join"}
            className="btn button_submission w-25 text-white"
          >
            Back
          </Link>
        </div>
      ) : (
        loader
      )}
    </Fragment>
  );
};

export default ResultShow;
