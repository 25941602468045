import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import MaintenanceImg from "../assets/images/under-maintenance.png";
import { Helmet } from "react-helmet";

const UnderMaintenancePage = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Under Maintenance</title>
        {/* <meta name="description" content="Under Maintenance" /> */}
      </Helmet>
      <div
        className="row align-items-center"
        style={{
          height: "100vh",
        }}
      >
        <div className="col-12 text-center">
          <img
            src={MaintenanceImg}
            alt=""
            className="img-fluid maintenance-img mt-2"
            style={{
              maxWidth: "420px",
              width: "100%",
            }}
          />
          <h1 className="mt-4">Under Maintenance!</h1>
          <div className="maintenance-text w-75 mx-auto mt-4">
            {/* <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book. It has
                            survived not only five centuries, but also the leap
                            into electronic typesetting, remaining essentially
                            unchanged.
                        </p> */}
          </div>
          {/* <a
                        href="dashboard1.html"
                        className="btn btn-warning my-2"
                    >
                        Back To Home
                    </a> */}
        </div>
      </div>
    </Fragment>
  );
};

export default UnderMaintenancePage;
