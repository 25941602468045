import React, { useState, useEffect } from "react";
import axios from "axios";
import Rubrics from "./rubric";
import { useHistory } from "react-router-dom";
import { useRouter } from "next/router";

import io from "socket.io-client";
import StudentWorksheetElement from "../student-sheet/element";
import { DndContext } from "@dnd-kit/core";
import { getCurrentUser, setCurrentUser } from "../../helpers/Utils";
import TestDocumentFile from "../test-document";

const LeftSheet = (props) => {
  const [inputValue, setInputValue] = useState("");
  const [timer, setTimer] = useState(null);
  const [teacherNotes, setTeacherNotes] = useState(props.worksheetData.notes);
  const [rubricModal, setRubricModal] = useState(false);
  const [language, setLanguage] = useState(false);
  const [parent, setParent] = useState(null);
  let history = useRouter();

  function handleDragEnd({ over }) {
    setParent(over ? over.id : null);
  }

  const getParagraph = (e, id, limit) => {
    props.setWordCounter(e.split(" ").length);

    if (e.split(" ").length === limit) {
      return false;
    }

    if (localStorage.getItem("end_date") === null) {
      props.setIsStart(true);
    }
    setInputValue(e);
    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      submitAnswer(e, id);
    }, 1000);
    setTimer(newTimer);
  };

  const checkWordLimit = (e, limit) => {
    if (e.keyCode !== 8 && limit) {
      if (e.target.value.split(" ").length === limit) {
        e.preventDefault();
        return false;
      }
    }
  };

  const submitAnswer = (e, id, docId) => {
    const formData = new FormData();
    formData.append("worksheet_id", props.data.worksheet_id);
    if (docId !== undefined) {
      formData.append("document_id", docId);
    } else {
      formData.append("document_id", props.data.id);
    }
    formData.append("test_record_id", localStorage.getItem("unique_id"));
    if (props.wordCounter !== undefined) {
      formData.append("word_count", props.wordCounter);
    }
    formData.append("element_id", id);
    formData.append("answer", e);

    axios
      .post(
        `${process.env.NEXT_PUBLIC_API_URL}/student/submit-ielts-answer`,
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${getCurrentUser()?.token}`,
          },
        }
      )
      .then(function (res) {})
      .catch(function (err) {
        setCurrentUser("");
        history.push("/");
      });
  };

  const handleChangelan = (e) => {
    setLanguage(e);
    const formData = new FormData();
    formData.append("student_rtl_enabled", e ? 1 : 0);
    formData.append("test_record_id", localStorage.getItem("unique_id"));
    axios
      .post(
        `${process.env.NEXT_PUBLIC_API_URL}/update-test-record-student-side`,
        formData
      )
      .then(function (res) {});
  };

  useEffect(() => {
    const socket = io(process.env.NEXT_PUBLIC_SOCKET_URL);
    socket.on(localStorage.getItem("unique_id"), (type, data) => {
      if (type === "testNotes") {
        setTeacherNotes((prevState) => [...prevState, data]);
      }
    });
  }, []);

  return (
    <>
      <div className="form-check form-switch d-flex my-3 px-3">
        <div>
          <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
            RTL
          </label>
        </div>
        <input
          className="form-check-input ml-1"
          type="checkbox"
          role="switch"
          id="flexSwitchCheckDefault"
          onChange={(e) => handleChangelan(e.target.checked)}
          style={{
            marginTop: "5px",
            marginLeft: "4px",
          }}
        />
      </div>
      {props.data.questions.length > 0 &&
        props.data.questions.map((item, i) => (
          <>
            {props.worksheetData.type === "writing" ? (
              <div className="question_data p-3 bg-white mt-2">
                <div className="essay-writing">
                  <h6>
                    Reminder: Check the word limit and the timer, no plagiarism
                    & no copying & pasting. Your work will automatically be
                    submitted once the timer ends or click submit when you are
                    sure you are done.
                  </h6>{" "}
                  {teacherNotes.length > 0 && (
                    <>
                      <h6
                        style={{
                          margin: "20px 0 4px",
                        }}
                      >
                        Notes from teacher:
                      </h6>
                      <ul
                        className="p-0"
                        style={{
                          marginLeft: "18px",
                        }}
                      >
                        {teacherNotes &&
                          teacherNotes.map((item, i) => (
                            <li key={i}>{item.message}</li>
                          ))}
                      </ul>
                    </>
                  )}
                  <textarea
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    onCopy={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    onChange={(e) =>
                      getParagraph(
                        e.target.value,
                        item.element_id,
                        item.word_limit
                      )
                    }
                    onKeyDown={(e) => checkWordLimit(e, item.word_limit)}
                    defaultValue={item.submitted_answer}
                    style={{
                      fontSize: "14px",
                    }}
                    className="form-control mt-1 assey_textarea"
                    dir={language ? "rtl" : "ltr"}
                  />
                  <div className="d-flex justify-content-between">
                    <span className="mt-1">Word Limit: {item.word_limit}</span>
                    <span className="mt-1">
                      Word Count: {props.wordCounter}
                    </span>
                  </div>
                  {props.worksheetData.rubric !== null && (
                    <button
                      className="btn btn-primary mt-3 mb-5 btn-sm"
                      onClick={() => setRubricModal(true)}
                    >
                      View rubric
                    </button>
                  )}
                </div>
                {rubricModal ? (
                  <Rubrics
                    data={props.worksheetData.rubric}
                    setRubricModal={setRubricModal}
                  />
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </>
        ))}
      {props.worksheetData.type !== "writing"
        ? props.worksheetData.documents.map(
            (docs) =>
              docs.questions.length > 0 &&
              docs.questions.map((item, i) => (
                <>
                  <div className="question_data p-3">
                    <div className={`row mt-3 pt-3 border-top`} key={i}>
                      <div className="col-12">
                        <h6 className="m-0 mb-1">
                          {i + 1}. {item.questions.question}
                        </h6>
                        <div className="row">
                          {Object.entries(item.questions.choices).map(
                            ([key, value]) => (
                              <div className="col-6 mt-2">
                                {item.submitted_answer === key ? (
                                  <input
                                    className="ansradio"
                                    type="radio"
                                    name={item.id}
                                    id={`${key}-${item.id}`}
                                    value={key}
                                    onChange={(e) =>
                                      submitAnswer(
                                        e.target.value,
                                        item.id,
                                        item.document_id
                                      )
                                    }
                                    defaultChecked
                                  />
                                ) : (
                                  <input
                                    className="ansradio"
                                    type="radio"
                                    name={item.id}
                                    id={`${key}-${item.id}`}
                                    value={key}
                                    onChange={(e) =>
                                      submitAnswer(
                                        e.target.value,
                                        item.id,
                                        item.document_id
                                      )
                                    }
                                  />
                                )}
                                <label
                                  className="cursor-pointer"
                                  htmlFor={`${key}-${item.id}`}
                                >
                                  {value}
                                </label>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                    {item.questions.length > 0 &&
                      item.questions.map((item, i) => (
                        <div className="row mt-3 pt-3 border-top" key={i}>
                          <div className="col-12">
                            <h6 className="m-0 mb-1">
                              {i + 1}.{" HOENY "}
                              {item.questions.question}
                            </h6>
                            <div className="row">
                              {Object.entries(item.questions.choices).map(
                                ([key, value]) => (
                                  <div className="col-6 mt-2">
                                    {item.submitted_answer === key ? (
                                      <input
                                        className="ansradio"
                                        type="radio"
                                        name={item.id}
                                        id={`${key}-${item.id}`}
                                        value={key}
                                        onChange={(e) =>
                                          submitAnswer(
                                            e.target.value,
                                            item.id,
                                            item.document_id
                                          )
                                        }
                                        defaultChecked
                                      />
                                    ) : (
                                      <input
                                        className="ansradio"
                                        type="radio"
                                        name={item.id}
                                        id={`${key}-${item.id}`}
                                        value={key}
                                        onChange={(e) =>
                                          submitAnswer(
                                            e.target.value,
                                            item.id,
                                            item.document_id
                                          )
                                        }
                                      />
                                    )}
                                    <label
                                      className="cursor-pointer"
                                      htmlFor={`${key}-${item.id}`}
                                    >
                                      {value}
                                    </label>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </>
              ))
          )
        : ""}
      {props.worksheetData.type === "reading" ? (
        <div className="mid_bar">
          <div className="middle_image_display">
            {props.worksheetData.documents &&
              props.worksheetData.documents.map((data) => (
                <div className="result_view text-center position-relative">
                  {data.is_instruction_document === 0 ? (
                    <>
                      <img
                        className="main_document border-bottom"
                        src={data.document}
                        alt="Punditin"
                      />
                      <DndContext onDragEnd={handleDragEnd}>
                        {data.elements &&
                          data.elements.map((item) => (
                            <>
                              <StudentWorksheetElement
                                mainDocument={data.document}
                                studentId={localStorage.getItem("unique_id")}
                                data={item}
                                worksheetid={props.worksheetData.id}
                                parent={parent}
                                handleDragEnd={handleDragEnd}
                                language={language}
                              />
                            </>
                          ))}
                      </DndContext>
                    </>
                  ) : (
                    ""
                  )}
                  {data.sub_documents &&
                    data.sub_documents.map((subDoc) => (
                      <div className="result_view text-center position-relative">
                        {subDoc.is_instruction_document === 0 ? (
                          <>
                            <img
                              className="main_document border-bottom"
                              src={subDoc.document}
                              alt="Punditin"
                            />
                            <DndContext onDragEnd={handleDragEnd}>
                              {subDoc.elements &&
                                subDoc.elements.map((item) => (
                                  <>
                                    <StudentWorksheetElement
                                      mainDocument={data.document}
                                      studentId={localStorage.getItem(
                                        "unique_id"
                                      )}
                                      data={item}
                                      worksheetid={props.worksheetData.id}
                                      parent={parent}
                                      handleDragEnd={handleDragEnd}
                                      language={language}
                                    />
                                  </>
                                ))}
                            </DndContext>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    ))}
                </div>
              ))}
          </div>
        </div>
      ) : (
        ""
      )}
      {props.worksheetData.type === "listening" ? (
        <div className="mid_bar">
          <div className="middle_image_display">
            {props.worksheetData.documents &&
              props.worksheetData.documents.map((data) => (
                <div className="result_view text-center position-relative">
                  {data.is_instruction_document === 0 ? (
                    <>
                      <TestDocumentFile imageSrc={data.document} data={data} />
                      <DndContext onDragEnd={handleDragEnd}>
                        {data.elements &&
                          data.elements.map((item) => (
                            <>
                              <StudentWorksheetElement
                                mainDocument={data.document}
                                studentId={localStorage.getItem("unique_id")}
                                data={item}
                                worksheetid={props.worksheetData.id}
                                parent={parent}
                                handleDragEnd={handleDragEnd}
                                language={language}
                              />
                            </>
                          ))}
                      </DndContext>
                    </>
                  ) : (
                    ""
                  )}
                  {data.sub_documents &&
                    data.sub_documents.map((subDoc) => (
                      <div className="result_view text-center position-relative">
                        {subDoc.is_instruction_document === 0 ? (
                          <>
                            <TestDocumentFile
                              imageSrc={subDoc.document}
                              data={subDoc}
                            />
                            <DndContext onDragEnd={handleDragEnd}>
                              {subDoc.elements &&
                                subDoc.elements.map((item) => (
                                  <>
                                    <StudentWorksheetElement
                                      mainDocument={data.document}
                                      studentId={localStorage.getItem(
                                        "unique_id"
                                      )}
                                      data={item}
                                      worksheetid={props.worksheetData.id}
                                      parent={parent}
                                      handleDragEnd={handleDragEnd}
                                      language={language}
                                    />
                                  </>
                                ))}
                            </DndContext>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    ))}
                </div>
              ))}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default LeftSheet;
