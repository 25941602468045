import React, { useState } from "react";
import Logo from "./../../assets/images/Punditlogo.png";
// import Google from "./../../assets/images/google.png";
import { Link } from "react-router-dom";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

const SignInForm = () => {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [captcha, setCaptcha] = useState("");

  const loginSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/login`, {
        email: email,
        password: password,
      })
      .then(function (res) {
        if (res.data.success === true) {
          setCaptcha("");
          setSuccess(res.data.message);
          setError("");
          window.location.href =
            "https://app.punditin.com/app/worksheet?login_token=" +
            res.data.user.login_token;
        } else {
          setLoading(false);
          setSuccess("");
          setError(res.data.message);
        }
      });
  };
  return (
    <>
      <div className="signup container" id="form1">
        <div className="signup_studend">
          <Link to={`${process.env.PUBLIC_URL + "/"}`}>
            <img src={Logo} alt="" className="text-center" width="200px" />
          </Link>
          {/* <h4 className="card-title mt-3 text-center text-white">Start using SmartLMS</h4> */}
          <p className="text-center text-white mt-3">
            Already have an account?{" "}
            <Link to="/register" style={{ color: "#f8812c" }}>
              Sign Up
            </Link>
          </p>
          {error ? (
            <div className="alert alert-warning" role="alert">
              {error}
            </div>
          ) : (
            ""
          )}
          {success ? (
            <div className="alert alert-success" role="alert">
              {success}
            </div>
          ) : (
            ""
          )}
          <form onSubmit={(e) => loginSubmit(e)}>
            <div className="form-group input-group">
              <input
                name=""
                className="form-control py-2"
                placeholder="Email address"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group input-group">
              <input
                name=""
                className="form-control py-2 mt-2"
                placeholder="Password"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="form-group mt-3">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="gridCheck"
                  required
                />
                <label
                  className="form-check-label text-white text-left"
                  htmlFor="gridCheck"
                >
                  I confirm that I have read, consent and agree to Punditin's{" "}
                  <Link
                    style={{
                      color: "rgb(248, 129, 44)",
                      textDecoration: "none",
                    }}
                    to={`${process.env.PUBLIC_URL + "/term-condition"}`}
                  >
                    Terms and Conditions
                  </Link>{" "}
                  and{" "}
                  <Link
                    style={{
                      color: "rgb(248, 129, 44)",
                      textDecoration: "none",
                    }}
                    to={`${process.env.PUBLIC_URL + "/privacy-policy"}`}
                  >
                    Privacy Policy
                  </Link>
                </label>
              </div>
            </div>
            <div className="form-group mt-3">
              <ReCAPTCHA
                onChange={(e) => setCaptcha(e)}
                size="invisible"
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
              />
            </div>
            <div className="form-group">
              {loading ? (
                <button
                  className="btn btn-primary btn-block py-2"
                  style={{
                    backgroundColor: "#543f86",
                    border: "none",
                    width: "100%",
                    marginTop: "10px",
                  }}
                >
                  Loading
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-primary btn-block py-2 mt-3 mb-2"
                  style={{
                    backgroundColor: "rgb(248 129 44)",
                    color: "white",
                    border: "none",
                    width: "100%",
                  }}
                >
                  Sign In
                </button>
              )}
            </div>
          </form>
          <div className="text-center pt-1 pb-4">
            <a href="https://app.punditin.com/user/forgot-password">
              <span style={{ color: "white" }}>Forget password?</span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignInForm;
