/* eslint-disable import/first */
import React, { useState, useEffect } from "react";
import katex from "katex";
window.katex = katex;
import "katex/dist/katex.css";

import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

// MathQuill dependency
import "./jquery";
import "@edtr-io/mathquill/build/mathquill.js";
import "@edtr-io/mathquill/build/mathquill.css";

// mathquill4quill include
import mathquill4quill from "mathquill4quill";
import "mathquill4quill/mathquill4quill.css";

const Equationeditor = ({
  element_data,
  options,
  document_id,
  element_id,
  worksheet_id,
  student_submitted,
}) => {
  const [value] = useState(
    student_submitted !== undefined
      ? student_submitted
      : element_data
      ? element_data
      : "<p></p>"
  );
  const reactQuill = React.createRef();

  useEffect(() => {
    const enableMathQuillFormulaAuthoring = mathquill4quill({
      Quill,
      katex,
    });
    enableMathQuillFormulaAuthoring(reactQuill.current.editor, options);
  }, [options, reactQuill]);

  return (
    <div style={{ marginTop: "40px" }}>
      <ReactQuill
        className="w-100 h-100"
        ref={reactQuill}
        id={`${element_id}_editor`}
        value={value}
        theme={"bubble"}
        // readOnly={true}
      />
    </div>
  );
};

export default Equationeditor;
