/* eslint-disable import/first */
// demo page
import React, { useState, useEffect } from "react";

// KaTeX dependency
import katex from "katex";
window.katex = katex;
import "katex/dist/katex.css";

// Quill dependency
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

// MathQuill dependency
import "./jquery";
import "@edtr-io/mathquill/build/mathquill.js";
import "@edtr-io/mathquill/build/mathquill.css";

// mathquill4quill include
import mathquill4quill from "mathquill4quill";
import "mathquill4quill/mathquill4quill.css";

import axios from "axios";
import { useHistory } from "react-router-dom";
import { setCurrentUser, getCurrentUser } from "../../../helpers/Utils";

const Editor = (props) => {
  const [value, setValue] = useState("<p></p>");
  const [responseValue, setResponseValue] = useState("");

  const [timer, setTimer] = useState(null);
  const reactQuill = React.createRef();
  let history = useHistory();
  useEffect(() => {
    const enableMathQuillFormulaAuthoring = mathquill4quill({
      Quill,
      katex,
    });
    enableMathQuillFormulaAuthoring(reactQuill.current.editor, props.options);
  }, []);

  const saveText = (e) => {
    setValue(e);
    setResponseValue(e);
    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      const formData = new FormData();
      formData.append("answer", e);
      formData.append("worksheet_id", props.worksheet_id);
      formData.append("document_id", props.document_id);
      formData.append("element_id", props.element_id);
      formData.append("test_record_id", localStorage.getItem("unique_id"));
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/student/submit-answer`,
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
              Authorization: `Bearer ${getCurrentUser()?.token}`,
            },
          }
        )
        .then(function (res) {})
        .catch(function (err) {
          setCurrentUser("");
          history.push("/");
        });
    }, 1000);
    setTimer(newTimer);
  };

  return (
    <ReactQuill
      className="w-100 h-100"
      ref={reactQuill}
      id={`${props.element_id}_editor`}
      value={value}
      modules={{
        formula: true,
        toolbar: [["formula"], [{ color: [] }]],
      }}
      onChange={(e) => saveText(e)}
    />
  );
};

export default Editor;
