import React from "react";
import Modal from "react-modal";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        padding: "0",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "95%",
        overflow: "auto",
        maxWidth: "1100px",
        maxHeight: "95%",
    },
};

const TestCounter = (props) => {
    return (
        <Modal
            isOpen="true"
            contentLabel="Example Modal"
            ariaHideApp={false}
            style={customStyles}
        >
            <div className="modal-header">
                <h2 className="text-capitalize m-0">Rubrics</h2>
                <button
                    onClick={() => props.setRubricModal(false)}
                    className="close"
                >
                    <span
                        style={{
                            fontSize: "28px",
                        }}
                        aria-hidden="true"
                    >
                        ×
                    </span>
                </button>
            </div>
            <div className="modal-body">
                <div className="rubric border rounded-sm">
                    {props.data.rubric_data.map((item, i) => (
                        <div
                            key={i}
                            className="rubric_single border-bottom p-3 pb-0"
                        >
                            <h4
                                style={{
                                    color: "#728FC8",
                                }}
                            >
                                {item.title}
                            </h4>
                            <p>{item.description}</p>
                            <div className="overflow-auto d-flex">
                                <div className="rubric_single_sub rounded-sm d-flex pb-3">
                                    {item.rubric_sub_data.map(
                                        (sub_item, sub_i) => (
                                            <div
                                                key={sub_i}
                                                className="rubric_single_sub-single p-3"
                                            >
                                                <div className="d-flex justify-content-between">
                                                    <h6
                                                        style={{
                                                            width: "calc(100% - 50px)",
                                                        }}
                                                    >
                                                        {sub_item.title}
                                                    </h6>
                                                    <i>{sub_item.points} pts</i>
                                                </div>
                                                <p>{sub_item.description}</p>
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </Modal>
    );
};

export default TestCounter;
