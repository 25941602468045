import React, { useState, Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";
import Breadcrumb from "../components/UI/breadcrumb";
import Layout from "../layouts";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";
import Loaders from "../components/loader";
import SubmittedDataMy from "../components/student-sheet/submitted-data/my-answer";
import SubmittedDataRight from "../components/student-sheet/submitted-data/right-answer";
import { useHistory } from "react-router-dom";
import { setCurrentUser, getCurrentUser } from "../helpers/Utils";
import { Helmet } from "react-helmet";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const StudentTestData = () => {
  const { id } = useParams();
  const [isCorrectAns, setIsCorrectAns] = useState(false);
  const [worksheetData, setWorksheetData] = useState("");
  const [wordCounter, setWordCounter] = useState(0);
  const [loader, setLoader] = useState(<Loaders />);
  const [loading, setLoading] = useState(false);

  let query = useQuery();
  let recordId = query.get("record_id");

  const toggleAns = () => {
    setLoading(true);
    setTimeout(() => {
      setIsCorrectAns(!isCorrectAns);
      setLoading(false);
    }, 500);
  };

  let history = useHistory();

  // function handleDragEnd({ over }) {
  //     setParent(over ? over.id : null);
  // }

  const getWorksheet = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/get-worksheet-student/${id}?student_record_id=${recordId}`,
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${getCurrentUser()?.token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.success) {
          setWorksheetData(res.data.data);
          getSingleWorksheet(res.data.data.documents[0].id);
        }
      })
      .catch(function (err) {
        setCurrentUser("");
        history.push("/");
      });
  };

  const getSingleWorksheet = (documentID) => {
    setLoader(<Loaders />);
    setWordCounter(0);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/get-document/${documentID}?student_record_id=${recordId}`
      )
      .then(function (res) {
        if (res.data.success) {
          if (
            res.data.data.questions.length !== 0 &&
            res.data.data.questions[0].answer &&
            res.data.data.questions[0].answer.word_count !== null
          ) {
            setWordCounter(res.data.data.questions[0].answer.word_count);
          }
        }
        setLoader("");
      });
  };

  useEffect(() => {
    getWorksheet();
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>Submitted Data</title>
      </Helmet>
      <Layout>
        <Breadcrumb title="Pricing my-5" />
        {loader ? (
          loader
        ) : (
          <div
            className="position-relative"
            style={{
              cursor: loading ? "wait" : "unset",
            }}
          >
            <div className="container">
              {worksheetData?.show_correct_answers &&
              worksheetData?.type !== "writing" ? (
                <div className="py-3 d-flex justify-content-end">
                  <div
                    style={{
                      overflow: "auto",
                    }}
                    className="ss"
                  >
                    <div className="its_loading">
                      {loading ? <Loaders /> : ""}
                    </div>
                    <button
                      onClick={() => toggleAns()}
                      className="btn btn-primary shadow-none"
                    >
                      {isCorrectAns ? "Show my answers" : "Show right answers"}
                    </button>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div
                style={{
                  overflow: "auto",
                }}
              >
                {isCorrectAns ? (
                  <SubmittedDataRight
                    worksheetData={worksheetData}
                    setWordCounter={setWordCounter}
                    wordCounter={wordCounter}
                    isAnswer="right_answer"
                  />
                ) : (
                  <SubmittedDataMy
                    worksheetData={worksheetData}
                    setWordCounter={setWordCounter}
                    wordCounter={wordCounter}
                    isAnswer="my_answer"
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </Layout>
    </Fragment>
  );
};

export default StudentTestData;
