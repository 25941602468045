/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useEffect } from "react";

import Layout from "../layouts";
import Breadcrumb from "../components/UI/breadcrumb";
import axios from "axios";
import { useRouter } from "next/router";
import { getCurrentUser, setCurrentUser } from "../helpers/Utils";
import Loaders from "../components/loader";
import SingleCourse from "../components/courses/single-course";
import io from "socket.io-client";
import Head from "next/head";

const Courses = () => {
  const [loader, setLoader] = useState(false);
  const [soon, setSoon] = useState(false);
  const [soonMessage, setSoonMessage] = useState("");
  const [message, setMessage] = useState("");
  const [allData, setAllData] = useState([]);
  const [allCategory, setAllCategory] = useState([]);

  const getSingleLesson = (e) => {
    if (e !== undefined) {
      e.preventDefault();
    }
    let currentTimestampInSeconds = Math.floor(Date.now() / 1000);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/course-module-enabled?timeStamp=${currentTimestampInSeconds}`
      )
      .then(function (res) {
        console.log(res);
        if (res.data.success) {
          setSoon(true);
          setLoader(true);
          setAllData([]);
          const currentTimestampInSeconds = Math.floor(Date.now() / 1000);
          let url = `${process.env.REACT_APP_API_URL}/public-course-list?timeStamp=${currentTimestampInSeconds}`;
          if (e !== undefined) {
            e.preventDefault();
            url = `${process.env.REACT_APP_API_URL}/public-course-list?timeStamp=${currentTimestampInSeconds}&ategory_id=${e.target.category.value}&course_title=${e.target.search.value}`;
          }
          axios.get(url).then(function (res) {
            if (res.data.success) {
              setAllData(res.data.data);
            }
            setMessage(res.data.message);
            setLoader(false);
          });
        } else {
          setSoonMessage(res.data.message);
          setSoon(false);
        }
      });
  };

  const getCourseCategories = () => {
    let currentTimestampInSeconds2 = Math.floor(Date.now() / 1000);
    setAllCategory([]);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/course-category-list?timeStamp=${currentTimestampInSeconds2}`
      )
      .then(function (response) {
        if (response.data.success) {
          setAllCategory(response.data.data);
        }
      });
  };

  useEffect(() => {
    getCourseCategories();
    getSingleLesson();
  }, []);

  return (
    <Fragment>
      <Layout>
        <Head>
          <title>Courses</title>
        </Head>
        <Breadcrumb title="Courses" />
        <div className="container py-4 courses">
          <div className="row">
            <div className="col-lg-2 col-md-3">
              <h2>Courses</h2>
            </div>
            {soon ? (
              <div className="col-lg-10 col-md-9">
                <form className="mb-4" onSubmit={(e) => getSingleLesson(e)}>
                  <div class="filter-section d-sm-flex align-items-center justify-content-end gap-3">
                    <h5 className="mb-2 mb-sm-0">Filter</h5>
                    <div class="mb-2 mb-sm-0">
                      <select
                        style={{
                          appearance: "auto",
                        }}
                        name="category"
                        className="form-control"
                      >
                        <option value="">All Category</option>
                        {allCategory?.map((item) => (
                          <option value={item.id}>
                            {item.course_category_name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div class="mb-2 mb-sm-0">
                      <input
                        className="form-control"
                        name="search"
                        type="text"
                        placeholder="Search by Name"
                      />
                    </div>
                    <button type="submit" className="btn btn-primary">
                      Filter
                    </button>
                  </div>
                </form>
              </div>
            ) : (
              ""
            )}
          </div>

          {soon ? (
            loader ? (
              <Loaders />
            ) : message ? (
              <p className="alert alert-danger my-4 text-center">{message}</p>
            ) : (
              <div className="row">
                {allData?.map((item, i) => (
                  <SingleCourse data={item} key={i} />
                ))}
              </div>
            )
          ) : (
            <h4
              class="text-center"
              style={{
                minHeight: "400px",
              }}
            >
              {soonMessage ? soonMessage : "Coming Soon"}
            </h4>
          )}
        </div>
      </Layout>
      <h2 className="d-none">.</h2>
    </Fragment>
  );
};

export default Courses;
