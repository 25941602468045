import React, { Fragment, useState } from "react";
import MetaTags from "react-meta-tags";
import logo from "../assets/images/Punditlogo.png";
import { Link, useHistory } from "react-router-dom";
import Layout from "../layouts";
import Breadcrumb from "../components/UI/breadcrumb";
import axios from "axios";
import { setCurrentUser } from "../helpers/Utils";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";

const LoginForStudent = () => {
  let history = useHistory();
  const [loading, setLoading] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  // const [passwordShown, setPasswordShown] = useState(false);
  const [captcha, setCaptcha] = useState("");

  const studentLogin = (e) => {
    e.preventDefault();
    if (captcha === "") {
      setError("Please verify that you are not a robot.");
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append(
      "parent_email_or_student_id",
      e.target.parent_email_or_student_id.value
    );
    formData.append("parent_password", e.target.password.value);
    axios
      .post(`${process.env.REACT_APP_API_URL}/parent-login`, formData)
      .then(function (res) {
        setLoading(false);
        setCaptcha("");
        if (res.data.success) {
          setCurrentUser(res.data.data);
          setSuccess(res.data.message);
          setError("");
          history.push(`/student/${res.data.data.id}`);
        } else {
          setSuccess("");
          setError(res.data.message);
        }
      });
  };
  // const togglePassword = () => {
  //     setPasswordShown(!passwordShown);
  //   };

  return (
    <Fragment>
      <Helmet>
        <title>Punditin Login</title>
        {/* <meta name="description" content="Punditin Login" /> */}
      </Helmet>
      <Layout>
        <Breadcrumb title="Join px-3" />
        <div className={`join`}>
          <div className="join_studend">
            <Link to={`${process.env.PUBLIC_URL + "/"}`}>
              <img alt="logo" src={logo} className="logoDesign mb-5" />
            </Link>
            {error ? (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            ) : (
              ""
            )}
            {success ? (
              <div className="alert alert-success" role="alert">
                {success}
              </div>
            ) : (
              ""
            )}
            <form
              onSubmit={(e) => studentLogin(e)}
              className="justify-content-center"
            >
              <div className="form-group">
                <input
                  type="text"
                  name="parent_email_or_student_id"
                  className="form-control p-2 w-100"
                  placeholder="Parent Email or Student Id"
                />
              </div>
              <div className="password_field">
                <div className="form-group">
                  <input
                    type="password"
                    // type={passwordShown ? "text" : "password"}
                    name="password"
                    className="form-control p-2 w-100"
                    placeholder="Enter Password"
                  />
                  {/* <button onClick={togglePassword}>Show/Hide</button> */}
                </div>
              </div>
              <div className="form-group mt-3">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="gridCheck"
                    required
                  />
                  <label
                    className="form-check-label text-white text-left"
                    htmlFor="gridCheck"
                  >
                    I confirm that I have read, consent and agree to Punditin's{" "}
                    <Link
                      style={{
                        color: "rgb(248, 129, 44)",
                        textDecoration: "none",
                      }}
                      to={`${process.env.PUBLIC_URL + "/term-condition"}`}
                    >
                      Terms and Conditions
                    </Link>{" "}
                    and{" "}
                    <Link
                      style={{
                        color: "rgb(248, 129, 44)",
                        textDecoration: "none",
                      }}
                      to={`${process.env.PUBLIC_URL + "/privacy-policy"}`}
                    >
                      Privacy Policy
                    </Link>
                  </label>
                </div>
              </div>
              <div className="form-group mt-3">
                <ReCAPTCHA
                  onChange={(e) => setCaptcha(e)}
                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                />
              </div>
              {loading ? (
                <button
                  className="btn btn-lg w-100 mt-2 disabled"
                  style={{
                    backgroundColor: "rgb(248 129 44)",
                    color: "white",
                  }}
                >
                  Loading
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-lg w-100 mt-2"
                  style={{
                    backgroundColor: "rgb(248 129 44)",
                    color: "white",
                  }}
                >
                  LOGIN
                </button>
              )}
            </form>
          </div>
        </div>
      </Layout>
    </Fragment>
  );
};

export default LoginForStudent;
