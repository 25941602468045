/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Editor from "./Math/editor";
import Equationeditor from "./Math/Equationeditor";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import Xarrow from "react-xarrows";
import { useHistory } from "react-router-dom";
import { setCurrentUser, getCurrentUser } from "../../helpers/Utils";

let CheckboxArr = [1, 2, 3];
let ColumnArr = [1];

const CUSTOM_OPERATORS = [
  ["\\pm", "\\pm"],
  ["\\sqrt{x}", "\\sqrt"],
  ["\\sqrt[3]{x}", "\\sqrt[3]{}"],
  ["\\sqrt[n]{x}", "\\nthroot"],
  ["\\frac{x}{y}", "\\frac"],
  ["\\sum^{s}_{x}{d}", "\\sum"],
  ["\\prod^{s}_{x}{d}", "\\prod"],
  ["\\coprod^{s}_{x}{d}", "\\coprod"],
  ["\\int^{s}_{x}{d}", "\\int"],
  ["\\binom{n}{k}", "\\binom"],
];

let dndID;
const connectPointStyle = {
  width: "15px",
  height: "15px",
  border: "none",
  borderRadius: "50%",
  zIndex: "4",
  position: "relative",
  background: "transparent",
};

let matXY;
const ConnectPointsWrapper = ({ img, boxId, handler, ref0, width, height }) => {
  const ref1 = useRef();

  const [position, setPosition] = useState({});
  const [beingDragged, setBeingDragged] = useState(false);

  const connectPointStyle = {
    position: "absolute",
    width: 12,
    height: 12,
    borderRadius: "50%",
    background: "#dc3545",
    zIndex: "1",
    cursor: "grab",
  };
  const connectPointOffset = {
    left: { left: 0, top: "50%", transform: "translate(-50%, -50%)" },
    right: { left: "100%", top: "50%", transform: "translate(-50%, -50%)" },
    top: { left: "50%", top: 0, transform: "translate(-50%, -50%)" },
    bottom: { left: "50%", top: "100%", transform: "translate(-50%, -50%)" },
  };

  return (
    <React.Fragment>
      <>
        {img ? (
          <img
            className="w-100 h-100"
            style={{
              objectFit: "cover",
              position: "absolute",
              left: 0,
              top: 0,
            }}
            src={img}
            alt="s"
          />
        ) : null}
        <div
          className="connectPoint"
          style={{
            ...connectPointStyle,
            ...connectPointOffset[handler],
            ...position,
          }}
          draggable={handler == "left" ? false : true}
          onDragStart={(e) => {
            const collection =
              document.getElementsByClassName("addborderdashed");
            for (var i = 0; i < collection.length; i++) {
              collection[i].style.border = "dashed 1px #111";
            }
            setBeingDragged(true);
            e.dataTransfer.setData("arrow", beingDragged ? "" : boxId);
          }}
          onDrag={(e) => {
            setPosition({
              position: "fixed",
              left: e.clientX,
              top: e.clientY,
              transform: "none",
              opacity: 0,
              zIndex: "4",
              cursor: "grabbing",
            });
          }}
          ref={ref1}
          onDragEnd={(e) => {
            const collection =
              document.getElementsByClassName("addborderdashed");
            for (var i = 0; i < collection.length; i++) {
              collection[i].style.removeProperty("border");
            }
            setPosition({});
            setBeingDragged(false);
          }}
        />
      </>
    </React.Fragment>
  );
};

let mobArrowFrom = "";
let mobArrowTo = "";

const Box = ({
  img,
  handler,
  addArrow,
  boxId,
  width,
  height,
  matchingSubmit,
}) => {
  const ref0 = useRef();

  const userAgent = navigator.userAgent.toLowerCase();
  const isTablet =
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      userAgent
    );

  const getMatchingID = () => {
    if (window.innerWidth < 1025 || isTablet) {
      if (handler == "right") {
        mobArrowFrom = boxId;
      } else if (mobArrowFrom) {
        mobArrowTo = boxId;
      }
      const refs = {
        start: mobArrowFrom,
        end: mobArrowTo,
      };
      if (mobArrowFrom && mobArrowTo) {
        addArrow(refs);
        matchingSubmit(refs);
        mobArrowFrom = "";
        mobArrowTo = "";
      }
    }
  };

  return (
    <div
      id={boxId}
      className={handler == "left" ? "addborderdashed" : "tooltip_hover"}
      style={{
        position: "relative",
        background: "transparent",
        width: "100%",
        height: "100%",
      }}
      ref={ref0}
      onDragOver={(e) => {
        e.preventDefault();
      }}
      onClick={() => getMatchingID()}
      onDrop={(e) => {
        if (handler !== "right") {
          if (e.dataTransfer.getData("arrow") === boxId) {
            const refs = {};
            addArrow(refs);
            matchingSubmit(refs);
          } else {
            const refs = {
              start:
                e.dataTransfer.getData("arrow") === boxId
                  ? ""
                  : e.dataTransfer.getData("arrow"),
              end: e.dataTransfer.getData("arrow") ? boxId : "",
            };
            addArrow(refs);
            matchingSubmit(refs);
          }
        }
      }}
    >
      {handler === "right" ? (
        <span className="custom_tooltip-text">
          Click and move the red circle to match
        </span>
      ) : (
        ""
      )}
      <ConnectPointsWrapper {...{ img, boxId, handler, ref0, width, height }} />
    </div>
  );
};

const StudentWorksheetElement = (props) => {
  const [clickValue, setclickValue] = useState(1);
  const [checked, setChecked] = useState("");
  const [arrows, setArrows] = useState([]);
  const [drag, setdrag] = useState(false);

  const [multiOpt, setMultiOpt] = useState("");
  const [changeInput, setChangeInput] = useState([1, 2, 3]);
  const [submittedAnswer, setSubmittedAnswer] = useState([]);
  const [columnInput, setColumnInput] = useState([1]);
  const [timer, setTimer] = useState(null);

  const [dragPosition, setDragPosition] = useState({ x: "0", y: "0" });

  let history = useHistory();

  const parentDiv = useRef();

  const addArrow = ({ start, end }) => {
    setArrows(() => [{ start, end }]);
  };
  const filterItem = (elem) => {
    if (arrows.length) {
      if (elem == arrows[0].start || elem == arrows[0].end) {
        const updatenode = arrows.filter((currentelem) => {
          return currentelem[0]?.start === elem;
        });
        setArrows(updatenode);
      }
    }
  };

  const [operators] = useState(CUSTOM_OPERATORS);
  const options = { operators };

  const submitAnsTime = (e) => {
    clearTimeout(timer);

    const newTimer = setTimeout(() => {
      getAnswer(e);
    }, 500);

    setTimer(newTimer);
  };

  const getAnswer = (e, elemType, pId, id) => {
    if (elemType === "hotspot") {
      const hpdiv = document.getElementsByClassName(`hp_${pId}`);

      for (var i = 0; i < hpdiv.length; i++) {
        var div = hpdiv[i];

        div.addEventListener("click", function (event) {
          var clickedDiv = event.target;
          for (var j = 0; j < hpdiv.length; j++) {
            if (hpdiv[j] !== clickedDiv) {
              hpdiv[j].style.background = "none";
            }
          }
        });
      }
    }
    let data = submittedAnswer;
    if (e.checked) {
      data.push(e.value);
    } else {
      var newArray = data;
      var index = newArray.indexOf(e.value);
      if (index !== -1) {
        newArray.splice(index, 1);
      }
    }

    const formData = new FormData();
    formData.append("worksheet_id", props.data.worksheet_id);
    formData.append("document_id", props.data.document_id);
    formData.append("element_id", props.data.element_id);
    formData.append("test_record_id", localStorage.getItem("unique_id"));
    if (e.name === "dropdown") {
      formData.append("answer", e.value);
    } else if (e.name === "text") {
      formData.append("answer", e.value);
    } else if (elemType === "hotspot") {
      const selectHotSpot = e.target;
      selectHotSpot.style.background = "#bbf7d0";
      setclickValue(!clickValue);
      formData.append("answer", Number(clickValue));
    } else if (e.name === "textarea") {
      formData.append("answer", e.value);
    } else if (e.name === "openended") {
      formData.append("answer", e.value);
    } else {
      formData.append("answer", data);
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/student/submit-answer`,
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${getCurrentUser()?.token}`,
          },
        }
      )
      .then((res) => {})
      .catch(function (err) {
        setCurrentUser("");
        history.push("/");
      });
  };

  const matchingSubmit = (e) => {
    const formData = new FormData();
    formData.append("answer", e.end);
    formData.append("worksheet_id", props.data.worksheet_id);
    formData.append("document_id", props.data.document_id);
    formData.append("element_id", e.start);
    formData.append("test_record_id", localStorage.getItem("unique_id"));
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/student/submit-answer`,
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${getCurrentUser()?.token}`,
          },
        }
      )
      .then((res) => {})
      .catch(function (err) {
        setCurrentUser("");
        history.push("/");
      });
  };

  // Matching element

  useEffect(() => {
    if (props.data.element_type == "checkbox") {
      if (props.data.submitted_answer) {
        setSubmittedAnswer(props.data.submitted_answer.split(","));
      }

      if (props.data.settings !== null && props.data.settings !== undefined) {
        // rowValue
        CheckboxArr = [];
        setMultiOpt(props.data.settings.rowValue);
        for (var i = 1; i <= props.data.settings.rowValue; i++) {
          CheckboxArr.push(i);
        }
        setChangeInput(CheckboxArr);
        // colValue
        ColumnArr = [];
        for (var j = 1; j <= props.data.settings.colValue; j++) {
          ColumnArr.push(j);
        }
        setColumnInput(ColumnArr);
      }
    } else if (props.data.element_type == "radio") {
      if (props.data.settings !== null && props.data.settings !== undefined) {
        // rowValue
        CheckboxArr = [];
        setMultiOpt(props.data.settings.rowValue);
        for (var i = 1; i <= props.data.settings.rowValue; i++) {
          CheckboxArr.push(i);
        }
        setChangeInput(CheckboxArr);
        // colValue
        ColumnArr = [];
        for (var j = 1; j <= props.data.settings.colValue; j++) {
          ColumnArr.push(j);
        }
        setColumnInput(ColumnArr);
      }
    }
  }, []);

  setTimeout(() => {
    if (props.data.element_type === "drag_drop") {
      let elementToMove = document.getElementById(
        `${props.data.element_id}_${props.data.id}`
      );
      let destinationElement = document.getElementById(
        props.data.answer?.answer
      );
      destinationElement.appendChild(elementToMove);
    }
  }, 500);

  const handleRadio = (e, id, index) => {
    setChecked(id);
    if (e.checked) {
      const formData = new FormData();
      formData.append("worksheet_id", props.data.worksheet_id);
      formData.append("document_id", props.data.document_id);
      formData.append("element_id", props.data.element_id);
      formData.append("test_record_id", localStorage.getItem("unique_id"));
      formData.append("answer", e.value);
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/student/submit-answer`,
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
              Authorization: `Bearer ${getCurrentUser()?.token}`,
            },
          }
        )
        .then((res) => {})
        .catch(function (err) {
          setCurrentUser("");
          history.push("/");
        });
    }
  };

  const upToFront = (e) => {
    const div = document.querySelector("div.upptofront");
    if (div !== null) {
      div.classList.remove("upptofront");
    }

    const div1 = document.getElementById(e);
    div1.className += " upptofront";
  };

  document.ondragstart = function (event) {
    event.dataTransfer.setData("Text", event.target.id);
    if (event.target.id) {
      dndID = event.target.id;
    }
  };

  const backToParent = (e) => {
    var parentDD = parentDiv.current;
    var childDD = e.target;
    parentDD.appendChild(childDD);
  };

  const hotspotSubmit = (id, ans, pID) => {};

  return (
    <>
      {props.data.element_type === "checkbox" ? (
        <div
          key={props.data.id}
          className="work_elements checkbox"
          style={{
            width: props.data.element_size && props.data.element_size.x,
            height: props.data.element_size && props.data.element_size.y,
            transform: `translate(${
              props.data.questions && props.data.questions.x + 7
            }px, ${props.data?.questions && props.data.questions?.y}px)`,
            minWidth: "24px",
          }}
        >
          <div
            className="checkbox"
            style={{
              width: "100%",
              height: props.data.element_size && props.data.element_size.y,
              gridTemplateColumns: `repeat(${columnInput.length}, auto)`,
              gridTemplateRows: `repeat(${changeInput.length}, auto	)`,
            }}
          >
            {columnInput.map((e, i) => (
              <>
                {changeInput.map((e, index) => (
                  <label>
                    {submittedAnswer.includes(`${i + 1}${index + 1}`) ? (
                      <input
                        id={`${i + 1}${index + 1}`}
                        onChange={(e) => getAnswer(e.target)}
                        className="d-none"
                        type="checkbox"
                        value={`${i + 1}${index + 1}`}
                        name={`${props.data.element_id}-checkbox`}
                        defaultChecked="checked"
                      />
                    ) : (
                      <input
                        id={`${i + 1}${index + 1}`}
                        onChange={(e) => getAnswer(e.target)}
                        className="d-none"
                        type="checkbox"
                        value={`${i + 1}${index + 1}`}
                        name={`${props.data.element_id}-checkbox`}
                      />
                    )}
                    <span
                      htmlFor={`${i + 1}${index + 1}`}
                      className="checkmark"
                    ></span>
                  </label>
                ))}
              </>
            ))}
          </div>
        </div>
      ) : (
        ""
      )}
      {/* Radio elements */}
      {props.data.element_type === "radio" ? (
        <div
          className="work_elements radio"
          style={{
            width: props.data.element_size && props.data.element_size.x,
            height: props.data.element_size && props.data.element_size.y,
            transform: `translate(${
              props.data.questions && props.data.questions.x + 7
            }px, ${props.data.questions && props.data.questions.y}px)`,
            minWidth: "24px",
          }}
          key={props.data.id}
        >
          <div
            className="radio"
            style={{
              width: "100%",
              height: props.data.element_size && props.data.element_size.y,
              gridTemplateColumns: `repeat(${columnInput.length}, auto)`,
              gridTemplateRows: `repeat(${changeInput.length}, auto	)`,
            }}
          >
            {columnInput.map((e, i) => (
              <>
                {changeInput.map((e, index) => (
                  <label>
                    <input
                      id={`${i + 1}${index + 1}`}
                      onChange={(e) => handleRadio(e.target)}
                      className="d-none"
                      type="radio"
                      value={`${i + 1}${index + 1}`}
                      name={`${props.data.element_id}-radio`}
                      defaultChecked={
                        props.data.submitted_answer == `${i + 1}${index + 1}`
                          ? "checked"
                          : ""
                      }
                    />

                    <span
                      htmlFor={`${i + 1}${index + 1}`}
                      className="checkmark"
                    ></span>
                  </label>
                ))}
              </>
            ))}
          </div>
        </div>
      ) : (
        ""
      )}
      {props.data.element_type === "audio_recording" ? (
        <div
          key={props.data.id}
          className="work_elements audio_recording"
          style={{
            minHeight: "70px",
            minWidth: "225px",
            width: props.data.element_size && props.data.element_size.x,
            height: props.data.element_size && props.data.element_size.y,
            transform: `translate(${
              props.data.questions && props.data.questions.x
            }px, ${props.data.questions && props.data.questions.y}px)`,
          }}
        >
          {props.data.attachment ? (
            <audio
              src={props.data.attachment}
              controls="controls"
              controlsList="nodownload noplaybackrate"
              // onEnded={() => setPlay(false)}
              className="w-100"
            />
          ) : (
            "Audio not available"
          )}
        </div>
      ) : (
        ""
      )}
      {props.data.element_type === "text" ? (
        <>
          <div
            key={props.data.id}
            className="work_elements"
            style={{
              width: props.data.element_size && props.data.element_size.x,
              height: props.data.element_size && props.data.element_size.y,
              transform: `translate(${
                props.data.questions && props.data.questions.x + 7
              }px, ${props.data.questions && props.data.questions.y}px)`,
              display: "inline-block",
            }}
          >
            <textarea
              className="form-control"
              onChange={(e) => submitAnsTime(e.target)}
              defaultValue={props.data.submitted_answer}
              style={{
                width: "100%",
                height: "100%",
                padding: "5px",
                resize: "none",
                minHeight: "unset",
                fontWeight: "bold",
              }}
              name="text"
              dir={props.language ? "rtl" : "ltr"}
            />
          </div>
        </>
      ) : (
        ""
      )}
      {props.data.element_type === "dropdown" ? (
        <div
          key={props.data.id}
          className="work_elements dropdown"
          style={{
            width: props.data.element_size && props.data.element_size.x,
            height: props.data.element_size && props.data.element_size.y,
            transform: `translate(${
              props.data.questions && props.data.questions.x + 7
            }px, ${props.data.questions && props.data.questions.y}px)`,
            display: "inline-block",
          }}
        >
          <select
            name="dropdown"
            onChange={(e) => getAnswer(e.target)}
            className="form-control"
            dir={props.data.rtl_enabled ? "rtl" : ""}
            style={{
              height: "100%",
              width: "100%",
              fontWeight: "bold",
            }}
            defaultValue={props.data.submitted_answer}
          >
            <option value="" disabled selected hidden>
              Select
            </option>
            {props?.data?.element_data?.map((val) => (
              <option value={val.name}>{val.name}</option>
            ))}
          </select>
        </div>
      ) : (
        ""
      )}
      {props.data.element_type === "youtube_video" ? (
        <div
          key={props.data.id}
          className="work_elements youtube_video"
          style={{
            width: props.data.element_size && props.data.element_size.x,
            height: props.data.element_size && props.data.element_size.y,
            transform: `translate(${
              props.data.questions && props.data.questions.x + 7
            }px, ${props.data.questions && props.data.questions.y}px)`,
            display: "inline-block",
            minHeight: "100px",
            minWidth: "200px",
          }}
        >
          <iframe
            style={{
              padding: "0",
              resize: "none",
              minHeight: "unset",
            }}
            width="100%"
            height="100%"
            title="he"
            src={`https://www.youtube.com/embed/${props.data.element_data}`}
          ></iframe>
        </div>
      ) : (
        ""
      )}
      {props.data.element_type == "hotspot" ? (
        <div
          key={props.data.id}
          className="work_elements hotspot"
          style={{
            width: props.data.element_size?.x,
            height: props.data.element_size?.y,
            transform: `translate(${
              props.data.questions && props.data.questions.x + 7
            }px, ${props.data.questions && props.data.questions.y}px)`,
            display: "inline-block",
          }}
        >
          <div
            onClick={(e) =>
              getAnswer(
                e,
                props.data.element_type,
                props.data.parent_id ? props.data.parent_id : props.data.id,
                props.data.id
              )
            }
            className={`hp_${
              props.data.parent_id ? props.data.parent_id : props.data.id
            }`}
            style={{
              width: "100%",
              height: "100%",
              opacity: "0.7",
              border: "1px solid blue",
              background: props.data.submitted_answer
                ? "#bbf7d0"
                : clickValue
                ? "transparent"
                : "#bbf7d0",
            }}
          ></div>
        </div>
      ) : (
        ""
      )}
      {props.data.element_type === "drag_drop" ? (
        <div
          key={props.data.id}
          className="work_elements hotspot drag_drop"
          style={{
            width: props.data.element_size && props.data.element_size.x,
            height: props.data.element_size && props.data.element_size.y,
            minWidth: "35px",
            minHeight: "35px",
            transform: `translate(${
              props.data.questions && props.data.questions.x + 7
            }px, ${props.data.questions && props.data.questions.y}px)`,
            display: "inline-block",
            backgroundColor: "white",
          }}
          ref={parentDiv}
          dataparentid={`${props.data.element_id}`}
        >
          <div
            className="position-relative overflow-hidden childss"
            draggable="true"
            id={`${props.data.element_id}_${props.data.id}`}
            onTouchStart={(e) => {
              var rect = parentDiv.current.getBoundingClientRect();
              var ww = props.data.element_size?.x / 2;
              var hh = props.data.element_size?.y / 2;
              setDragPosition({
                x: e.touches[0].clientX - rect.left - ww,
                y: e.touches[0].clientY - rect.top - hh,
              });
            }}
            onTouchMove={(e) => {
              var rect = parentDiv.current.getBoundingClientRect();
              var ww = props.data.element_size?.x / 2;
              var hh = props.data.element_size?.y / 2;
              setDragPosition({
                x: e.touches[0].clientX - rect.left - ww,
                y: e.touches[0].clientY - rect.top - hh,
              });
            }}
            onTouchEnd={(event) => {
              var dropDiv = document.elementFromPoint(
                event.changedTouches[0].clientX,
                event.changedTouches[0].clientY
              );
              if (dropDiv && dropDiv.classList.contains("droptarget")) {
                dropDiv.appendChild(event.changedTouches[0].target);
                setDragPosition({
                  x: "0",
                  y: "0",
                });
                const formData = new FormData();
                formData.append("answer", dropDiv.id);
                formData.append("worksheet_id", props.data.worksheet_id);
                formData.append("document_id", props.data.document_id);
                formData.append(
                  "element_id",
                  event.changedTouches[0].target.id.split("_")[0]
                );
                formData.append(
                  "test_record_id",
                  localStorage.getItem("unique_id")
                );
                axios
                  .post(
                    `${process.env.REACT_APP_API_URL}/student/submit-answer`,
                    formData,
                    {
                      headers: {
                        "content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser()?.token}`,
                      },
                    }
                  )
                  .then((res) => {
                    dndID = "";
                  })
                  .catch(function (err) {
                    setCurrentUser("");
                    history.push("/");
                  });
              } else {
                setDragPosition({
                  x: "0",
                  y: "0",
                });
              }
            }}
            style={{
              boxShadow: "0 0 2px 1px #998",
              touchAction: "none",
              width: props.data.element_size && props.data.element_size.x,
              height: props.data.element_size && props.data.element_size.y,
              minWidth: "35px",
              minHeight: "35px",
              transform: `translate(${dragPosition.x}px, ${dragPosition.y}px)`,
            }}
            onClick={(e) => backToParent(e)}
          >
            <img
              src={
                props.data?.attachment
                  ? props.data.attachment
                  : props.mainDocument
              }
              className="main__d"
              alt="s"
              style={
                props.data.attachment && props.data.attachment !== ""
                  ? {
                      pointerEvents: "none",
                      objectFit: "cover",
                      width: "100%",
                      height: "100%",
                    }
                  : {
                      transform: `translate(-${
                        props.data.questions && props.data.questions.x + 7
                      }px, -${
                        props.data.questions && props.data.questions.y
                      }px)`,
                      width: "800px",
                      position: "absolute",
                      left: "0",
                      top: "0",
                      pointerEvents: "none",
                      maxWidth: "unset",
                      maxHeight: "unset",
                    }
              }
            />
          </div>
        </div>
      ) : (
        ""
      )}
      {props.data.element_type === "dropzone" ? (
        <div
          key={props.data.id}
          className="work_elements hotspot drag_drop"
          style={{
            width: props.data.element_size && props.data.element_size.x,
            height: props.data.element_size && props.data.element_size.y,
            minWidth: "35px",
            minHeight: "35px",
            transform: `translate(${
              props.data.questions && props.data.questions.x + 7
            }px, ${props.data.questions && props.data.questions.y}px)`,
            display: "inline-block",
            border: "dashed 2px #555",
            background: "#ffffff87",
            padding: "2px 0 0 2px",
          }}
        >
          <div
            className="droptarget overflow-hidden"
            style={{
              minWidth: "35px",
              minHeight: "35px",
            }}
            onDrop={(event) => {
              event.preventDefault();
              if (dndID) {
                if (event.target.id) {
                  const formData = new FormData();
                  formData.append("answer", event.target.id);
                  formData.append("worksheet_id", props.data.worksheet_id);
                  formData.append("document_id", props.data.document_id);
                  formData.append("element_id", dndID.split("_")[0]);
                  formData.append(
                    "test_record_id",
                    localStorage.getItem("unique_id")
                  );
                  axios
                    .post(
                      `${process.env.REACT_APP_API_URL}/student/submit-answer`,
                      formData,
                      {
                        headers: {
                          "content-type": "multipart/form-data",
                          Authorization: `Bearer ${getCurrentUser()?.token}`,
                        },
                      }
                    )
                    .then((res) => {
                      dndID = "";
                    })
                    .catch(function (err) {
                      setCurrentUser("");
                      history.push("/");
                    });
                }
                if (event.target.className) {
                  var data = event.dataTransfer.getData("Text");
                  event.target.appendChild(document.getElementById(data));
                }
              }
            }}
            onDragOver={(e) => e.preventDefault()}
            id={props.data.id}
          ></div>
        </div>
      ) : (
        ""
      )}
      {props.data.element_type === "textarea" ? (
        <div
          key={props.data.id}
          className="work_elements textarea"
          style={{
            width: props.data.element_size && props.data.element_size.x,
            height: props.data.element_size && props.data.element_size.y,
            transform: `translate(${
              props.data.questions && props.data.questions.x + 7
            }px, ${props.data.questions && props.data.questions.y}px)`,
            display: "inline-block",
          }}
        >
          <div
            style={{
              padding: "10px",
            }}
            className={
              props.data.rtl_enabled ? "text-right p-2" : "text-left p-2"
            }
            dangerouslySetInnerHTML={{
              __html: props.data?.element_data,
            }}
          />
        </div>
      ) : (
        ""
      )}
      {props.data.element_type === "image" ? (
        <div
          key={props.data.id}
          className="work_elements"
          style={{
            width: props.data.element_size && props.data.element_size.x,
            height: props.data.element_size && props.data.element_size.y,
            transform: `translate(${
              props.data.questions && props.data.questions.x + 7
            }px, ${props.data.questions && props.data.questions.y}px)`,
            display: "inline-block",
          }}
        >
          <img
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            src={props.data.attachment}
            alt=""
            srcset=""
          />
        </div>
      ) : (
        ""
      )}
      {props.data.element_type === "open_ended" ? (
        <div
          key={props.data.id}
          className="work_elements"
          style={{
            width: props.data.element_size && props.data.element_size.x,
            height: props.data.element_size && props.data.element_size.y,
            transform: `translate(${
              props.data.questions && props.data.questions.x + 7
            }px, ${props.data.questions && props.data.questions.y}px)`,
            display: "inline-block",
          }}
        >
          <textarea
            className="form-control"
            onChange={(e) => submitAnsTime(e.target)}
            defaultValue={props.data.submitted_answer}
            style={{
              width: "100%",
              height: "100%",
              padding: "5px",
              resize: "none",
              minHeight: "unset",
              fontWeight: "bold",
              border: "0.5px solid black",
            }}
            name="openended"
            dir={props.language ? "rtl" : "ltr"}
          />
        </div>
      ) : (
        ""
      )}
      {props.data.element_type === "math_equation" ? (
        <div
          key={props.data.id}
          className="work_elements border"
          style={{
            width: props.data.element_size && props.data.element_size.x,
            height: props.data.element_size && props.data.element_size.y,
            transform: `translate(${
              props.data.questions && props.data.questions.x + 7
            }px, ${props.data.questions && props.data.questions.y}px)`,
            display: "inline-block",
          }}
        >
          <Equationeditor
            options={options}
            key={JSON.stringify(options)}
            element_data={props?.data?.element_data}
            document_id={props?.data?.document_id}
            element_id={props.data?.element_id}
            worksheet_id={props.data?.worksheet_id}
            type={"math_equation"}
          />
        </div>
      ) : (
        ""
      )}
      {props.data.element_type === "math_response" ? (
        <div
          key={props.data.id}
          className="work_elements math_response"
          id={`math_response-${props.data.id}`}
          onClick={() => upToFront(`math_response-${props.data.id}`)}
          style={{
            width: props.data.element_size && props.data.element_size.x,
            height: props.data.element_size && props.data.element_size.y,
            transform: `translate(${
              props.data.questions && props.data.questions.x + 7
            }px, ${props.data.questions && props.data.questions.y}px)`,
            display: "inline-block",
          }}
        >
          <Editor
            options={options}
            key={JSON.stringify(options)}
            element_data={props?.data?.element_data}
            document_id={props?.data?.document_id}
            element_id={props.data?.element_id}
            worksheet_id={props.data?.worksheet_id}
            type={"math_response"}
          />
        </div>
      ) : (
        ""
      )}
      {props.data.element_type === "matching" ? (
        <div
          key={props.data.id}
          className="work_elements"
          style={{
            width: props.data.element_size?.x,
            height: props.data.element_size?.y,
            left: `${props.data.questions?.x + 7}px`,
            top: `${props.data.questions?.y}px`,
            background: "#ffffff87",
            display: "inline-block",
          }}
          onDragStart={(e) => setdrag(!drag)}
          onClick={(e) => filterItem(props.data.id)}
        >
          <Box
            {...{
              img: props.data.attachment,
              addArrow,
              handler: "right",
              boxId: `${props.data.element_id}`,
              width: props.data.element_size?.x,
              height: props.data.element_size?.y,
              matchingSubmit: matchingSubmit,
            }}
          />
        </div>
      ) : (
        ""
      )}
      {props.data.element_type === "matching_pair" ? (
        <div
          className="work_elements"
          style={{
            width: props.data.element_size?.x,
            height: props.data.element_size?.y,
            left: `${props.data.questions?.x + 7}px`,
            top: `${props.data.questions?.y}px`,
            background: "#ffffff87",
            display: "inline-block",
          }}
          onClick={(e) => filterItem(props.data.id)}
        >
          <Box
            {...{
              img: props.data.attachment,
              addArrow,
              handler: "left",
              boxId: `${props.data.id}`,
              matchingSubmit: matchingSubmit,
            }}
          />
        </div>
      ) : (
        ""
      )}
      {arrows.map((ar) => (
        <Xarrow
          start={ar.start}
          end={ar.end}
          curveness={false}
          showHead={false}
          startAnchor={"right"}
          endAnchor={"left"}
          lineColor="#999898"
          strokeWidth="3"
          key={ar.start + "-." + ar.start}

          // passProps={{ onClick: hello }}
        />
      ))}
    </>
  );
};

export default StudentWorksheetElement;
