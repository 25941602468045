/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { useHistory } from "react-router-dom";
import SplitPane from "react-split-pane";
import LeftSheet from "../components/ielts/leftside";
import RightSheet from "../components/ielts/rightside";
import Countdown from "react-countdown";
import axios from "axios";
import TimerMinutesSection from "../components/minutes-section";
import TimerMinutesExam from "../components/minutes-exam";
import Timer from "../assets/images/time.png";
import io from "socket.io-client";
import { useParams } from "react-router-dom";
import Loaders from "../components/loader";
import Logo from "../assets/images/Punditlogo.png";
import StudentTest from "../components/student-test/index";
import { getCurrentUser, setCurrentUser } from "../helpers/Utils";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-modal";
import { Helmet } from "react-helmet";

const customAlertStylesFull = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    padding: "15px",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "545px",
    width: "100%",
  },
};
const customAlertStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    padding: "30px 15px",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "545px",
    width: "100%",
  },
};
const customWarningStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    padding: "0",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "545px",
    width: "100%",
  },
};
const customStyles = {
  content: {
    top: "0",
    left: "0",
    right: "auto",
    padding: "0",
    bottom: "auto",
    marginRight: "0",
    width: "100%",
    height: "100%",
    background: "#000",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};
let blockedCount;
let totalCount = 0;
let currentDocCount = 0;

const SplitScreenTest = () => {
  const { id } = useParams();
  const [userAgent] = useState(window.navigator.userAgent);
  const [singleData, setSingleData] = useState("");
  const [worksheetData, setWorksheetData] = useState("");
  const [loader, setLoader] = useState(<Loaders />);
  const [testTimer, setTestTimer] = useState("");
  const [testTimerSingle, setTestTimerSingle] = useState("");
  const [isExam, setIsExam] = useState(0);
  const [isStart, setIsStart] = useState(false);
  const [wordCounter, setWordCounter] = useState(0);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [windowAlertModal, setWindowAlertModal] = useState(
    window.innerHeight < window.innerWidth ? true : false
  );
  const [screeSize, setScreenSize] = useState(window.innerWidth);
  const [alertModal, setAlertModal] = useState(false);
  const [isPaused, setIsPaused] = useState(false); // State to track pause/play
  const [isFullScreen, setIsFullScreen] = useState(false);

  const [fullScreenWarning, setFullScreenWarning] = useState(false);

  const [active, setActive] = useState(true);

  useEffect(() => {
    const handleFocus = () => setActive(true);
    const handleBlur = () => setActive(false);

    window.addEventListener("focus", handleFocus);
    window.addEventListener("blur", handleBlur);

    return () => {
      window.removeEventListener("focus", handleFocus);
      window.removeEventListener("blur", handleBlur);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!active) {
        if (!alertModal) {
          sendAlert();
          setIsPaused(true);
          setAlertModal(true);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [active, alertModal]);

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (document.fullscreenElement) {
        setIsFullScreen(false);
      } else {
        setIsFullScreen(true);
      }
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  };

  useEffect(() => {
    if (isExam && screeSize > 1025) {
      if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      } else if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      }
    }
  }, [isExam, screeSize]);

  useEffect(() => {
    window.onresize = function () {
      setScreenSize(window.innerWidth);
      if (window.innerHeight < window.innerWidth) {
        setWindowAlertModal(true);
      } else {
        setWindowAlertModal(false);
      }
    };
  }, [screeSize]);

  let history = useHistory();

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_URL);
    socket.on(getCurrentUser().id, (type, data) => {
      if (type === "freeze_test" && data == "0") {
        setAlertModal(false);
        setIsPaused(false);
        if (isExam) {
          const docElement = document.documentElement;
          if (docElement.requestFullscreen) {
            docElement.requestFullscreen();
          } else if (docElement.mozRequestFullScreen) {
            docElement.mozRequestFullScreen();
          } else if (docElement.msRequestFullscreen) {
            docElement.msRequestFullscreen();
          }
        }
      } else if (type === "freeze_test" && data == "1") {
        setAlertModal(true);
        setIsPaused(true);
      }
      if (type == "additional_time") {
        setTestTimer(data);
      }
    });
  }, []);

  const sendAlert = () => {
    const formData = new FormData();
    formData.append("is_using_other_tab", 1);
    formData.append("freeze_test", 1);
    formData.append("blocked_count", blockedCount + 1);
    formData.append("test_record_id", localStorage.getItem("unique_id"));
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/update-test-record-student-side`,
        formData
      )
      .then(function (res) {
        blockedCount = res.data.data.blocked_count;
        if (res.data.data.blocked_count == 10) {
          submissionFile();
        }
      });
  };

  const getStudentData = () => {
    setLoader(<Loaders />);
    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/student/get-test-record/${localStorage.getItem("unique_id")}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser()?.token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.success) {
          setIsExam(res.data.data.worksheet.is_exam);
          totalCount = res.data.data.worksheet.documents_count;
          blockedCount = res.data.data.blocked_count;
          if (res.data.data.blocked_count == 10) {
            submissionFile();
          }
          if (res.data.data.worksheet.is_exam && screeSize > 1025) {
            setFullScreenWarning(true);
            setIsPaused(true);
          }
        } else {
          history.push("/");
        }
        if (res.data.data.freeze_test) {
          setAlertModal(true);
          setIsPaused(true);
        }
        if (
          res.data.data.updated_remaining_time !== null &&
          res.data.data.updated_remaining_time !== 0
        ) {
          setTestTimer(res.data.data.updated_remaining_time);
        } else {
          setTestTimer("");
        }
        getWorksheet();
      })
      .catch(function (err) {
        setCurrentUser("");
        history.push("/");
      });
  };

  const getWorksheet = () => {
    setSubmitLoading(true);
    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/get-worksheet-student/${id}?student_record_id=${localStorage.getItem(
          "unique_id"
        )}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser()?.token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.success) {
          setWorksheetData(res.data.data);
          if (localStorage.getItem("current_document_id")) {
            getSingleWorksheet(localStorage.getItem("current_document_id"));
          } else if (res.data.data.type === "reading") {
            getSingleWorksheet(res.data.data.question_documents[0].id);
          } else if (res.data.last_document_id) {
            getSingleWorksheet(res.data.last_document_id);
          } else {
            getSingleWorksheet(res.data.data.documents[0].id);
          }
          setSubmitLoading(false);
        }
      })
      .catch(function (err) {
        setCurrentUser("");
        history.push("/");
      });
  };

  const getSingleWorksheet = (documentID) => {
    if (documentID) {
      setLoader(<Loaders />);
      setWordCounter(0);
      setTestTimerSingle("");
      axios
        .get(
          `${
            process.env.REACT_APP_API_URL
          }/get-document/${documentID}?student_record_id=${localStorage.getItem(
            "unique_id"
          )}`,
          {
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${getCurrentUser()?.token}`,
            },
          }
        )
        .then(function (res) {
          if (res.data.success) {
            setSingleData(res.data.data);
            currentDocCount = res.data.data.current_document_count;
            localStorage.setItem("current_document_id", res.data.data.id);
            if (res.data.data.allowed_time) {
              setTestTimerSingle(res.data.data.allowed_time * 60000);
            } else {
              setTestTimerSingle("");
            }
            if (
              res.data.data.questions.length !== 0 &&
              res.data.data.questions[0].answer &&
              res.data.data.questions[0].answer.word_count !== null
            ) {
              setWordCounter(res.data.data.questions[0].answer.word_count);
            }
          }
          setLoader("");
        })
        .catch(function (err) {
          setCurrentUser("");
          history.push("/");
        });
    } else {
      submissionFile();
    }
  };

  const submissionFile = () => {
    localStorage.removeItem("current_document_id");
    localStorage.removeItem("current_document_id");
    setSubmitLoading(true);
    const formData = new FormData();
    formData.append("time_spent", "");
    formData.append("worksheet_id", id);
    formData.append("name", getCurrentUser().name);
    formData.append("id", localStorage.getItem("unique_id"));
    axios
      .post(`${process.env.REACT_APP_API_URL}/student/submit-test`, formData, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${getCurrentUser()?.token}`,
        },
      })
      .then(function (res) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
        if (res.data.is_course_worksheet) {
          history.push("/my-courses");
        } else {
          history.push("/result/" + localStorage.getItem("unique_id"));
        }
        // setSubmitLoading(true);
      })
      .catch(function (err) {
        setCurrentUser("");
        history.push("/");
      });
  };

  useEffect(() => {
    getStudentData();
    const socket = io(process.env.REACT_APP_SOCKET_URL);
    socket.on(localStorage.getItem("unique_id"), (type, data) => {
      if (type === "testNotes") {
        toast.info(data.message, {
          position: "top-right",
          autoClose: true,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
    const handleTabClose = (event) => {
      event.preventDefault();
      return (event.returnValue = "Are you sure you want to exit?");
    };
    window.addEventListener("beforeunload", handleTabClose);
    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>Test</title>
        {windowAlertModal ? "" : <meta name="viewport" content="" />}
      </Helmet>
      <div className="worksheet_topheader">
        <div className="">
          {testTimerSingle ? (
            <h5 className="m-0 p-0 text-white">
              Section time:
              <img width="22" className="mx-2" src={Timer} alt="" />
              <TimerMinutesSection
                milliseconds={testTimerSingle}
                onComplete={getSingleWorksheet}
                nextID={singleData.next_document_id}
                isPaused={isPaused}
              />
            </h5>
          ) : (
            ""
          )}
          {testTimer ? (
            <h5 className="m-0 p-0 text-white">
              Exam time:
              <img width="22" className="mx-2" src={Timer} alt="" />
              <TimerMinutesExam
                milliseconds={testTimer}
                onComplete={submissionFile}
                isPaused={isPaused}
              />
            </h5>
          ) : (
            ""
          )}
        </div>
        <div className="logo_head p-0">
          <img src={Logo} alt="" width="125px" />
        </div>
        <div className="d-flex"></div>
      </div>
      <ToastContainer />
      {loader !== "" ? (
        loader
      ) : (
        <>
          {worksheetData.type === "listening" ? (
            <div className="SplitPane vertical">
              <div
                className="container"
                style={{
                  maxWidth: "100%",
                }}
              >
                <LeftSheet
                  data={singleData}
                  attachment={worksheetData.attachment}
                  worksheetData={worksheetData}
                />
                <RightSheet data={singleData} worksheetData={worksheetData} />
              </div>
            </div>
          ) : worksheetData.type !== null ? (
            <SplitPane
              split={screeSize < 1024 ? "horizontal" : "vertical"}
              minSize={screeSize < 1024 ? "60%" : "50%"}
            >
              <div>
                <LeftSheet
                  data={singleData}
                  attachment={worksheetData.attachment}
                  worksheetData={worksheetData}
                />
              </div>
              <div>
                <RightSheet
                  setIsStart={setIsStart}
                  data={singleData}
                  worksheetData={worksheetData}
                  setWordCounter={setWordCounter}
                  wordCounter={wordCounter}
                />
              </div>
            </SplitPane>
          ) : (
            <div className="SplitPane vertical">
              <div
                className="container"
                style={{
                  maxWidth: "100%",
                }}
              >
                <StudentTest
                  worksheetData={worksheetData}
                  data={singleData}
                  setWordCounter={setWordCounter}
                  wordCounter={wordCounter}
                  setIsStart={setIsStart}
                  attachment={worksheetData.attachment}
                />
              </div>
            </div>
          )}

          <div className="worksheet_footer">
            <div className="row align-items-center w-100">
              <div className="col"></div>
              <div className="col timer text-center">
                {worksheetData.type !== "writing" ? (
                  <button className="btn btn-primary turnIN mx-1">
                    {currentDocCount + "/" + totalCount}
                  </button>
                ) : (
                  ""
                )}
              </div>
              <div className="col text-end submit_test">
                {worksheetData.type !== "writing" ? (
                  singleData.next_document_id ? (
                    submitLoading ? (
                      <button
                        className="btn btn-primary turnIN disabled"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary turnIN"
                        onClick={() =>
                          getSingleWorksheet(singleData.next_document_id)
                        }
                      >
                        Next
                      </button>
                    )
                  ) : submitLoading ? (
                    <button
                      className="btn btn-primary turnIN disabled"
                      disabled
                    >
                      Loading...
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary turnIN"
                      onClick={() => submissionFile()}
                    >
                      Submit Test
                    </button>
                  )
                ) : submitLoading ? (
                  <button className="btn btn-primary turnIN disabled" disabled>
                    Loading...
                  </button>
                ) : (
                  <button
                    className="btn btn-primary turnIN"
                    onClick={() => submissionFile()}
                  >
                    Submit Test
                  </button>
                )}
              </div>
            </div>
          </div>
        </>
      )}
      {screeSize < 1024 ? (
        <Modal
          isOpen={windowAlertModal}
          contentLabel="Example Modal"
          ariaHideApp={false}
          style={customStyles}
        >
          <div className="">
            <div className="modal-body text-center rotate-alert">
              <div className="phone"></div>
              <p
                className="text-white m-0"
                onClick={() => setWindowAlertModal(false)}
              >
                Please rotate your device!
                <small className="d-block text-light">
                  (or click to continue)
                </small>
              </p>
            </div>
          </div>
        </Modal>
      ) : (
        ""
      )}
      <Modal
        isOpen={isFullScreen && screeSize > 1025}
        contentLabel="Example Modal"
        ariaHideApp={false}
        style={customAlertStylesFull}
      >
        <div className="modal-header d-block p-2">
          <h5 className="text-capitalize text-center px-4">
            Please click the button below to go full screen and continue to
            exam.
          </h5>
        </div>
        <div className="modal-body text-center">
          <button className="btn btn-primary mb-3" onClick={toggleFullScreen}>
            Enter Fullscreen
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={alertModal && isExam}
        contentLabel="Example Modal"
        ariaHideApp={false}
        style={customAlertStyles}
      >
        <div className="modal-header d-block p-2">
          <h2 className="text-capitalize text-center">You are Blocked</h2>
        </div>
        <div className="modal-body text-center">
          <h5
            style={{
              fontSize: "17px",
            }}
            className="mb-1"
          >
            To continue contact your exam controller.{" "}
          </h5>
          <h5
            style={{
              fontSize: "17px",
            }}
          >
            After informing the exam controller, click within the window.
          </h5>
        </div>
      </Modal>
      <Modal
        isOpen={fullScreenWarning && screeSize > 1025}
        contentLabel="Example Modal"
        ariaHideApp={false}
        style={customWarningStyles}
      >
        <form
          onSubmit={() => {
            setFullScreenWarning(false);
            setIsPaused(false);
          }}
        >
          <div className="modal-header d-block p-3 border-bottom border-light">
            <h2 className="text-capitalize text-center m-0">
              You are about to enter Full Screen Mode.
            </h2>
          </div>
          <div className="modal-body p-3 pl-5">
            <p>Minimizing your screen or opening a new tab is not allowed.</p>
            <p>
              Doing so will result in an immediate block, and such actions will
              be logged.
            </p>
            <p>
              To maintain the integrity of the exam, it is crucial to stay
              focused and refrain from engaging in any unauthorized activities.
            </p>
            <div className="d-flex mt-2 text-left justify-content-between">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck2"
                name="sss"
                required
              />
              <label
                className="mx-2"
                htmlFor="exampleCheck2"
                style={{
                  width: "calc(100% - 30px)",
                }}
              >
                I have read and agree to the above message.
              </label>
            </div>
          </div>
          <div className="modal-footer p-3 border-top border-light">
            <button type="submit" className="btn btn-primary">
              Accept
            </button>
          </div>
        </form>
      </Modal>
    </Fragment>
  );
};

export default SplitScreenTest;
