import React, { useState } from "react";
import AudioPlayer from "react-h5-audio-player";
import StudentSubmittedElement from "./submitted-element";
import { DndContext } from "@dnd-kit/core";
import TestDocumentFile from "../../../test-document";

const SubmittedDataRight = (props) => {
  const [parent, setParent] = useState(null);

  function handleDragEnd({ over }) {
    setParent(over ? over.id : null);
  }
  return (
    <>
      <div className="">
        <div className="row">
          {props.worksheetData.attachments.length !== 0 &&
            props.worksheetData.attachments.map((item, i) => (
              <div key={i} className="col-6">
                <label>Audio {i + 1}</label>
                <AudioPlayer
                  src={item.document}
                  showJumpControls="false"
                  showDownloadProgress="false"
                  showFilledProgress="false"
                  hasDefaultKeyBindings="false"
                  autoPlayAfterSrcChange="false"
                />
              </div>
            ))}
        </div>
        {/* Listeinig data element */}
        <div className="mid_bar">
          <div className="middle_image_display">
            <div className="result_view text-left position-relative">
              {props.worksheetData.documents &&
                props.worksheetData.documents.map((data) => (
                  <>
                    {data.is_instruction_document === 1 ? (
                      <>
                        <TestDocumentFile
                          rightAns="1"
                          imageSrc={data.document}
                          data={data}
                        />
                        <DndContext onDragEnd={handleDragEnd}>
                          {data.elements &&
                            data.elements.map((item) => (
                              <>
                                <StudentSubmittedElement
                                  key={item.id}
                                  isAnswer={props.isAnswer}
                                  mainDocument={data.document}
                                  studentId={localStorage.getItem("unique_id")}
                                  data={item}
                                  worksheetid={props.worksheetData}
                                  parent={parent}
                                  handleDragEnd={handleDragEnd}
                                />
                              </>
                            ))}
                        </DndContext>
                      </>
                    ) : (
                      ""
                    )}
                    {data.sub_documents &&
                      data.sub_documents.map((subDoc) => (
                        <div className="result_view text-center position-relative">
                          {subDoc.is_instruction_document === 1 ? (
                            <>
                              <TestDocumentFile
                                rightAns="1"
                                imageSrc={subDoc.document}
                                data={subDoc}
                              />
                              <DndContext onDragEnd={handleDragEnd}>
                                {subDoc.elements &&
                                  subDoc.elements.map((item) => (
                                    <>
                                      <StudentSubmittedElement
                                        key={item.id}
                                        isAnswer={props.isAnswer}
                                        mainDocument={data.document}
                                        studentId={localStorage.getItem(
                                          "unique_id"
                                        )}
                                        data={item}
                                        worksheetid={props.worksheetData}
                                        parent={parent}
                                        handleDragEnd={handleDragEnd}
                                      />
                                    </>
                                  ))}
                              </DndContext>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      ))}
                  </>
                ))}
            </div>
          </div>
        </div>

        <div className="mid_bar">
          <div className="middle_image_display">
            {props.worksheetData.documents &&
              props.worksheetData.documents.map((data) => (
                <div className="result_view text-center position-relative">
                  {data.is_instruction_document === 0 ? (
                    <>
                      <TestDocumentFile
                        rightAns="1"
                        imageSrc={data.document}
                        data={data}
                      />
                      <DndContext onDragEnd={handleDragEnd}>
                        {data.elements &&
                          data.elements.map((item) => (
                            <>
                              <StudentSubmittedElement
                                key={item.id}
                                isAnswer={props.isAnswer}
                                mainDocument={data.document}
                                studentId={localStorage.getItem("unique_id")}
                                data={item}
                                worksheetid={props.worksheetData}
                                parent={parent}
                                handleDragEnd={handleDragEnd}
                              />
                            </>
                          ))}
                      </DndContext>
                    </>
                  ) : (
                    ""
                  )}
                  {data.sub_documents &&
                    data.sub_documents.map((subDoc) => (
                      <div className="result_view text-center position-relative">
                        {subDoc.is_instruction_document === 0 ? (
                          <>
                            <TestDocumentFile
                              rightAns="1"
                              imageSrc={subDoc.document}
                              data={subDoc}
                            />
                            <DndContext onDragEnd={handleDragEnd}>
                              {subDoc.elements &&
                                subDoc.elements.map((item) => (
                                  <>
                                    <StudentSubmittedElement
                                      key={item.id}
                                      isAnswer={props.isAnswer}
                                      mainDocument={data.document}
                                      studentId={localStorage.getItem(
                                        "unique_id"
                                      )}
                                      data={item}
                                      worksheetid={props.worksheetData}
                                      parent={parent}
                                      handleDragEnd={handleDragEnd}
                                    />
                                  </>
                                ))}
                            </DndContext>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    ))}
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default SubmittedDataRight;
